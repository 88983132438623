import styled from 'styled-components';
import { Button, NavLink, DropdownToggle, NavbarToggler } from 'reactstrap';

export const PrimaryButton = styled(Button)`
    background-color: #222c57
    padding: 10px 30px
    border: none
    color: #f0e626
`
export const HeaderNavLink = styled(NavLink)`
    color: #1d1d1b
    font-weight:bold
`
export const HeaderDropdownToggle = styled(DropdownToggle)`
    color: #1d1d1b
    font-weight:bold
`
export const HeaderNavbarToggler = styled(NavbarToggler)`
    color: #1d1d1b
`