import React from 'react';

import { Row, Col } from 'reactstrap';


class BoxLogos extends React.Component {
    render() {
        var patrocinadores = this.props.patrocinadores.map((patrocinador, idx) => {
            let img = <img src={patrocinador.logo} alt={patrocinador.nome} className="img-fluid" style={{maxHeight:'160px'}}/>;
            return (
                <Col key={idx} className="text-center">
                    {patrocinador.link ? (
                        <a href={'http://' + patrocinador.link} rel="noopener noreferrer" title={patrocinador.nome} target="_blank">{img}</a>
                    ) : (
                        <>{ img }</>
                    )}
                </Col>
            );
        });

        return (
            <Row className='justify-content-center align-items-center'>
                {patrocinadores}
            </Row>
        );
    }
};


export { BoxLogos };