import React from "react";
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import styled from 'styled-components';
import { PRIMARY_COLOR } from 'helpers/consts';

import { fetch } from 'actions/candidatos';

const Info = styled.p`
    color: #767676;
    font-weight: 500;
    margin:0;

    & b {
        color: black;
        font-weight: 500!important;
    }
`

const H6Line = styled.h6`
    font-size: 98%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        display: flex;
        content: "";
        border-top: 1px solid #cccccc;
        width: 100%;
        margin-left: 1rem;
    }
`

class Curriculo extends React.Component {
    constructor(){
        super();

        this.state = {
            loading: false,
            model: null
        };
        this._isMounted = false;
    }
    
    fetchData = () => {
        let idx = this.props.idx - 1;
        if(this.props.curriculos[idx] && this.props.curriculos[idx].idCandidato){
            this.setState({
                loading: true
            }, ()=>{
                fetch(this.props.curriculos[idx].idCandidato, (data)=>{
                    if(data.result){
                        let model = data.result;
                        if(this._isMounted){
                            this.setState({
                                model,
                                loading: false
                            });
                        }
                    }
                });
            });
        }
    } 

    componentDidMount(){
        this._isMounted = true;
        this.fetchData();
    }

    componentDidUpdate(nextProps){
        if(nextProps.match.params.idx !== this.props.match.params.idx){
            this.fetchData();
        }
    }
    
    componentWillUnmount(){
        this._isMounted = false;
    }

    dateToDisplay = (dt = '') => {
        if(!dt) return '';
        if(!dt.date) return '';
        dt = dt.date;
        let date = dt.split(' ')[0].split('-');
        return `${date[2]}/${date[1]}/${date[0]}`;
    }

	render() {
        if(this.state.loading || !this.state.model){
            return <p className="py-5 text-center">Carregando...</p>;
        }

        const { model } = this.state;
        return <Row>
            <Col xs="12 pt-4 mt-2">
                <h6 style={{color: PRIMARY_COLOR, borderBottom: '2px solid #cccccc'}} className="w-100 font-weight-light mb-3 pb-1">PESSOAL</h6>
                <Row>
                    {model.foto &&
                        <Col xs="12" md="4" lg="3">
                            <img src={model.foto} alt={model.nome} className="img-fluid" style={{border:'1px solid #c7c7c7'}}/>
                        </Col>
                    }
                    <Col>
                        <Info><b>Nome: </b>{model.nome}</Info>
                        <Info><b>CPF: </b>{model.cpf}</Info>
                        <Info><b>Sexo: </b>{model.sexo ? model.sexo === 'M' ? 'Masculino' : 'Feminino' : ''}</Info>
                        <Info><b>Data de nascimento: </b>{this.dateToDisplay(model.dataNascimento)}</Info>
                        <Info><b>E-mail: </b>{model.email}</Info>
                        <Info>
                            <b>Telefone: </b>{model.telefone}
                            {model.telefone2 && ` | ${model.telefone2}`}
                            {model.telefone3 && ` | ${model.telefone3}`}
                        </Info>
                        <Info><b>Cidade de nascimento: </b>{model.nomeNaturalidade}</Info>
                        <Info><b>Estado civil: </b>
                            {model.estadoCivil === 'S' && 'Solteiro(a)'}
                            {model.estadoCivil === 'C' && 'Casado(a)'}
                            {model.estadoCivil === 'D' && 'Divorciado(a)'}
                            {model.estadoCivil === 'V' && 'Viúvo(a)'}
                            {model.estadoCivil === 'O' && 'Outro'}
                        </Info>
                        <Info><b>Nº de filhos: </b>{model.numeroFilhos || 0}</Info>
                        <Info><b>LinkedIn: </b>{model.linkedin}</Info>
                        <Info><b>Facebook: </b>{model.facebook}</Info>
                    </Col>
                </Row>

                <h6 style={{color: PRIMARY_COLOR, borderBottom: '2px solid #cccccc'}} className="w-100 font-weight-light mb-3 mt-4 pb-1">ENDEREÇO</h6>
                <Row>
                    <Col xs="12">
                        <Info><b>CEP: </b>{model.cep}</Info>
                        <Info><b>Endereço: </b>{model.endereco}</Info>
                        <Info><b>Bairro: </b>{model.bairro}</Info>
                        <Info><b>Estado: </b>{model.uf}</Info>
                        <Info><b>Cidade: </b>{model.nomeCidade}</Info>
                    </Col>
                </Row>  

                <h6 style={{color: PRIMARY_COLOR, borderBottom: '2px solid #cccccc'}} className="w-100 font-weight-light mb-3 mt-4 pb-1">ESCOLARIDADE</h6>
                <Row>
                    <Col xs="12">
                        <Info><b>Nível de formação: </b>
                            {model.nivelFormacao === 'FI' && 'Ensino Fundamental Incompleto'}
                            {model.nivelFormacao === 'FC' && 'Ensino Fundamental Completo'}
                            {model.nivelFormacao === 'MI' && 'Ensino Médio Incompleto'}
                            {model.nivelFormacao === 'MC' && 'Ensino Médio Completo'}
                            {model.nivelFormacao === 'SI' && 'Ensino Superior Incompleto'}
                            {model.nivelFormacao === 'SC' && 'Ensino Superior Completo'}
                        </Info>
                        {(model.escolaridades && model.escolaridades.length > 0) &&
                        <>
                            <H6Line className="mt-3 mb-2">FORMAÇÃO</H6Line>
                            {model.escolaridades.map((val,idx)=>
                                <div key={idx}>
                                    <b>0{idx+1})</b>
                                    <Info><b>Instituição: </b>{val.nomeInstituicao}</Info>
                                    <Info><b>Curso: </b>{val.nomeCurso}</Info>
                                    <Info><b>Período: </b>{this.dateToDisplay(val.dataInicial)} - {this.dateToDisplay(val.dataFinal)}</Info>
                                </div>
                            )}
                        </>
                        }
                        {(model.cursos && model.cursos.length > 0) &&
                        <>
                            <H6Line className="mt-3 mb-2">CURSOS</H6Line>
                            {model.escolaridades.map((val,idx)=>
                                <div key={idx}>
                                    <b>0{idx+1})</b>
                                    <Info><b>Instituição: </b>{val.nomeInstituicao}</Info>
                                    <Info><b>Curso: </b>{val.nomeCurso}</Info>
                                    <Info><b>Data: </b>{val.data ? this.dateToDisplay(val.data) : null}</Info>
                                    <Info><b>Carga horária: </b>{val.cargaHoraria}</Info>
                                </div>
                            )}
                        </>
                        }
                    </Col>
                </Row>

                <h6 style={{color: PRIMARY_COLOR, borderBottom: '2px solid #cccccc'}} className="w-100 font-weight-light mb-3 mt-4 pb-1">PROFISSIONAL</h6>
                <Row>
                    <Col xs="12">
                        {model.primeiroEmprego && <Info>Primeiro emprego</Info>}
                        {(model.experiencias && model.experiencias.length > 0) &&
                        <>
                            {model.experiencias.map((val,idx)=>
                                <div key={idx}>
                                    <b>0{idx+1})</b>
                                    <Info><b>Empresa: </b>{val.nomeEmpresa}</Info>
                                    <Info><b>Cargo: </b>{val.nomeCargo}</Info>
                                    <Info><b>Período: </b>{this.dateToDisplay(val.dataInicial)} - {val.dataFinal ? this.dateToDisplay(val.dataFinal) : 'Atualmente'}</Info>
                                </div>
                            )}
                        </>
                        }
                    </Col>
                </Row>

                <h6 style={{color: PRIMARY_COLOR, borderBottom: '2px solid #cccccc'}} className="w-100 font-weight-light mb-3 mt-4 pb-1">ADICIONAIS</h6>
                <Row>
                    <Col xs="12">
                        <Info><b>Conhecimentos: </b>{model.conhecimentos}</Info>
                        {model.comentarios && <Info><b>Comentários: </b>{model.comentarios}</Info>}
                    </Col>
                </Row>

            </Col>
        </Row>;
	}
}

export default withRouter(Curriculo);
