import 'core-js/es/map';
import 'core-js/es/set';
import 'raf/polyfill';

import React from "react";
import ReactDOM from "react-dom";
import jwt_decode from 'jwt-decode';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import { Router as BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

// Helpers
import store from 'helpers/store'
import setAxiosDefaults from "helpers/setAxiosDefaults";
import setAuthToken from "helpers/setAuthToken";
import { logoutUser } from 'actions/auth';
import { JWT_TOKEN } from 'helpers/consts';
import refreshToken from 'helpers/refresh';

// Páginas
import Base from "views/Base";
import CadastroCandidato from "views/CadastroCandidato";
import CadastroEmpresa from "views/CadastroEmpresa";
import Empresa from "views/Empresa";
import Home from "views/Home";
import Login from "views/Login";
import QuemSomos from "views/QuemSomos";
import Patrocinadores from "views/Patrocinadores";
import Contato from "views/Contato";
import Vagas from "views/Vagas";
import VagasView from "views/VagasView";
import Capacitacoes from "views/Capacitacoes";
import Candidato from 'views/Candidato';
import TermosCondicoes from 'views/TermosCondicoes';
import PoliticasPrivacidade from 'views/PoliticasPrivacidade';

//Analytics
import ReactGA from 'react-ga';

// Ícones (FA)
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Cabecalho from 'components/Cabecalhos';
library.add(fas)
library.add(fab)

setAxiosDefaults('https://api.rionegrinhoempregos.org.br/');

refreshToken(()=>{
    let token = localStorage.getItem(JWT_TOKEN);
    setAuthToken(token);
    const decoded = jwt_decode(token);

    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
        logoutUser();
    }
})

// Define em variável caso precise interromper
window.refreshTokenInterval = setInterval(()=>{
    refreshToken();
},120000)

const browserHistory = createBrowserHistory();

browserHistory.listen((location, action) => {
  window.scrollTo(0, 0);
});

ReactGA.initialize('UA-60036824-13');

ReactDOM.render(
    <>
        <Cabecalho/>
        <Provider store={ store }>
            <BrowserRouter history={browserHistory}>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL}/quem-somos`} component={(props) => <Base {...props}><QuemSomos/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/patrocinadores`} component={(props) => <Base {...props}><Patrocinadores/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/contato`} component={(props) => <Base {...props}><Contato/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/vagas/setores/:setor`} component={(props) => <Base {...props}><Vagas/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/vagas/categorias/:categoria`} component={(props) => <Base {...props}><Vagas/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/vagas/procurar/:pesquisa`} component={(props) => <Base {...props}><Vagas/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/vagas`} component={(props) => <Base {...props}><Vagas/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/vaga/:vaga`} component={(props) => <Base {...props}><VagasView/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/capacitacoes`} component={(props) => <Base {...props}><Capacitacoes/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/termos-e-condicoes`} component={(props) => <Base {...props}><TermosCondicoes/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/politicas-privacidade`} component={(props) => <Base {...props}><PoliticasPrivacidade/></Base>} />
                    
                    <Route path={`${process.env.PUBLIC_URL}/login`} component={(props) => <Base {...props}><Login/></Base>} />

                    <Route path={`${process.env.PUBLIC_URL}/candidato/cadastro`} component={(props) => <Base {...props}><CadastroCandidato/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/minha-conta`} component={(props) => <Base {...props}><Candidato/></Base>} />
                    
                    <Route path={`${process.env.PUBLIC_URL}/empresa/cadastro`} component={(props) => <Base {...props}><CadastroEmpresa/></Base>} />
                    <Route path={`${process.env.PUBLIC_URL}/empresa`} component={(props) => <Base {...props}><Empresa/></Base>} />

                    <Route path={`${process.env.PUBLIC_URL}/`} component={(props) => <Base {...props}><Home/></Base>} />
                </Switch>
            </BrowserRouter>
        </Provider>
    </>
    ,
  document.getElementById("root")
);
