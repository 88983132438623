import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { defaultSave, defaultFetch } from './default';

const MySwal = withReactContent(Swal);

const model = "clientes";
const idField = "idCliente";

export const update = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, true, actions, afterSave, afterError)
export const fetch = (id, callback) => defaultFetch(model, id, callback)

export const save = (data = {}, actions, afterSave, afterError) => {
    let sendData = new FormData();
    Object.keys(data).forEach(function(key) {
        sendData.append(key, data[key]);
    })
    let url = `${model}/`;

    axios.post(url, sendData)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Cadastro realizado com sucesso'
            })
            actions.setSubmitting(false)

            if(afterSave)
                afterSave()
        })
        .catch(err => {
            if(err.response){
                MySwal.fire({
                    type: 'error',
                    title: "Não foi possível cadastrar a empresa. " + err.response.data.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                actions.setErrors(err.response.data.errors)
            }
            actions.setSubmitting(false)

            if(afterError)
                afterError()
        })
}
