import React from "react";
import { withRouter, NavLink } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import InfiniteScroll from 'react-infinite-scroller';

// Métodos / Ações
import { fetchList } from 'actions/vagas';

// Constantes
import { JWT_TOKEN, PRIMARY_COLOR } from 'helpers/consts';
import { PrimaryButton } from "components/Buttons";

// Componentes
import BoxVaga from 'components/SuaContaEmpresa/BoxVaga';

class Main extends React.Component {
    constructor(){
        super();

        this.state = {
            pgSize: 10,
            hasMore: true,
            vagas: [
                //{idVaga:0, nome: 'TESTE', status: -1, curriculos: 3}
            ]
        }
    }

    loadData = (pg = 1) => {
        let reqData = {
            pg,
            pgSize: this.state.pgSize,
            status: 1,
            o: 'data',
            t: 'desc',
            private: true

        }
        var promise = new Promise((res, rej) => {
            fetchList(reqData, res, rej);
        });
        
        promise.then((data) => {
            let hasMore = true;
            if(data.paginacao.paginas <= pg){
                hasMore = false;
            }
            let vagas = this.state.vagas;
            for(let i=0; i < data.results.length; i++){
                vagas.push({
                    idVaga: data.results[i].idVaga,
                    nome: data.results[i].cg_nome,
                    status: data.results[i].status,
                    curriculos: data.results[i].qtdCurriculos
                })
            }

            this.setState({
                hasMore,
                vagas
            });
        });
    }

    afterDelete = () => {
        this.setState({vagas: [], hasMore: true}, ()=>{
            this.loadData();
        });      
    }

    componentDidMount(){
        this.loadData();
    }

	render() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token)
            return null;
        
        token = jwt_decode(token);
    
        let curriculosLen = 0;
        const vagas = this.state.vagas.map((value, idx)=>{
            curriculosLen += parseInt(value.curriculos);
            return <BoxVaga vaga={value} afterDelete={this.afterDelete} key={idx}/>
        })
		return (
			<div>
                <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Olá {token.nome}</h4>
                <div className="text-muted mt-3 mb-4">
                    {this.state.vagas.length > 0 &&
                        <>
                            <div className="mb-3">Você recebeu {curriculosLen} currículo(s) para a(s) vaga(s):</div>
                            <InfiniteScroll
                                pageStart={1}
                                loadMore={this.loadData}
                                hasMore={this.state.hasMore}
                                initialLoad={false}
                                loader={<div className="loader" key={0}>Carregando ...</div>}
                            >
                                {vagas}
                            </InfiniteScroll>
                        </>
                    }
                    {this.state.vagas.length === 0 &&
                        <>Você não tem nenhuma vaga em aberto.</>
                    }
                </div>
                <PrimaryButton tag={NavLink} to={`${process.env.PUBLIC_URL}/empresa/vagas`} className="font-weight-light mt-4" style={{color:'white'}}>VER TODAS</PrimaryButton>
			</div>
		);
	}
}

export default withRouter(Main);
