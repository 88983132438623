import * as Yup from 'yup';
import { addInYup as addCpf } from "helpers/validation/Cpf"; 
import { addInYup as addPhone } from "helpers/validation/Phone"; 
import { addInYup as addDateYup } from "helpers/validation/Date";

// Adiciona funções customizadas no YUP (Não existentes por padrão)
addCpf();
addPhone();
addDateYup();

Yup.addMethod(Yup.mixed, 'formacao', function (message) {
    return this.test('formacao', message, function (value) {
        let rgx_idx = /\[[0-9]{0,}\]/;
        let idx = this.path.match(rgx_idx)[0].replace('[', '').replace(']', '');
        if(parseInt(idx) === 0){
            return (value !== null && value !== '');
        } else {
            return true;
        }
    });
});

const validationSchema = Yup.object().shape({
    nivelFormacao: Yup.string().required('Por favor selecione um nível de formação').oneOf(['FI', 'FC', 'MI', 'MC', 'SI', 'SC']),
    formacao: Yup.array().min(1, 'Informe ao menos uma formação').of(Yup.object().shape({
        idCurso: Yup.mixed().formacao('Selecione o curso').notRequired().nullable(),
        idInstituicao: Yup.mixed().formacao('Selecione a instutição').notRequired().nullable(),
        dataInicial: Yup.string().formacao('Informe a data de início').date('Informe uma data válida').notRequired().nullable(),
    })),
    /* curso: Yup.array().notRequired().of(Yup.object().shape({
        idCurso: Yup.mixed().required('Selecione o curso'),
        idInstituicao: Yup.mixed().required('Selecione a instituição'),
        data: Yup.string().required('Informe a data').date('Informe uma data válida'),
        cargaHoraria: Yup.number().integer('Informe a carga horária').positive('Informe a carga horária').required('Informe a carga horária')
    })) */
});

export default validationSchema;