import React from "react";
import PropTypes from "prop-types";
import { Field } from 'formik';
import { FormGroup, FormFeedback } from 'reactstrap';

// Componentes Estilizados
import { InputLabel, CustomInput } from "./styles";

function FormInput(props) {
    const { form, label } = props;
    const fieldProps = props.field;
    const { touched, errors } = form;

    const isValid = (name) => {
        let invalid = false;
        let valid = false;
        let rgx_idx = /\[[0-9]{0,}\]/;
        let rgx_name = /^[a-z0-9A-Z]{0,}/;
        let rgx_prop = /[a-z0-9A-Z]{0,}$/;
        let message = '';
        if(name.match(rgx_idx) && name.match(rgx_idx)){
            let idx = name.match(rgx_idx)[0].replace('[', '').replace(']', '');
            let prop = name.match(rgx_prop)[0];
            name = name.match(rgx_name)[0];

            if(touched[name] && touched[name][idx] && touched[name][idx][prop] && errors[name] && errors[name][idx] && errors[name][idx][prop]){
                invalid = true;
                message = errors[name][idx][prop];
            } else if(touched[name] && touched[name][idx] && touched[name][idx][prop]){
                valid = true;
            }
        }
        else {
            if(touched[name] && errors[name]){
                invalid = true;
                message = errors[name];
            }
            if(touched[name] && !errors[name]){
                valid = true;
            }
        }
        return { valid, invalid, message };
    };
    
    return (
        <>
            <Field {...fieldProps}>{({ field }) => {
                let inputProps = Object.assign(field, fieldProps);
                const { invalid, valid, message } = isValid(fieldProps.name);
                let id = inputProps.id || inputProps.name || '';
                
                return (<FormGroup>
                    <InputLabel htmlFor={id}>{label}</InputLabel>
                    <CustomInput invalid={invalid} id={id} valid={valid} {...inputProps}/>
                    {invalid && <FormFeedback>{message}</FormFeedback>}
                </FormGroup>);
            }}
            </Field>
        </>
    );
}


FormInput.propTypes = {
    field: PropTypes.object.isRequired,
    label: PropTypes.any,
    form: PropTypes.any
}

export default FormInput;
