import * as Yup from "yup";
import { addInYup as addPhone } from "helpers/validation/Phone";

// Adiciona funções customizadas no YUP (Não existentes por padrão)
addPhone();

const validationSchema = Yup.object().shape({
  idCargo: Yup.mixed().required("Por favor informe o cargo"),
  idCidade: Yup.mixed().required("Por favor informe a cidade"),
  resumo: Yup.string().required("Por favor informe o resumo da vaga"),
  descricao: Yup.string().required("Por favor informe a descrição da vaga"),
  idCategoria: Yup.mixed().required("Por favor selecione uma categoria"),
  idSetor: Yup.mixed().required("Por favor selecione um setor"),
  idArea: Yup.mixed().required("Por favor selecione uma área"),
  telefone: Yup.string()
    .required("Por favor digite um telefone válido")
    .phone("Por favor digite um telefone válido"),
  dataExpiracao: Yup.string()
    .default("")
    .typeError("Por favor informe a data de expiração")
    .matches(
      /^\d{2}\/\d{2}\/\d{4}$/,
      "Formato de Data inválida. Use o formato DD/MM/YYYY."
    )
    .test(
      "isValidAndFutureDate",
      "Data inválida ou anterior à data atual.",
      (value) => {
        if (!value) return false;
        const [day, month, year] = value.split("/").map(Number);

        if (day < 1 || day > 31) return false;
        if (month < 1 || month > 12) return false;

        const inputDate = new Date(year, month - 1, day);
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return !isNaN(inputDate) && inputDate >= today;
      }
    )
    .required("Por favor informe a data de expiração"),
  email: Yup.string()
    .required("Por favor digite um e-mail")
    .email("Por favor digite um e-mail válido"),
  responsavel: Yup.string().required("Por favor digite um responsável"),
});

export default validationSchema;
