import React from 'react';

import { Container, Row, Col } from 'reactstrap';

//Analytics
import ReactGA from 'react-ga';

class PoliticasPrivacidade extends React.Component {

    componentDidMount() {
        ReactGA.pageview(this.constructor.name);
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Container fluid={true} className="border-bottom">
                <Container>
                    <Row className="py-5">
                        <Col>
                            <h1 className="text-center">Políticas de privacidade</h1>
                            <p>O www.rionegrinhoempregos.org.br respeita a privacidade de seus usuários de forma transparente e objetiva.</p>
                            <p>Aqui iremos informar quais tipos de informações pessoais recebemos e coletamos quando você visita o 
                            www.rionegrinhoempregos.org.br e como essa informação é guardada. O www.rionegrinhoempregos.org.br nunca repassará essas 
                            informações a terceiros, sejam empresas ou pessoas físicas.</p>
                            <p>Assim como outros websites, coletamos e usamos informações relativas aos registros. A informação contida nos registos 
                            inclui o seu endereço IP (internet protocol), o seu ISP (internet service provider), o navegador que utilizou ao 
                            visitar-nos, o tempo da sua visita e que páginas visitou neste site.</p>
                            <p>Cookies e Web Beacons: Utilizamos cookies para armazenar informação, tais como as suas preferências pessoais, quando 
                            você visita o www.rionegrinhoempregos.org.br. Isto pode incluir um simples popup, ou uma ligação em vários serviços que 
                            providenciamos, tais como fóruns. O Google faz uso do novo DART cookie para mostrar anúncios baseados nos seus interesses 
                            e na sua visita ao nosso blog e a outros endereços na web. Poderá optar por não utilizar o DART cookie visitando a página 
                            de anúncios Google ad e o programa de policiamento da empresa. Também utilizamos publicidade de terceiros no nosso site.</p>
                            <p>Alguns destes publicitários, poderão utilizar tecnologias como os cookies e/ou web beacons ao publicarem anúncios 
                            neste site, o que fará com que estes publicitários (como o Google através do Google AdSense) também recebam a sua 
                            informação pessoal, como o endereço IP, o seu ISP , o seu browser, etc.</p>
                            <p>Esta função é geralmente utilizada para geotargeting ou apresentar publicidade direcionada a um tipo de utilizador. 
                            Você pode desligar os seus cookies, nas opções de seu navegador, ou efetuando alterações nas ferramentas de programas 
                            Anti-Virus.</p>
                            <p>No entanto, isso poderá alterar a forma como você interage com este e ou outros sites. Isso poderá afetar, ou não 
                            permitir que faça login em programas, sites ou fóruns de nossa e de outras redes.</p>
                            <p>As informações armazenadas tanto de empresas, quanto de usuários do blog www.rionegrinhoempregos.org.br estarão 
                            privadas de qualquer acesso externo e não serão concedidas para nenhum outro fim, a não ser os serviços propostos para 
                            o blog.</p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
};

export default (PoliticasPrivacidade);
