import React from 'react';
import axios from 'axios';
import objToQueryString from 'helpers/objToQueryString';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { PrimaryButton, SecondaryButton } from "components/Buttons";

const MySwal = withReactContent(Swal);

export function defaultFetchList(model, reqData, resolve, reject, dispatch) {
    let ignoreNulls = true;

    axios.get(`${model}/list${objToQueryString(reqData, ignoreNulls)}`)
    .then(res => {
        resolve(res.data)
    })
    .catch(err => {
        let erro = {message: "Não foi possível obter os dados"}
        if(err.response){
            erro = err.response.data
        }
        reject(erro.message)
    })
}

export function defaultFetch(model, id, callback = () => {}) {
    axios.get(`${model}/${btoa(id)}`)
    .then(res => {
        callback(res.data)
    })
    .catch(err => {
        if(err.response){
            MySwal.fire({
                type: 'error',
                title: "Não foi possível obter os dados. " + err.response.data.message,
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
            })
        }
    })
}

export const defaultSave = (model, idField, data = {}, formData = false, actions, afterSave, afterError, extraEndpoint = '') => {
    let sendData = Object.assign({}, data)

    if(formData){
        sendData = new FormData();
        Object.keys(data).forEach(function(key) {
            sendData.append(key, data[key]);
        })
    }
    let url = `${model}/`;
    if(data[idField]){
        url = `${model}/${btoa(data[idField])}${extraEndpoint}`;
    }

    axios.post(url, sendData)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Registro salvo com sucesso'
            })
            if(actions)
                actions.setSubmitting(false)

            if(afterSave)
                afterSave(res)
        })
        .catch(err => {
            if(err.response){
                MySwal.fire({
                    type: 'error',
                    title: "Não foi possível salvar o registro. " + err.response.data.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                if(actions)
                    actions.setErrors(err.response.data.errors)
            }
            if(actions)
                actions.setSubmitting(false)

            if(afterError)
                afterError()
        })
}

export const defaultDelete = (model, id, afterDelete, afterError) => {
    MySwal.fire({
        title: '',
        type: 'warning',
        html: 'Deseja realmente excluir o registro? <br>Essa ação não pode ser revertida!',
        showCancelButton: true,
        confirmButtonText: <PrimaryButton tag="span" size="lg" color="danger" title="Sim, excluir">Sim, excluir</PrimaryButton>,
        cancelButtonText: <SecondaryButton tag="span" size="lg" color="white" title="Cancelar">Cancelar</SecondaryButton>,
        reverseButtons: true
    }).then((result) => {
        if(result.value){
            axios.post(`${model}/delete/${btoa(id)}`)
            .then(res => {
                MySwal.fire({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                    type: 'success',
                    title: "Registro excluído com sucesso"
                })

                if(afterDelete)
                    afterDelete()
            })
            .catch(err => {
                if(err.response){
                    MySwal.fire({
                        type: 'error',
                        title: "Não foi possível excluir o registro. " + err.response.data.message,
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                    if(afterError)
                        afterError()
                }
            })
        }
    })
}


export const defaultLoadOptions = (inputValue = '', model, fieldFrom = [], fieldTo = fieldFrom) => {
    return new Promise((resolve, reject) => {
        if(fieldFrom.length !== fieldTo.length){
            reject('Não é possível utilizar arrays de tamanhos diferentes');
        }
        let reqData = {
            s: inputValue,
            pg: 1
        };
        var promise = new Promise((res, rej) => {
            defaultFetchList(model, reqData, res, rej);
        });
        
        promise.then((data) => {
            let res = [];
            for(let i=0; i < data.results.length; i++){
                let tmp = {};
                for(let j=0;j<fieldFrom.length;j++){
                    tmp[fieldTo[j]] = data.results[i][fieldFrom[j]];
                }
                res.push(tmp);
            }
            resolve(res);
        });
    })
}

export const defaultChangeStatus = (model, id, status = 0, afterChange, afterError, extraData) => {
    axios.post(`${model}/change-status/${btoa(id)}`, {status, ...extraData})
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: "Situação alterada com sucesso"
            })

            if(afterChange)
                afterChange()
        })
        .catch(err => {
            if(err.response){
                MySwal.fire({
                    type: 'error',
                    title: "Não foi possível alterar a situação. " + err.response.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                if(afterError)
                    afterError()
            }
        })
}