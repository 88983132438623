import styled from 'styled-components';

export const BackGround = styled.div`
    background-size: cover
    background-position: center center
    background-image: url(${props => props.source});    
`
export const H1 = styled.h1`
    font-size: 40px
    color: white    
`