import React from "react";

import { Collapse, Navbar, NavbarToggler, Nav, NavItem, UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import { HeaderNavLink, HeaderDropdownToggle } from './styles';
import { withRouter,Link } from "react-router-dom";

import { loadOptions as  fetchSetoresList} from "actions/setores";
import { loadOptions as  fetchCategoriasList} from "actions/categorias";

import { LINK_FACE } from "helpers/consts";
import { LINK_INSTA } from "helpers/consts";
import { LINK_LINKEDIN } from "helpers/consts";
import iconFace from 'assets/img/iconFace.png';
import iconInstagram from 'assets/img/iconInstagram.png';
import iconLinkedin from 'assets/img/iconLinkedin.png';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class NavbarLinks extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			isOpen: false,
			setores: <DropdownItem>Carregando...</DropdownItem>,
			categorias: <DropdownItem>Carregando...</DropdownItem>,
		};
	}

    setores = () => {
        fetchSetoresList().then((data) => {
			let result = <DropdownItem>Nenhum setor encontrado</DropdownItem>;
			if(data.length){
				result = data.map((prop,key) => {
					return (
						<DropdownItem key={key}>
							<HeaderNavLink tag={Link} to={`${process.env.PUBLIC_URL}/vagas/setores/`+prop.slug}>{prop.setor}</HeaderNavLink>
						</DropdownItem>
					);
				});
			}
            this.setState({
                setores: result
			});
        });
    }

    categorias = () => {
        fetchCategoriasList().then((data) => {
			let result = <DropdownItem>Nenhuma categoria encontrada</DropdownItem>;
			if(data.length){
				result = data.map((prop,key) => {
					return (
						<DropdownItem key={key}>
							<HeaderNavLink tag={Link} to={`${process.env.PUBLIC_URL}/vagas/categorias/`+prop.slug}>{prop.descricao}</HeaderNavLink>
						</DropdownItem>
					);
				});
			}
            this.setState({
                categorias: result
			});
        });
    }

    componentDidMount(){
        this.setores();
        this.categorias();
    }

	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	render() {
		return (
			<Navbar expand="lg">
				<NavbarToggler onClick={this.toggle}><FontAwesomeIcon icon="bars" style={{color:'#1d1d1b'}}></FontAwesomeIcon></NavbarToggler>
				<Collapse isOpen={this.state.isOpen} navbar>
					<Nav className="mx-auto" navbar>
						<UncontrolledDropdown nav inNavbar>
							<HeaderDropdownToggle nav caret>
								SETORES
							</HeaderDropdownToggle>
							<DropdownMenu>
								{this.state.setores}
							</DropdownMenu>
						</UncontrolledDropdown>
						<UncontrolledDropdown nav inNavbar>
							<HeaderDropdownToggle nav caret>
								CATEGORIAS
							</HeaderDropdownToggle>
							<DropdownMenu>
								{this.state.categorias}
							</DropdownMenu>
						</UncontrolledDropdown>
						<NavItem><HeaderNavLink tag={Link} to={`${process.env.PUBLIC_URL}/capacitacoes`}>CAPACITAÇÕES</HeaderNavLink></NavItem>
						<NavItem><HeaderNavLink tag={Link} to={`${process.env.PUBLIC_URL}/quem-somos`}>QUEM SOMOS</HeaderNavLink></NavItem>
						<NavItem><HeaderNavLink tag={Link} to={`${process.env.PUBLIC_URL}/patrocinadores`}>PATROCINADORES</HeaderNavLink></NavItem>
						<NavItem><HeaderNavLink tag={Link} to={`${process.env.PUBLIC_URL}/contato`}>CONTATO</HeaderNavLink></NavItem>
						<NavItem className="d-lg-none">
							<a target="_blank" rel="noopener noreferrer" title="Facebook" href={LINK_FACE}><img src={iconFace} alt="Facebook" className="mr-1"/></a>
							<a target="_blank" rel="noopener noreferrer" title="Instagram" href={LINK_INSTA}><img src={iconInstagram} alt="Instagram" className="mr-1"/></a>
							<a target="_blank" rel="noopener noreferrer" title="Linkedin" href={LINK_LINKEDIN}><img src={iconLinkedin} alt="Linkedin" className="mr-1"/></a>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		);
	}
};

export default withRouter(NavbarLinks);