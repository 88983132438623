import styled from 'styled-components';
import { Button } from 'reactstrap';
import { PRIMARY_COLOR,SECONDARY_COLOR } from 'helpers/consts';

export const PrimaryButton = styled(Button)`
    background-color: ${PRIMARY_COLOR}
    padding: 10px 30px
    border: none
    color: #f0e626
`
export const SecondaryButton = styled(Button)`
    background-color: ${SECONDARY_COLOR}
    padding: 10px 30px
    border: none
    color: ${PRIMARY_COLOR}
    font-weight: bold
`
export const ThirdButton = styled(Button)`
    background-color: #ffffff
    padding: 10px 30px
    border: none
    color: ${PRIMARY_COLOR}
    font-weight: bold
`
export const FourthButton = styled(Button)`
    background-color: #778292
    padding: 10px 30px
    border: none
    color: #FFF
    font-weight: bold
`