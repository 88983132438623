import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import logoAcirne from 'assets/img/logoAcirne.png';
import logoNucleo from 'assets/img/logoNucleo.png';
import logoRH from 'assets/img/logoRH.png';

class Idealizadores extends React.Component {
	render() {
		return (
			<Container fluid={true} className="bg-light">
				<Container className="py-4">
					<Row className="justify-content-center">
						<Col xs="12" md="auto" className="px-5 my-4"><img src={logoAcirne} title="ACIRNE" alt="ACIRNE" className="img-fluid center-block"/></Col>
						<Col xs="12" md="auto" className="px-5 my-4"><img src={logoNucleo} title="Núcleo de Jovens Empreendedores de Rio Negrinho" alt="Núcleo de Jovens Empreendedores de Rio Negrinho" className="img-fluid center-block"/></Col>
						<Col xs="12" md="auto" className="px-5 my-4"><img src={logoRH} title="Núcleo de Gestores de Recursos Humanos de Rio Negrinho" alt="Núcleo de Gestores de Recursos Humanos de Rio Negrinho" className="img-fluid center-block"/></Col>
					</Row>
				</Container>
			</Container>
		);
	}
}

export default Idealizadores;