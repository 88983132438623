import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { PRIMARY_COLOR } from "helpers/consts";
import {
  PrimaryButton,
  SecondaryButton,
  ThirdButton,
} from "components/Buttons/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuantidadeCurriculos } from "components/SuaContaEmpresa/BoxVaga/styles";

class CurriculoHeader extends React.Component {
  goBack = () => {
    this.props.history.push(
      `${process.env.PUBLIC_URL}/empresa/vagas/edit/${this.props.id}`
    );
  };

  next = () => {
    let idx = this.props.idx - 1;
    let newIdx = idx + 1;
    if (this.props.curriculos[newIdx]) {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/empresa/vagas/edit/${
          this.props.id
        }/curriculos/${newIdx + 1}`
      );
    } else {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/empresa/vagas/edit/${this.props.id}/curriculos/1`
      );
    }
  };

  previous = () => {
    let idx = this.props.idx - 1;
    let newIdx = idx - 1;
    if (this.props.curriculos[newIdx]) {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/empresa/vagas/edit/${
          this.props.id
        }/curriculos/${newIdx + 1}`
      );
    } else {
      this.props.history.push(
        `${process.env.PUBLIC_URL}/empresa/vagas/edit/${this.props.id}/curriculos/${this.props.curriculos.length}`
      );
    }
  };

  render() {
    return (
      <Row>
        <Col xs="12">
          <h4
            style={{ color: PRIMARY_COLOR }}
            className="font-weight-light mb-3"
          >
            Vagas Cadastradas | Currículos
          </h4>
          <Row
            className="mx-0"
            style={{ borderBottom: "2px solid " + PRIMARY_COLOR }}
          >
            <Col
              style={{ color: PRIMARY_COLOR, fontSize: "18px" }}
              className="font-weight-bold px-2 px-md-0 py-md-2"
            >
              {this.props.vaga.nomeCargo}
            </Col>
            <Col
              xs="12"
              md="auto"
              className="d-flex align-items-end justify-content-center pr-0"
            >
              <ThirdButton
                onClick={this.goBack}
                size="sm"
                className="py-1 px-2 mb-1"
              >
                VOLTAR
              </ThirdButton>
            </Col>
            <QuantidadeCurriculos
              style={{ fontSize: "0.875rem" }}
              xs="12"
              md="auto"
              className="d-flex align-items-end justify-content-center mb-2"
            >
              {this.props.curriculos.length} CURRÍCULOS
            </QuantidadeCurriculos>
            <Col
              xs="6"
              md="auto"
              className="d-flex align-items-end justify-content-center pr-0"
            >
              <SecondaryButton
                onClick={this.previous}
                size="sm"
                className="py-1 px-2 mb-2"
              >
                <FontAwesomeIcon icon="arrow-left" color={PRIMARY_COLOR} />
              </SecondaryButton>
            </Col>
            <Col
              xs="6"
              md="auto"
              className="d-flex align-items-end justify-content-center pr-0"
            >
              <SecondaryButton
                onClick={this.next}
                size="sm"
                className="py-1 px-2 mb-2"
              >
                <FontAwesomeIcon icon="arrow-right" color={PRIMARY_COLOR} />
              </SecondaryButton>
            </Col>
          </Row>
          <Row>
            <form
              method="POST"
              action="https://api.rionegrinhoempregos.org.br/candidatos/curriculo/"
              target="_blank"
            >
              <Col xs="12" className="mt-3">
                <PrimaryButton
                  type="submit"
                  name="candidato"
                  value={btoa(
                    this.props.curriculos[this.props.idx - 1].idCandidato
                  )}
                >
                  <FontAwesomeIcon icon="file-pdf" /> Abrir Currículo em PDF
                </PrimaryButton>
              </Col>
            </form>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default withRouter(CurriculoHeader);
