import * as yup from 'yup';

const Phone = (val = "") => (/(\(?\d{2}\)?) ?9?\d{4}-?\d{4}/.test(val) || val === "" || val === null)

export default Phone;

export const addInYup = () => {
    yup.addMethod(yup.string, "phone", function(message) {
        return this.test("phone", message || "Número de Telefone inválido", value => Phone(value));
    });
}