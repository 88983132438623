import styled from 'styled-components';
import { Label, Input } from 'reactstrap';

import { INFO_COLOR } from 'helpers/consts';

export const InputLabel = styled(Label)`
    font-size: 14px;
    margin-bottom: 0;
`

export const CustomInput = styled(Input)`
    && {
        background-color: ${INFO_COLOR};
    }
`