import React, { Component } from 'react';
import { Carousel, CarouselItem, Container, Row, Col } from 'reactstrap';

import { CircleCarouselIndicators } from './styles';

import { loadOptions as fetchPatrocinadoresList } from "actions/patrocinadores";

class CarrosselClientes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
			patrocinadores: [<CarouselItem onExiting={this.onExiting} onExited={this.onExited} key='1' className="text-center">Carregando...</CarouselItem>]
		};
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.goToIndex = this.goToIndex.bind(this);
		this.onExiting = this.onExiting.bind(this);
		this.onExited = this.onExited.bind(this);
	}

	onExiting() {
		this.animating = true;
	}

	onExited() {
		this.animating = false;
	}

	next() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === this.state.patrocinadores.length - 1 ? 0 : this.state.activeIndex + 1;
		this.setState({ activeIndex: nextIndex });
	}

	previous() {
		if (this.animating) return;
		const nextIndex = this.state.activeIndex === 0 ? this.state.patrocinadores.length - 1 : this.state.activeIndex - 1;
		this.setState({ activeIndex: nextIndex });
	}

	goToIndex(newIndex) {
		if (this.animating) return;
		this.setState({ activeIndex: newIndex });
	}

	componentDidMount() {
		fetchPatrocinadoresList().then((data) => {
			let patrocinadoresDisplay = null;
			if (data.length) {
				patrocinadoresDisplay = data.map((patrocinador, idx) => {
					let img = <img src={patrocinador.anuncio} alt={patrocinador.nome} className="w-100 img-fluid" />;
					return (
						<CarouselItem
							onExiting={this.onExiting}
							onExited={this.onExited}
							key={idx}
						>
							{patrocinador.link ? (
								<a href={'http://'+patrocinador.link} rel="noopener noreferrer" title={patrocinador.nome} target="_blank">{img}</a>
							) : (
								{img}
							)}
						</CarouselItem>
					);
				});
			}

			this.setState({
				patrocinadores: patrocinadoresDisplay
			});
		});
	}


	render() {
		const { activeIndex } = this.state;

		return (
			<Container fluid={true} className="border-bottom">
				<Container>
					<Row className="justify-content-center py-5">
						<Col>
							<Carousel
								activeIndex={activeIndex}
								next={this.next}
								previous={this.previous}
							>
								<CircleCarouselIndicators items={this.state.patrocinadores} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
								{this.state.patrocinadores}
							</Carousel>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}


export default CarrosselClientes;