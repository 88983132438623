import React from 'react';

import { Row, Col } from 'reactstrap';
import { Titulo, BoxSetorData, Setor, LinkVaga } from './styles'

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class BoxVaga extends React.Component {
    render() {
        const vaga = this.props.vaga;
        return (
            <LinkVaga to={`${process.env.PUBLIC_URL}/vaga/` + vaga.idVaga + `/` + slugify(vaga.cg_nome)} title={'Ver ' + vaga.cg_nome} style={{ color: 'inherit' }}>
                <Col xs="12" className="my-5">
                    <Row>
                        <Col>
                            <Setor>{vaga.st_descricao}</Setor>
                            <p className="float-left mt-1">{vaga.data}</p>
                            {vaga.status === 2 && <p className="float-left mt-1 ml-2">(Vaga Preenchida <FontAwesomeIcon icon="clipboard-check"/>) </p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Titulo className="h4">{vaga.necEspecial === 1 && <FontAwesomeIcon icon="wheelchair" className="mr-2 mb-1 fa-lg" style={{color:'#007FFF'}}/>} {vaga.cg_nome}</Titulo>
                            {vaga.cl_ocultaInfo !== 1 &&
                                <p className="text-muted"><b>Empresa: </b>{vaga.em_nome}</p>
                            }
                            <p className="text-muted" dangerouslySetInnerHTML={{ __html: vaga.resumo }}></p>
                        </Col>
                    </Row>
                </Col>
            </LinkVaga>
        );
    }
};

class BoxVagaResumida extends React.Component {
    render() {
        const vaga = this.props.vaga;
        return (
            <Col xs="12" md="6" className='my-3'>
                <LinkVaga to={`${process.env.PUBLIC_URL}/vaga/` + vaga.idVaga + `/` + slugify(vaga.cg_nome)} title={'Ver ' + vaga.cg_nome} style={{ color: 'inherit' }}>
                    <div className="bg-white p-4 px-5 position-relative h-100">
                        <BoxSetorData>
                            <Setor>{vaga.st_descricao}</Setor>
                            <p className="float-left mt-1">{vaga.data}</p>
                            {vaga.status === 2 && <p className="float-left mt-1 ml-2">(Vaga Preenchida <FontAwesomeIcon icon="clipboard-check"/>) </p>}
                        </BoxSetorData>
                        <Titulo className="h4 mt-5">{vaga.necEspecial === 1 && <FontAwesomeIcon icon="wheelchair" className="mr-2 mb-1 fa-lg" style={{color:'#007FFF'}}/>} {vaga.cg_nome}</Titulo>
                        <p className="text-muted">{vaga.cl_ocultaInfo !== 1 && 
                            <>
                            <b>Empresa: </b>{vaga.em_nome} |
                            </>
                        } <b>Categoria: </b>{vaga.ct_descricao}</p>
                        <p className="text-muted" dangerouslySetInnerHTML={{ __html: vaga.resumo }}></p>
                    </div>
                </LinkVaga>
            </Col>
        );
    }
};

function slugify(string) {
    const a = 'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
    const b = 'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')
  
    return string.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }

export { BoxVaga, BoxVagaResumida };