import styled from 'styled-components';
import { Row } from 'reactstrap';

export const GridRow = styled(Row)`
    &::before{
        position: absolute;
        content: '\\00a0';
        top: 0px;
        bottom: 0px;
        width: 1px;
        height: 100%;
        right: 0px;
        background:white;
        z-index:1;
    }
    &::after{
        position: absolute;
        content: '\\00a0';
        right:0px;
        left:0px;
        bottom: 48px;
        width: 100%;
        height: 1px;
        background:white;
        z-index:1;
    }
`