import React from "react";
import { withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Constantes
import { JWT_TOKEN, PRIMARY_COLOR } from 'helpers/consts';

// Componentes
import Banner from 'components/Banners/CadastroCandidato';
import PessoalForm from './Pessoal/form';
import EnderecoForm from './Endereco/form';
import EscolaridadeForm from './Escolaridade/form';
import ProfissionalForm from './Profissional/form';

//Analytics
import ReactGA from 'react-ga';

class CadastroCandidato extends React.Component {
    constructor(){
        super();
		ReactGA.pageview(this.constructor.name);

        this.state = {
            etapa: 0
        }
    }
    /*
            {  
        "history":{  
            "length":4,
            "action":"PUSH",
            "location":{  
                "pathname":"/v2/candidato/cadastro",
                "search":"",
                "hash":"",
                "state":{  
                    "facebook":{  
                    "id":"2449206865169441",
                    "first_name":"Andrei Luiz",
                    "last_name":"Nenevê",
                    "name":"Andrei Luiz Nenevê",
                    "name_format":"{first} {last}",
                    "picture":{  
                        "data":{  
                            "height":50,
                            "is_silhouette":false,
                            "url":"https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2449206865169441&height=50&width=50&ext=1565540499&hash=AeQ34myX2w4ytnUm",
                            "width":50
                        }
                    },
                    "short_name":"Andrei Luiz",
                    "email":"andreiluizn@gmail.com"
                    }
                },
                "key":"yg8eyo"
            }
        },...
    */
    componentDidMount() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(token && jwt_decode(token).platform === 'empresa'){
            this.props.history.push(`${process.env.PUBLIC_URL}/empresa`)
        } else if(token && jwt_decode(token).platform === 'candidato') {
            this.props.history.push(`${process.env.PUBLIC_URL}/minha-conta`)
        }
    }

    next = () => {
        this.setState({
            etapa: (this.state.etapa + 1)
        })
    }

	render() {
        /*let token = localStorage.getItem(JWT_TOKEN);
        if(token)
            return null;*/
		return (
			<div>
				<Banner/>
                <Row className="py-5 mx-0 d-flex align-items-center justify-content-center" style={{borderBottom: '1px solid #cccccc'}}>
                    <Col xs="12" md="auto" className="font-weight-bold px-2 px-md-0" style={{color:this.state.etapa >= 0 ? PRIMARY_COLOR : '#b3b3b3'}}>
                        PESSOAL<FontAwesomeIcon icon='arrow-right' className="mx-3"/>
                    </Col>
                    <Col xs="12" md="auto" className="font-weight-bold px-2 px-md-0" style={{color:this.state.etapa >= 1 ? PRIMARY_COLOR : '#b3b3b3'}}>
                        ENDEREÇO<FontAwesomeIcon icon='arrow-right' className="mx-3"/>
                    </Col>
                    <Col xs="12" md="auto" className="font-weight-bold px-2 px-md-0" style={{color:this.state.etapa >= 2 ? PRIMARY_COLOR : '#b3b3b3'}}>
                        ESCOLARIDADE<FontAwesomeIcon icon='arrow-right' className="mx-3"/>
                    </Col>
                    <Col xs="12" md="auto" className="font-weight-bold px-2 px-md-0" style={{color:this.state.etapa >= 3 ? PRIMARY_COLOR : '#b3b3b3'}}>
                        PROFISSIONAL
                    </Col>
                </Row>
                {this.state.etapa === 0 && <PessoalForm onNext={this.next} {...this.props}/>}
                {this.state.etapa === 1 && <EnderecoForm onNext={this.next} {...this.props}/>}
                {this.state.etapa === 2 && <EscolaridadeForm onNext={this.next} {...this.props}/>}
                {this.state.etapa === 3 && <ProfissionalForm onNext={this.next} {...this.props}/>}
			</div>
		);
	}
}

export default withRouter(CadastroCandidato);
