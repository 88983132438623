import * as Yup from 'yup';
import { addInYup as addPhone } from "helpers/validation/Phone"; 

// Adiciona funções customizadas no YUP (Não existentes por padrão)
addPhone();

const validationSchema = Yup.object().shape({
    assunto: Yup.string().required('Por favor informe o assunto'),
    nome: Yup.string().required('Por favor informe o nome'),
    email: Yup.string().required('Por favor digite um e-mail').email('Por favor digite um e-mail válido'),
    telefone: Yup.string().required('Por favor digite um telefone válido').phone('Por favor digite um telefone válido'),
    mensagem: Yup.string().required('Por favor informe o mensagem'),    
});

export default validationSchema;