import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

let store;

const startStore = (customCompose = compose) => {
    const inititalState = {};

    store = createStore(
            rootReducer, 
            inititalState, 
            customCompose(applyMiddleware(thunk)));
}

if (process.env.NODE_ENV === 'development') {
    const custom = composeWithDevTools({ 
        name: 'Rio Negrinho Empregos - Painel Administrativo'
    });
    startStore(custom)
} else {
    startStore(compose)
}

export default store;