import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import jwt_decode from 'jwt-decode';
import heic2any from 'heic2any';

// Consts
import { PRIMARY_COLOR, JWT_TOKEN } from 'helpers/consts';

import { fetchList as fetchSetoresList } from 'actions/setores';
import { loadOptions as loadCidadesOptions } from 'actions/cidades';
import { loadOptions as loadPaisesOptions } from 'actions/paises';
import { loginUser } from 'actions/auth';
import { save, fetch } from 'actions/candidatos';

// Componentes
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import { SecondaryButton, PrimaryButton } from 'components/Buttons';
import Cpf from 'components/FormInput/Cpf';
import Telefone from 'components/FormInput/Telefone';
import DateInput from 'components/FormInput/Date';
import { InputLabel } from 'components/FormInput/styles';
import Checkbox from 'components/Checkbox';
import Thumb from 'components/Thumb';

// Validação
import validationSchema from './validation';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.insert = function (index, string) {
    var ind = index < 0 ? this.length + index  :  index;
    return  this.substring(0, ind) + string + this.substring(ind, this.length);
};

class FormCadastroEmpresa extends React.Component {
    constructor() {
        super();

        this._initialValues = {
            nome: "",
            cpf: "",
            dataNascimento: "",
            email: "",
            estadoCivil: "",
            facebook: "",
            foto: null,
            linkedin: "",
            idNacionalidade: 36,
            nomeNacionalidade: 'Brasil',
            idNaturalidade: null,
            nomeNaturalidade: '',
            idEstado: null,
            nomeEstado: '',
            uf: '',
            newsletter: 0,
            numeroFilhos: 0,
            setor: {},
            sexo: "",
            telefone: "",
            telefone2: "",
            telefone3: "",
            facebookUserId: null
        };     
        this._isMounted = false;

        this.state = {
            model: this._initialValues,
            telefones: 1,
            loading: false,
            setores: [],
            image: null,
        }
    }

    handleFileChange = async (FormikProps, event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        try {
            const processedFile = await this.onFileLoadEnd(file);
            FormikProps.setFieldValue('foto', processedFile);
            this.setState({ image: URL.createObjectURL(processedFile) });
        } catch (error) {
            console.error('Erro ao processar o arquivo', error);
        }
    };    
    
    onFileLoadEnd = (file) => {
    return new Promise((resolve, reject) => {
        if (file?.type === 'image/heif' || file?.type === 'image/heic') {
        const reader = new FileReader();
        reader.onload = () => {
            const blob = this.dataURItoBlob(reader.result);
            heic2any({
            blob,
            toType: 'image/jpeg',
            })
            .then((conversionResult) => {
                const baseName = file.name.replace(/\.[^/.]+$/, "");
                const newFile = new File([conversionResult], `${baseName}.jpg`, { type: 'image/jpeg' });
                resolve(newFile);
            })
            .catch((errorObject) => {
                console.log(errorObject);
                reject(errorObject);
            });
        };
        reader.readAsDataURL(file);
        } else {
        resolve(file);
        }
    });
    };

    dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], { type: mimeString });
    }

    loadSetores = () => {
        let reqData = {
            pg: 0,
            o: 'descricao',
            t: 'desc'

        }
        var promise = new Promise((res, rej) => {
            fetchSetoresList(reqData, res, rej);
        });
        
        promise.then((data) => {
            if(this._isMounted)
                this.setState({
                    setores: data.results
                });
        });
    }

    componentDidMount(){
        this.loadSetores();

        let fbkData = this.props.history.location.state ? this.props.history.location.state.facebook : null;
        if(fbkData){
            if(this._isMounted){
                let model = this.state.model;
                model.nome = fbkData.name;
                model.email = fbkData.email;
                model.facebookUserId = fbkData.id;
                this.setState({
                    model
                })
            }
        }

        this.loadCandidato();

        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    loadCandidato = () => {
        this.setState({loading: true});
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return;
        let decript = jwt_decode(token);
        let id = decript.sub;

        fetch(id, (data)=>{
            let model = data.result;
            model.dataNascimento = this.mysqlToDate(model.dataNascimento.date);
            model.idNacionalidade = model.idPais;
            let setor = {};
            for(let i=0; i<model.setores.length; i++){
                setor[`Setor_${model.setores[i].idSetor}`] = model.setores[i].idSetor
            }
            model.setor = setor;
            this.setState({
                model,
                loading: false
            });
        })
    }
    
    mysqlToDate = (date) => {
        if(!/-/.test(date)) return date;
        date = date.split(' ')[0].split('-');
        return date[2] + '/' + date[1] + '/' + date[0]
    }

    handleSubmit = (values, actions) => {
        let data = Object.assign({}, values);

        let cpf = values.cpf;
        let dataNascimento = values.dataNascimento;
        dataNascimento = dataNascimento.split('/');
        dataNascimento = dataNascimento[2] + '-' + dataNascimento[1] + '-' + dataNascimento[0];

        data.nacionalidade = data.idNacionalidade;
        if(data.idNaturalidade){
            data.naturalidade = data.idNaturalidade;
        }
        data.telefone = data.telefone.replace('-','').insert(-4, '-');
        data.telefone2 = data.telefone2 ? data.telefone2.replace('-','').insert(-4, '-') : '';
        data.telefone3 = data.telefone3 ? data.telefone3.replace('-','').insert(-4, '-') : '';
        data.dataNascimento = dataNascimento;

        let id = 0;
        Object.keys(data.setor).forEach((key) => {
            if(data.setor[key]!== ''){
                data['setor['+id+']'] = data.setor[key];
            }
            id++;
        });
        delete data.setor;

        delete data.escolaridades;
        delete data.setores;

        let token = localStorage.getItem(JWT_TOKEN);
        if(token){
            let decript = jwt_decode(token);
            data.idCandidato = decript.sub;
        }

        save(data, actions, ()=>{
            if(!this.isEdit()){
                loginUser({
                    cpf,
                    dataNascimento
                }, ()=>{
                    this.props.onNext();
                });
            }
        });
    }

    isEdit = () => {
        return /minha-conta\/edit/.test(window.location.href);
    }

	render() {
        if(this.state.loading && this.isEdit()){
            return <p className="py-5 text-center">Carregando...</p>
        }
		return (
            <Container>
                <Row className={`${this.isEdit() ? '' : 'py-5'} d-flex justify-content-center`}>    
                    {this.isEdit() && 
                        <Col xs="12">
                            <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Edição Currículo | Pessoal</h4>
                        </Col>
                    }
                    <Col xs="12" md={this.isEdit() ? "12" : "10"} lg={this.isEdit() ? "12" : "9"}>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this.state.model}
                            onSubmit={this.handleSubmit}
                            enableReinitialize={true}
                            render={(FormikProps) => (
                                <Form onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'nome'
                                            }} form={FormikProps} label="Nome completo *"/>
                                        </Col>
                                        <Col xs="12" lg="3">
                                            <FormInput field={{
                                                name: 'cpf',
                                                tag: Cpf
                                            }} form={FormikProps} label="CPF *"/>
                                        </Col>
                                        <Col xs="12" lg="3">
                                            <FormGroup>
                                                <InputLabel>Sexo</InputLabel>
                                                <div>
                                                    <Checkbox onChange={()=>{
                                                        FormikProps.setFieldValue('sexo', 'M')
                                                        FormikProps.setFieldTouched('sexo', true)
                                                    }} checked={FormikProps.values.sexo === 'M'} inline type="radio" id="sexoM" name="sexo" label="Masculino" />

                                                    <Checkbox onChange={()=>{
                                                        FormikProps.setFieldValue('sexo', 'F')
                                                        FormikProps.setFieldTouched('sexo', true)
                                                    }} checked={FormikProps.values.sexo === 'F'} inline type="radio" id="sexoF" name="sexo" label="Feminino" />
                                                    {FormikProps.touched.sexo && FormikProps.errors.sexo && <div style={{color: '#DC3544'}} >{FormikProps.errors.sexo}</div>}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'dataNascimento',
                                                tag: DateInput
                                            }} form={FormikProps} label="Data de Nascimento *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'email',
                                                type: 'email',
                                                autoComplete: 'username'
                                            }} form={FormikProps} label="E-mail *"/>
                                        </Col>
                                        <Col xs="12" lg="4">
                                            <FormInput field={{
                                                name: 'telefone',
                                                tag: Telefone
                                            }} form={FormikProps} label="Telefone *"/>
                                        </Col>
                                        {this.state.telefones >= 2 &&
                                            <Col xs="12" lg="4">
                                                <FormInput field={{
                                                    name: 'telefone2',
                                                    tag: Telefone
                                                }} form={FormikProps} label="Telefone 2"/>
                                            </Col>
                                        }
                                        {this.state.telefones >= 3 &&
                                            <Col xs="12" lg="4">
                                                <FormInput field={{
                                                    name: 'telefone3',
                                                    tag: Telefone
                                                }} form={FormikProps} label="Telefone 3"/>
                                            </Col>
                                        }
                                        {this.state.telefones < 3 &&
                                            <Col xs="12" lg="3" className="d-flex align-items-end">
                                                <PrimaryButton block size="sm" type="button" style={{color:'white', marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light" onClick={()=>{this.setState({telefones: this.state.telefones + 1})}}>ADICIONAR +</PrimaryButton>
                                            </Col>
                                        }
                                    </Row>
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormSelect field={{
                                                placeholder: "Digite para localizar...",
                                                isSearchable: true,
                                                name: 'idNacionalidade',
                                                loadOptions: loadPaisesOptions,
                                                getOptionLabel: (option) => `${option['nome']}`,
                                                getOptionValue: (option) => {
                                                    return option.idPais;
                                                },
                                                loadingMessage: ()=>"Carregando...",
                                                noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                value: FormikProps.values.idNacionalidade ? {
                                                    idPais: FormikProps.values.idNacionalidade,
                                                    nome: FormikProps.values.nomeNacionalidade,
                                                } : null,
                                                onChange: (value) => {
                                                    FormikProps.setFieldValue("idNacionalidade", value.idPais);
                                                    FormikProps.setFieldValue("nomeNacionalidade", value.nome);
                                                }
                                            }} form={FormikProps} async={true} label="País de Nascimento *"/>
                                        </Col>

                                        {FormikProps.values.idNacionalidade === 36 &&
                                        <Col xs="12" lg="6">
                                            <FormSelect field={{
                                                placeholder: "Digite para localizar...",
                                                isSearchable: true,
                                                name: 'idNaturalidade',
                                                loadOptions: loadCidadesOptions,
                                                getOptionLabel: (option) => `${option['nome']}${option['estado'] ? ' - ' + option['estado']['uf'] : ''}`,
                                                getOptionValue: (option) => {
                                                    return option.idCidade;
                                                },
                                                loadingMessage: ()=>"Carregando...",
                                                noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                value: FormikProps.values.idNaturalidade ? {
                                                    idCidade: FormikProps.values.idNaturalidade,
                                                    nome: FormikProps.values.nomeNaturalidade,
                                                    estado: {
                                                        idEstado: FormikProps.values.idEstado,
                                                        nome: FormikProps.values.nomeEstado,
                                                        uf: FormikProps.values.uf,
                                                    }
                                                } : null,
                                                onChange: (value) => {
                                                    FormikProps.setFieldValue("idNaturalidade", value.idCidade);
                                                    FormikProps.setFieldValue("nomeNaturalidade", value.nome);
                                                    FormikProps.setFieldValue("idEstado", value.estado.idEstado);
                                                    FormikProps.setFieldValue("nomeEstado", value.estado.nome);
                                                    FormikProps.setFieldValue("uf", value.estado.uf);
                                                }
                                            }} form={FormikProps} async={true} label="Cidade de Nascimento *"/>
                                        </Col>
                                        }
                                        
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'linkedin'
                                            }} form={FormikProps} label={<span>LinkedIn <span className="small">(Copie e cole o endereço eletrônico)</span></span>}/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'facebook'
                                            }} form={FormikProps} label={<span>Facebook <span className="small">(Copie e cole o endereço eletrônico)</span></span>}/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <InputLabel>Envie-nos uma foto para seu currículo</InputLabel>
                                            {this.isEdit() && FormikProps.values.foto &&
                                                <img src={FormikProps.values.foto} alt={FormikProps.values.nome} className="img-fluid"/>
                                            }
                                            <input type="file" name="foto" id="foto-empresa" style={{display: 'none'}} onChange={event => this.handleFileChange(FormikProps, event)} accept="image/*, .heic, .heif" />

                                            <label htmlFor="foto-empresa" style={{display: 'flex', alignItems: 'center'}}>
                                                <PrimaryButton onClick={()=>{document.getElementById('foto-empresa').click()}} block size="sm" type="button" style={{color:'white', marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light">ESCOLHER ARQUIVO</PrimaryButton>
                                            </label>
                                            <div style={{flexGrow: 1, marginLeft: '15px', width: '100%'}}>{(FormikProps.values.foto && FormikProps.values.foto.name) ? FormikProps.values.foto.name : ''}</div>
                                            <Thumb file={FormikProps.values.foto} />
                                        </Col>

                                        <Col xs="12">
                                            <InputLabel>Desejo receber notificações de emprego das seguintes áreas:</InputLabel>
                                            {this.state.setores.map((val, idx)=>
                                                <Checkbox key={idx} onChange={(evt)=>{
                                                    let setores = FormikProps.values.setor;
                                                    if(evt.target.checked){
                                                        setores[`Setor_${val.idSetor}`] = val.idSetor;
                                                    } else {
                                                        setores[`Setor_${val.idSetor}`] = "";
                                                    }
                                                    FormikProps.setFieldValue('setor', setores)
                                                }} checked={FormikProps.values.setor && FormikProps.values.setor[`Setor_${val.idSetor}`] > 0} id={`Setor_${val.idSetor}`} type="checkbox" name="setor" label={val.descricao} />
                                            )}
                                        </Col>

                                        <Col xs="12" className="mb-3 mt-2 py-3 px-0 mx-2" style={{borderTop: '1px solid #cccccc', borderBottom: '1px solid #cccccc'}}>
                                            <Checkbox onChange={(evt)=>{
                                                FormikProps.setFieldValue('newsletter', evt.target.checked ? 1 : 0)
                                            }} checked={FormikProps.values.newsletter === 1} inline type="checkbox" id="NewsletterOk" name="newsletter" label="Autorizo receber informativos sobre cursos e novidades em meu e-mail" />
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs="12">
                                            {this.isEdit() && 
                                                <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Salvar <FontAwesomeIcon icon='check' color={PRIMARY_COLOR}/></SecondaryButton>
                                            }
                                            {!this.isEdit() && 
                                                <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Próximo <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default FormCadastroEmpresa;
