import React, { Component } from 'react'

export default class Thumb extends Component {
    state = {
      loading: false,
      thumb: undefined,
      name: null,
    };
  
    componentWillReceiveProps(nextProps) {
      if (!nextProps.file || typeof nextProps.file !== 'object' || nextProps.file.name === this.state.name) { return; }
  
      this.setState({ loading: true }, () => {
        let reader = new FileReader();
  
        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result, name: nextProps.file.name });
        };
  
        reader.readAsDataURL(nextProps.file);
      });
    }
  
    render() {
        const { file } = this.props;
        const { loading, thumb } = this.state;
    
        if (!file || typeof file !== 'object') { return null; }
    
        if (loading) { return <small>Carregando...</small>; }
    
        return (<img src={thumb}
            alt={file.name}
            title={file.name}
            style={{marginTop: '5px', maxWidth: '100%'}}/>);
    }
  }