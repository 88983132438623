import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'

export default class Cep extends Component {
    constructor() {
        super();
        
        let anyNum = /[0-9]/;
        this.state = { 
            value: null,
            mask: [anyNum, anyNum, anyNum, anyNum, anyNum, '-', anyNum, anyNum, anyNum]
        }
    }

    render() {
        let props = {}
        Object.keys(this.props).map((idx)=>{ // Não pode-se colocar funções customizadas
            if(idx !== 'inputRef') 
                props[idx] = this.props[idx]
            
            return null
        })
        return (
            <MaskedInput guide={true} mask={this.state.mask} {...props}/>
        )
    }
}