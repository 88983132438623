// Geral
import React from "react";

// Componentes
import { Container, Row, Col, Media } from 'reactstrap';
import { PrimaryP, SecondaryP, BackgroundPrimaryContainer, BackgroundSecondaryCol, CustomInput, ContainerFluidRight, NewsLetterCol } from "./styles";
import { SecondaryButton } from "components/Buttons/styles";
import { PRIMARY_COLOR, LINK_INSTA, LINK_FACE_NUCLEO_JOVENS, LINK_FACE_NUCLEO_RH, LINK_MAPS, EMAIL_CONTATO } from "helpers/consts";
import Slider from "react-slick";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link } from "react-router-dom";

import iconFace from 'assets/img/iconFace.png';
import iconMap from 'assets/img/iconMap.png';
import logoDoSul from 'assets/img/dosul.png';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MySwal = withReactContent(Swal);

// Validação
const validationSchema = Yup.object().shape({
    email: Yup.string().required('Por favor digite um e-mail').email('Por favor digite um e-mail válido'),
});

class Footer extends React.Component {

    constructor() {
        super();
         
        this._initialValues = {
            email: '',
        };     

        this.state = {
            instagram: [],
        };
    }

    loadData = () =>{
        axios.get(`instagram`)
        .then(res => {
            this.setState({
                instagram:res.data.results
            });
        })
        .catch(err => {
            if(err.response){
                MySwal.fire({
                    type: 'error',
                    title: "Não foi possível obter os dados. " + err.response.data.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
            }
        });
    }

    componentDidMount() {
        this.loadData();
    }


    handleSubmit = (values, actions) => {
        let email = values.email;
        axios.post('newsletter', {email}, {headers: {'Accept': 'application/json'}})
        .then(() => {
            MySwal.fire({
                type: 'success',
                title: 'E-mail cadastrado! Obrigado!',
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
            });
        }).catch(err => {
            actions.setSubmitting(false);
            if(err.response.status===400){
                MySwal.fire({
                    type: 'info',
                    title: err.response.data.errors.email,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }else{
                MySwal.fire({
                    type: 'danger',
                    title: 'Houve algum problema. Tente mais tarde',
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        });
    }


    render(){
        let instagramDisplay;
        if(this.state.instagram){
            instagramDisplay = this.state.instagram.map((insta,idx) => {
                return (
                    <div key={idx} style={{height:'200px'}}>
                        {insta.type==='img' ? (
                            <img src={insta.src} alt='Imagem Instagram' className="img-fluid" style={{height: '200px'}}/>
                        ) : (
                            <video src={insta.src} controls style={{height: '200px'}}/>
                        )}
                    </div>
                );
            });

            var settings = {
                autoplay: true,
                infinite: true,
                speed: 500,
                centerMode: true,
                variableWidth: true,
                swipeToSlide: true,
                arrows: false,
                lazyLoad: 'ondemand',
            };
        }

        return (
            <div>
                <BackgroundPrimaryContainer fluid={true} className="border-bottom p-0 px-xl-3">
                    <ContainerFluidRight>
                        <Row>
                            <NewsLetterCol xs='12' xl='5' className="py-4">
                                <SecondaryP className="h4">NEWSLETTER</SecondaryP>
                                <Formik
                                    validationSchema={validationSchema}
                                    initialValues={this._initialValues}
                                    onSubmit={this.handleSubmit}
                                    render={(FormikProps) => (
                                        <Form onSubmit={FormikProps.handleSubmit}>
                                            <CustomInput field={{
                                                value: FormikProps.email,
                                                name: 'email',
                                                type: 'email',
                                                autoComplete: 'e-mail'                                                
                                            }} form={FormikProps} placeholder="Digite seu e-mail" onChange={(event) =>{FormikProps.setFieldValue('email',event.target.value)}}/>
                                            {FormikProps.errors.email && <div id="feedback" className="text-danger">{FormikProps.errors.email}</div>}
                                            <SecondaryButton disabled={FormikProps.isSubmitting} type="submit" className="my-3">ENVIAR <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                                        </Form>
                                    )}
                                />
                            </NewsLetterCol>
                            <BackgroundSecondaryCol xs='12' sm="5" lg="auto" className="text-center py-5 font-weight-bold">
                                <a target="_blank" rel="noopener noreferrer" href={LINK_INSTA} title="Instagram">
                                    <p className="h2"><FontAwesomeIcon icon={['fab','instagram']} color={PRIMARY_COLOR}/></p>
                                    <PrimaryP>@acirne_rionegrinho</PrimaryP>
                                </a>
                            </BackgroundSecondaryCol>
                            <Col xs="12" sm="7" lg={true} className="p-0" style={{height: '200px',overflow:'hidden'}}>
                                {this.state.instagram ? (
                                    <Slider {...settings}>
                                        {instagramDisplay}
                                    </Slider>
                                ) : (
                                    <div className="text-center text-white my-5 mx-4">Carregando imagens...</div>
                                )}
                            </Col>
                        </Row>
                    </ContainerFluidRight>
                </BackgroundPrimaryContainer>
                <Container>
                    <Row className="text-muted py-3 border-bottom">
                        <Col xs="12" sm="6" lg={true} className="my-3 text-center">
                            <a target="_blank" rel="noopener noreferrer" href={LINK_FACE_NUCLEO_JOVENS} title="Facebook" style={{color:'inherit'}}>
                                <Media>
                                    <Media className="px-1" middle>
                                        <Media object src={iconFace} alt="Núcleo de Jovens Empreendedores" />
                                    </Media>
                                    <Media body>Núcleo de Jovens Empreendedores</Media>
                                </Media>                    
                            </a>
                        </Col>
                        <Col xs="12" sm="6" lg={true} className="my-3 text-center">
                            <a target="_blank" rel="noopener noreferrer" href={LINK_FACE_NUCLEO_RH} title="Facebook" style={{color:'inherit'}}>
                                <Media>
                                    <Media className="px-1" middle>
                                        <Media object src={iconFace} alt="Núcleo de Recursos Humanos Acirne" />
                                    </Media>
                                    <Media body>Núcleo de Recursos Humanos Acirne</Media>
                                </Media>                    
                            </a>
                        </Col>
                        <Col xs="12" sm="6" lg={true} className="my-3 text-center">
                            <a target="_blank" rel="noopener noreferrer" href={LINK_MAPS} title="Endereço" style={{color:'inherit'}}>
                                <Media>
                                    <Media className="px-1" middle>
                                        <Media object src={iconMap} alt="Generic placeholder image" />
                                    </Media>
                                    <Media body>Rua Maria Scholz, 62 - Centro<br/>Rio Negrinho - SC - 89295-000</Media>
                                </Media>
                            </a>
                        </Col>
                        <Col xs="12" sm="6" lg={true} className="my-3 text-center text-right" style={{color:PRIMARY_COLOR}}>
                            <a rel="noopener noreferrer" href="tel:554736442131" title="Endereço" style={{color:'inherit'}}>
                                <small className="mt-2 font-weight-bold m-0 d-inline-block">(47)</small><p className="h4 d-inline-block">3644.2131</p>
                            </a>
                            <a rel="noopener noreferrer" href={`mailto:${EMAIL_CONTATO}`} title="Endereço" style={{color:'inherit'}}>
                                <small className="text-muted d-inline-block">{EMAIL_CONTATO}</small>
                            </a>
                        </Col>
                    </Row>
                    <Row className="text-muted py-3 border-bottom justify-content-between">
                        <Col>
                            <small className="text-muted">
                                rionegrinhoempregos.org.br - 
                                Todos os direitos reservados - 
                                <Link to={`${process.env.PUBLIC_URL}/termos-e-condicoes`} title="Termos e condições" style={{color:'inherit'}}>Termos e condições - </Link>
                                <Link to={`${process.env.PUBLIC_URL}/politicas-privacidade`} title="Políticas de privacidade" style={{color:'inherit'}}>Políticas de privacidade</Link>
                            </small>
                        </Col>
                        <Col xs="auto">
                            <a target="_blank" rel="noopener noreferrer" href='https://dosul.digital/' title="doSul Sistemas">
                                <img src={logoDoSul} title="doSul Sistemas - Acessar site" alt="doSul Sistemas" style={{height: "30px",}}/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Footer;