import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'reactstrap';
import jwt_decode from 'jwt-decode';

// Consts
import { PRIMARY_COLOR, JWT_TOKEN } from 'helpers/consts';

import { loadOptions as loadCidadesOptions } from 'actions/cidades';
import { saveEndereco as save, fetch } from 'actions/candidatos';

// Componentes
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import { SecondaryButton } from 'components/Buttons';
import Cep from 'components/FormInput/Cep';

// Validação
import validationSchema from './validation';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FormEndereco extends React.Component {
    constructor() {
        super();

        this._initialValues = {
            endereco: "",
            cep: "",
            bairro: "",
            idCidade: null,
            nomeCidade: '',
            idEstado: null,
            nomeEstado: '',
            uf: '',
        };     
        this._isMounted = false;

        this.state = {
            model: this._initialValues,
            loading: false,
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.loadCandidato();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleSubmit = (values, actions) => {
        let data = Object.assign({}, values);
        
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return;
        let decript = jwt_decode(token);
    
        data.cidade = data.idCidade;
        data.idCandidato = decript.sub;
        save(data, actions, ()=>{
            if(!this.isEdit()){
                this.props.onNext();
            }
        });
    }

    loadCandidato = () => {
        this.setState({
            loading: true
        })
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return;
        let decript = jwt_decode(token);
        let id = decript.sub;

        fetch(id, (data)=>{
            let model = data.result;
            this.setState({
                model,
                loading: false
            });
        })
    }

    isEdit = () => {
        return /minha-conta\/edit/.test(window.location.href);
    }

	render() {
        if(this.state.loading && this.isEdit()){
            return <p className="py-5 text-center">Carregando...</p>
        }
		return (
            <Container>
                <Row className={`${this.isEdit() ? '' : 'py-5'} d-flex justify-content-center`}>    
                    {this.isEdit() && 
                        <Col xs="12">
                            <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Edição Currículo | Endereço</h4>
                        </Col>
                    }
                    <Col xs="12" md={this.isEdit() ? "12" : "10"} lg={this.isEdit() ? "12" : "9"}>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this.state.model}
                            onSubmit={this.handleSubmit}
                            enableReinitialize={true}
                            render={(FormikProps) => (
                                <Form onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'cep',
                                                tag: Cep
                                            }} form={FormikProps} label="CEP *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'endereco'
                                            }} form={FormikProps} label="Endereço *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'bairro'
                                            }} form={FormikProps} label="Bairro *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormSelect field={{
                                                placeholder: "Digite para localizar...",
                                                isSearchable: true,
                                                name: 'idCidade',
                                                loadOptions: loadCidadesOptions,
                                                getOptionLabel: (option) => `${option['nome']}${option['estado'] ? ' - ' + option['estado']['uf'] : ''}`,
                                                getOptionValue: (option) => {
                                                    return option.idCidade;
                                                },
                                                loadingMessage: ()=>"Carregando...",
                                                noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                value: FormikProps.values.idCidade ? {
                                                    idCidade: FormikProps.values.idCidade,
                                                    nome: FormikProps.values.nomeCidade,
                                                    estado: {
                                                        idEstado: FormikProps.values.idEstado,
                                                        nome: FormikProps.values.nomeEstado,
                                                        uf: FormikProps.values.uf,
                                                    }
                                                } : null,
                                                onChange: (value) => {
                                                    FormikProps.setFieldValue("idCidade", value.idCidade);
                                                    FormikProps.setFieldValue("nomeCidade", value.nome);
                                                    FormikProps.setFieldValue("idEstado", value.estado.idEstado);
                                                    FormikProps.setFieldValue("nomeEstado", value.estado.nome);
                                                    FormikProps.setFieldValue("uf", value.estado.uf);
                                                }
                                            }} form={FormikProps} async={true} label="Cidade *"/>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs="12">
                                        {this.isEdit() && 
                                            <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Salvar <FontAwesomeIcon icon='check' color={PRIMARY_COLOR}/></SecondaryButton>
                                        }
                                        {!this.isEdit() && 
                                            <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Próximo <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                                        }
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default FormEndereco;
