import * as Yup from 'yup';
import { addInYup as addCpf } from "helpers/validation/Cpf"; 
import { addInYup as addPhone } from "helpers/validation/Phone"; 
import { addInYup as addDateYup } from "helpers/validation/Date";

// Adiciona funções customizadas no YUP (Não existentes por padrão)
addCpf();
addPhone();
addDateYup();

const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Por favor informe o nome da Empresa'),
    cpf: Yup.string().required('Por favor digite o CPF').cpf('Por favor informe um CPF válido'),
    dataNascimento: Yup.string().required('Por favor digite a data de nascimento').date('Por favor digite uma data válida'),    
    idNacionalidade: Yup.mixed().required('Por favor selecione a nacionalidade'),
    telefone: Yup.string().required('Por favor digite um telefone válido').phone('Por favor digite um telefone válido'),
    telefone2: Yup.string().notRequired().phone('Por favor digite um telefone válido').nullable(),
    telefone3: Yup.string().notRequired().phone('Por favor digite um telefone válido').nullable(),
    email: Yup.string().required('Por favor digite um e-mail').email('Por favor digite um e-mail válido'),
    sexo: Yup.string().required('Sexo é obrigatório'),
});

export default validationSchema;