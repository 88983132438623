import React from 'react';

import { Container, Row, Col, NavItem, TabContent, TabPane } from 'reactstrap';
import { BoxVaga } from 'components/BoxVagas';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'helpers/consts';
import { PrimaryButton } from 'components/Buttons';
import { CustomNav, CustomNavLink } from './style';

import { NavLink } from 'react-router-dom';

import { defaultFetchList } from 'actions/default';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class VagasPorCategorias extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: 0,
            categorias: [],
        };
    }

    componentDidMount() {
        let reqData = {
            s: ''
        };
        var promise = new Promise((res, rej) => {
            defaultFetchList('vagas/categorias', reqData, res, rej);
        });

        promise.then((data) => {
            this.setState({
                categorias: data.results
            });
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
        let tabPanes = [];
        let navItens;
        if(this.state.categorias.length){
            navItens = this.state.categorias.map((categoria, idx) => {
                tabPanes.push(
                    <TabPane key={idx} tabId={idx}>{
                            categoria.vagas.map((vaga, idx2) => {
                                return (<Row key={idx2}><Col sm="12" className="p-0"><BoxVaga vaga={vaga} /></Col></Row>);
                            })
                        }
                    </TabPane>);
                return (
                    <NavItem key={idx}><CustomNavLink className={this.state.activeTab === idx ? 'active' : ''} onClick={() => { this.toggle(idx); }}>{categoria.descricao}</CustomNavLink></NavItem>
                )
            });
        }else{
            navItens = [<NavItem key='0'><CustomNavLink className='active'>Carregando...</CustomNavLink></NavItem>];
            tabPanes = [<TabPane key='0' tabId={0}><Row><Col sm="12" className="p-0 text-center py-5">Carregando...</Col></Row></TabPane>];
        }
        
        return (
            <Container fluid={true}>
                <Container>
                    <Row className="py-5">
                        <Col xs="12">
                            <p className="h1 m-0" style={{ color: PRIMARY_COLOR }}>VAGAS POR CATEGORIAS</p>
                            <p className="h4 text-muted font-weight-light">Encontre a vaga certa para o seu perfil profissional!</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomNav tabs className="justify-content-center">{navItens}</CustomNav>
                            <TabContent activeTab={this.state.activeTab}>{tabPanes}</TabContent>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="text-center pb-5">
                            <PrimaryButton tag={NavLink} to={`${process.env.PUBLIC_URL}/vagas`}>VER TODAS <FontAwesomeIcon icon='arrow-right' color={SECONDARY_COLOR} /></PrimaryButton>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    };
}

export default (VagasPorCategorias);
