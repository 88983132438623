import styled from 'styled-components';

import { PRIMARY_COLOR } from 'helpers/consts';
import { SECONDARY_COLOR } from 'helpers/consts';

export const Titulo = styled.p`
    color:${PRIMARY_COLOR}
    border-bottom: 3px solid ${PRIMARY_COLOR}
`
export const Setor = styled.div`
    background-color:${SECONDARY_COLOR}
    float:left
    margin-right: 15px
    padding:5px 15px
`