import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

import { BackGround, H1 } from './styles';

import bkg from 'assets/img/bkgPatrocinadores.jpg';
import { THIRD_COLOR } from 'helpers/consts';

class BannerPatrocinadores extends React.Component {
    render() {
        return (
            <BackGround source={bkg}>
                <Container fluid={true}>
                    <Container>
                        <Row className="py-5 align-items-end">
                            <Col xs="12" md="6" className="my-4">
                                <p className="text-white font-weight-light m-0">CONHEÇA QUEM ESTÁ</p>
                                <H1 className="font-weight-bold" style={{lineHeight:'0.5'}}>nos apoiando</H1>
                            </Col>
                            <Col xs="12" md="6" className="my-4 text-white font-weight-light">
                                Deseja divulgar sua empresa neste espaço? Seja um patrocinador! <Link to={`${process.env.PUBLIC_URL}/contato`} title="Contato" style={{color:THIRD_COLOR}}>ENTRE EM CONTATO</Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (BannerPatrocinadores);
