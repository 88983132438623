import styled from 'styled-components';
import { PRIMARY_COLOR } from 'helpers/consts';
import { SECONDARY_COLOR } from 'helpers/consts';

import { Col } from 'reactstrap';


export const P = styled.p`
    &::before{
        position: absolute;
        content: '\\00a0';
        top: -15px;
        width: 40px;
        height: 3px;
        left: 50%;
        transform: translateX(-50%);
        background-color:${SECONDARY_COLOR};
    }
    color:${PRIMARY_COLOR}
`
export const BorderLeftCol = styled(Col)`
    @media screen and (min-width:992px){
        border-left:1px solid #cbcbca
        &:first-child {
            border: none;
        }
    }`