import styled from 'styled-components';
import { Col, Row } from 'reactstrap';

import { PRIMARY_COLOR, SECONDARY_COLOR } from 'helpers/consts';

const DANGER_COLOR = "#cf5125";
const SUCCESS_COLOR = "#2ba959";

export const TituloRow = styled(Row)`
    border-bottom: 2px solid ${PRIMARY_COLOR}
`

export const Titulo = styled(Col).attrs(()=>({
    className: 'px-0'
}))`
    color:${PRIMARY_COLOR};
    font-size: 18px;
    font-weight: 600!important;
`

export const Info = styled.p`
    color: #767676;
    font-weight: 500;
    margin:0;

    & b {
        color: black;
        font-weight: 500!important;
    }
`

export const Situacao = styled(Col).attrs((props)=>({
    children: props.status === -1 ? 'AG. AVALIAÇÃO' : props.status === 0 ? 'BLOQUEADA' : props.status === 1 ? 'EM ABERTO' : 'PREENCHIDA',
    xs: '12',
    sm: '6',
    md: '4',
    lg: '3',
    className: 'rounded'
}))`
    margin-top: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    color: white;
    text-align:center;
    background-color: ${(props)=>props.status === -1 ? PRIMARY_COLOR : props.status === 0 ? SECONDARY_COLOR : props.status === 1 ? SUCCESS_COLOR : DANGER_COLOR}
`