import React from "react";

import { NAME_SITE, DESCRIPTION_SITE, KEYWORDS_SITE } from 'helpers/consts';
import { Helmet } from "react-helmet";

class Cabecalho extends React.Component {
	render() {
        const props = this.props;
		return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{NAME_SITE}{props.title ? (' - '+props.title) : ('')}</title>
                <link rel="canonical" href={window.location.origin}/>
                <meta name="description" content={props.description ? (props.description) : (DESCRIPTION_SITE)}/>
                <meta name="keywords" content={props.keywords ? (props.keywords) : (KEYWORDS_SITE)}/>
                <meta property="og:image" content={window.location.origin+process.env.PUBLIC_URL+'/rio-negrinho-empregos.jpg'}/>
            </Helmet>
		);
	}
}

export default Cabecalho;
