import React from 'react';

import { Row, Col } from 'reactstrap';
import { Titulo, Setor } from './styles'

class BoxCapacitacao extends React.Component {

    mysqlToDate = (date) => {
        if(!/-/.test(date)) return date;
        date = date.split(' ')[0].split('-');
        return date[2] + '/' + date[1] + '/' + date[0]
    }

	render() {
        const capacitacao = this.props.capacitacao;
        console.log(new Date(capacitacao.data_inicial));
        return (
            <Col xs="12" className="my-5">
                <Row>
                    <Col className="mb-3">
                        <Setor>Início {this.mysqlToDate(capacitacao.data_inicial)}</Setor>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Titulo className="h4">{capacitacao.titulo}</Titulo>
                        <p className="text-muted m-0"><b>Informações: </b>{capacitacao.informacoes}</p>
                        <p className="text-muted m-0"><b>Instrutor: </b>{capacitacao.instrutor}</p>
                        <p className="text-muted m-0"><b>Local: </b>{capacitacao.local}</p>
                        <p className="text-muted mt-3">{capacitacao.descricao}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <a className="btn btn-secondary text-white" href={capacitacao.link} target="_blank" rel="noopener noreferrer" title='Ver no site da ACIRNE' style={{color:'inherit'}}>Mais Detalhes</a>
                    </Col>
                </Row>
            </Col>
        );
    }
};

export { BoxCapacitacao };