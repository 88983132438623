import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { PRIMARY_COLOR,THIRD_COLOR } from 'helpers/consts';

import bkg from 'assets/img/iconMarcoHistorico.png';

export const DateCol = styled(Col)`
    color: ${PRIMARY_COLOR}
    font-weight:bold
    padding-left: 60px;
    font-size: 35px;
    color: ${THIRD_COLOR};
    &::before{
        position: absolute;
        content: '\\00a0';
        top: 50%;
        transform: translateY(-50%);
        width: 33px;
        height: 33px;
        left: 0px;
        background:url(${bkg});
        background-size:cover;
    }
`
export const DateRow = styled(Row)`
    &::before{
        position: absolute;
        content: '\\00a0';
        top: 0px;
        width: 1px;
        height: 100%;
        left: 15px;
        background-color:#d3d5dd;
        background-size:cover;
    }
`
export const InfoCol = styled(Col)`
    &::after{
        position: absolute;
        content: '\\00a0';
        bottom: -45px;
        height: 1px;
        background-color:#d3d5dd;
        background-size:cover;
        left: 15px;
        right: 15px;
    }
`
