import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'

export default class Telefone extends Component {
    constructor() {
        super();
        
        this._maskTelefone = ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
        this._maskCelular = ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    }

    render() {
        let props = {}
        Object.keys(this.props).map((idx)=>{ // Não pode-se colocar funções customizadas
            if(idx !== 'inputRef') 
                props[idx] = this.props[idx]
            
            return null
        })
        return (
            <MaskedInput guide={false} mask={(props.value && props.value.replace(/[^0-9]/g, '').length > 10) ? this._maskCelular : this._maskTelefone} {...props}/>
        )
    }
}