import React from 'react';

import { defaultFetchList } from 'actions/default';

import { Container, Row, Col } from 'reactstrap';
import { BoxCapacitacao } from 'components/BoxCapacitacoes';

class Capacitacoes extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
            capacitacoes: [<div key="0" className="text-center">Carregando...</div>],
		};
    }

    componentDidMount(){
        let reqData = {
            s: '',
            pg: 0,            
        };
        var promise = new Promise((res, rej) => {
            defaultFetchList('capacitacoes', reqData, res, rej);
        });
        
        promise.then((data) => {
            if(data.results.length){
                this.setState({
                    capacitacoes: data.results
                });
            }
        });
    }


    render() {
        const capacitacoesDisplay = this.state.capacitacoes.map((capacitacao,idx) => {
            return (
                <BoxCapacitacao key={idx} capacitacao={capacitacao}/>
            );
        });

        return (
            <div>
                <Container fluid={true}>
                    <Container>
                        <Row>
                            <Col sm="12" className="p-0">
                                {capacitacoesDisplay}
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    };
}

export default (Capacitacoes);
