import React from "react";

import BannerVagas from 'components/Banners/Vagas'
import CompVagas from "components/Vagas";
import Cabecalho from "components/Cabecalhos";

//Analytics
import ReactGA from 'react-ga';

class Vagas extends React.Component {
	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}

	render() {
		return (
			<div>
				<Cabecalho title='Vagas' description='description vagas' keywords='keys vagas'/>
				<BannerVagas/>
                <CompVagas/>
			</div>
		);
	}
}

export default Vagas;
