import React from 'react';
import { Container } from 'reactstrap';

import { DateRow, DateCol, InfoCol } from './styles';

class MarcosHistoricos extends React.Component {
	render() {
		return (
			<Container fluid={true} className="border-bottom">
				<Container className="py-4">
					<DateRow className="justify-content-center align-items-center py-5 position-relative">
						<DateCol xs="12" md="auto">2015</DateCol>
						<InfoCol className="pl-4 pl-md-2">
							Durante o ano de 2015 os Núcleos de Gestores em Recursos Humanos e de Jovens Empreendedores, vinculados a Associação Empresarial de Rio Negrinho, em suas reuniões buscavam soluções para os 
							principais limitadores do desenvolvimento da atividade empresarial deste município. Surgindo então a ideia de criar uma plataforma de empregos, a qual os núcleos acima mencionados uniram forças 
							para viabilizar o desenvolvimento e operação deste projeto.
						</InfoCol>
					</DateRow>
					<DateRow className="justify-content-center align-items-center py-5 position-relative">
						<DateCol xs="12" md="auto">2016</DateCol>
						<InfoCol className="pl-4 pl-md-2">
							Foi então que em março de 2016 esta ferramenta foi disponibilizada para sociedade rionegrinhense e de seus arredores. Tendo como principal objetivo conectar ótimas oportunidades a grandes talentos.
						</InfoCol>
					</DateRow>
					<DateRow className="justify-content-center align-items-center py-5 position-relative">
						<DateCol xs="12" md="auto">2017</DateCol>
						<InfoCol className="pl-4 pl-md-2">
							Em 2017, visando a melhoria continua da plataforma web, foram realizadas diversas atualizações. Buscando acessibilidade, desenvolveu-se o aplicativo para aumentar  o acesso e a divulgação das 
							oportunidades de empregos e capacitações.
						</InfoCol>
					</DateRow>
					<DateRow className="justify-content-center align-items-center py-5 position-relative">
						<DateCol xs="12" md="auto">2018</DateCol>
						<InfoCol className="pl-4 pl-md-2">
							No ano de 2018 houve a renovação dos patrocinadores, os quais, são fundamentais para manter a plataforma em atividade. Dessa forma, oportuniza que as empresas divulguem suas vagas de 
							forma gratuita para toda população.
						</InfoCol>
					</DateRow>
					<DateRow className="justify-content-center align-items-center py-5 position-relative">
						<DateCol xs="12" md="auto">2019</DateCol>
						<InfoCol className="pl-4 pl-md-2">
							Pensando em inovar, no ano de 2019, aumenta-se as funcionalidades da plataforma, cria-se um ambiente moderno e prático para todos os usuários.
						</InfoCol>
					</DateRow>
				</Container>
			</Container>
		);
	}
}


export default MarcosHistoricos;