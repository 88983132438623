// Geral
import React from "react";
import { Row,Col } from 'reactstrap';
import { withRouter, Link } from "react-router-dom";
import NavbarLinks from "./NavbarLinks";
import jwt_decode from 'jwt-decode';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Ações / Métodos
import imageColorOverlay from 'helpers/imageColorOverlay';

//imgs
import logo from 'assets/img/logoDark.png';
import home from 'assets/img/iconHome.png';
import iconFace from 'assets/img/iconFace.png';
import iconInstagram from 'assets/img/iconInstagram.png';
import iconLinkedin from 'assets/img/iconLinkedin.png';
import iconCandidato from 'assets/img/iconCandidato.png';
import iconEmpresa from 'assets/img/iconEmpresaBlack.png';
import { NAME_SITE,LINK_FACE,LINK_INSTA,LINK_LINKEDIN,PRIMARY_COLOR,SECONDARY_COLOR,JWT_TOKEN } from 'helpers/consts';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//components
import { PrimaryButton, SecondaryButton } from 'components/Buttons';

//Analytics
import ReactGA from 'react-ga';

const MySwal = withReactContent(Swal);

function NavBar() {
    let token = localStorage.getItem(JWT_TOKEN) ? jwt_decode(localStorage.getItem(JWT_TOKEN)) : null;
    return (
        <Row className='justify-content-between align-items-center py-3'>
            <Col className="d-none d-lg-block">
				<a target="_blank" rel="noopener noreferrer" href={LINK_FACE}><img src={iconFace} alt="Facebook" className="mr-1"/></a>
				<a target="_blank" rel="noopener noreferrer" href={LINK_INSTA}><img src={iconInstagram} alt="Instagram" className="mr-1"/></a>
				<a target="_blank" rel="noopener noreferrer" href={LINK_LINKEDIN}><img src={iconLinkedin} alt="Linkedin" className="mr-1"/></a>
            </Col>
            <Col className="text-center">
				<Link to={`${process.env.PUBLIC_URL}/`} title={NAME_SITE}><img src={logo} alt={NAME_SITE} className="img-fluid"/></Link>
            </Col>
            <Col className="text-right">
                {!token &&
				    <PrimaryButton tag={Link} to={`${process.env.PUBLIC_URL}/login`} className="text-white">CADASTRE-SE <FontAwesomeIcon className='ml-2' icon='arrow-right' color={SECONDARY_COLOR}/></PrimaryButton>
                }
                {token &&
                    <>
                    {token.platform === 'empresa' &&
                        <PrimaryButton tag={Link} to={`${process.env.PUBLIC_URL}/empresa`} className="text-white">MINHA CONTA <img src={iconEmpresa} alt="Empresa" className='ml-2' style={{
                            filter: imageColorOverlay(SECONDARY_COLOR),
                            height: '18px'
                        }}/></PrimaryButton>
                    }
                    {token.platform === 'candidato' &&
                        <PrimaryButton tag={Link} to={`${process.env.PUBLIC_URL}/minha-conta`} className="text-white">MINHA CONTA <img src={iconCandidato} alt="Candidato" className='ml-2' style={{
                            filter: imageColorOverlay(SECONDARY_COLOR),
                            height: '18px'
                        }}/></PrimaryButton>
                    }
                    </>
                }
            </Col>
        </Row>
	);
};

class basicSubNavBar extends React.Component {

    popUpPesquisa(){
        MySwal.fire({
            title: 'Que vaga você está procurando?',
            type: 'question',
            input: 'text',
            showCancelButton: true,
            confirmButtonText: <PrimaryButton tag="span" size="lg" title="Procurar">Procurar</PrimaryButton>,
            cancelButtonText: <SecondaryButton tag="span" size="lg" color="white" title="Cancelar">Cancelar</SecondaryButton>,
            reverseButtons: true
        }).then((result) => {
            if(result.value){
                ReactGA.event({
                    category: 'Vagas',
                    action: 'Pesquisa: '+result.value
                });
                this.props.history.push(`${process.env.PUBLIC_URL}/vagas/procurar/`+result.value);                
            }
        });
    }

    render(){
        return (
            <Row className='justify-content-between align-items-center py-3'>
                <Col xs='auto' className="border-right">
                    <Link to={`${process.env.PUBLIC_URL}/`} title={NAME_SITE}><img src={home} alt={NAME_SITE} /></Link>
                </Col>
                <Col className="text-center">
                    <NavbarLinks/>
                </Col>
                <Col xs='auto' className="text-right border-left">
                    <FontAwesomeIcon icon='search' color={PRIMARY_COLOR} onClick={()=>{this.popUpPesquisa()}}/>
                </Col>
            </Row>
        );
    }
}

const SubNavBar = withRouter(basicSubNavBar);
export {NavBar, SubNavBar};