import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { BackGround,H1 } from './styles';

import bkg from 'assets/img/bkgQuemSomos.jpg';

class BannerQuemSomos extends React.Component {
    render() {
        return (
			<BackGround source={bkg}>
                <Container fluid={true}>
                    <Container>
                        <Row className="py-5 align-items-center">
                            <Col xs="12" md="auto">
                                <H1 className="font-weight-bold m-0">SOBRE NÓS</H1>
                            </Col>
                            <Col xs="12" md="auto" className="text-white font-weight-light">
                                Conheca os marcos importantes<br/>de nossa história
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (BannerQuemSomos);
