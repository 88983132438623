import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import jwt_decode from 'jwt-decode';

// Consts
import { PRIMARY_COLOR, JWT_TOKEN } from 'helpers/consts';

import { loadOptions as loadInstituicoesOptions, save as saveInstituicao } from 'actions/instituicoes';
import { loadOptions as loadCursosOptions, save as saveCurso } from 'actions/cursos';
import { saveEscolaridade as save, fetch } from 'actions/candidatos';

// Componentes
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import { SecondaryButton, PrimaryButton } from 'components/Buttons';
import DateInput from 'components/FormInput/Date';
import { InputLabel } from 'components/FormInput/styles';
import Checkbox from 'components/Checkbox';

// Validação
import validationSchema from './validation';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FormEscolaridade extends React.Component {
    constructor() {
        super();

        this._initialValues = {
            nivelFormacao: "FI",
            curso: [],
            formacao: []
        };     
        this._isMounted = false;
        this._maxCursos = 6;
        this._maxFormacoes = 3;

        for(let i = 0; i<this._maxFormacoes;i++){
            this._initialValues.formacao.push({
                atualmente: true,
                idCurso: null,
                nomeCurso: '',
                dataInicial: '',
                dataFinal: '',
                idInstituicao: null,
                nomeInstituicao: ''
            });
        }

        for(let i = 0; i<this._maxCursos;i++){
            this._initialValues.curso.push({
                cargaHoraria: '',
                idCurso: null,
                nomeCurso: '',
			    data: '',
                idInstituicao: null,
                nomeInstituicao: ''
            });
        }

        this.state = {
            model: this._initialValues,
            loading: false,
            maxCursos: 6,
            maxFormacoes: 3,
            cursos: 1,
            formacoes: 1
        }
    }

    removeFormacao = () => {
        let model = this.state.model;
        let formacao = model.formacao;
        let lenFormacoes = this.state.formacoes;
        formacao[lenFormacoes-1] = {
            idCandidatoEscolaridade: null,
            atualmente: true,
            idCurso: null,
            nomeCurso: '',
            dataInicial: '',
            dataFinal: '',
            idInstituicao: null,
            nomeInstituicao: ''
        };
        model.formacao = formacao;
        this.setState({model, formacoes: (lenFormacoes-1)});
    }

    removeCurso = () => {
        let model = this.state.model;
        let curso = model.curso;
        let lenCursos = this.state.cursos;
        curso[lenCursos-1] = {
            idCandidatoCurso: null,
            cargaHoraria: '',
            idCurso: null,
            nomeCurso: '',
            data: '',
            idInstituicao: null,
            nomeInstituicao: ''
        };
        model.curso = curso;
        this.setState({model, cursos: (lenCursos-1)});
    }

    componentDidMount(){
        this._isMounted = true;
        this.loadCandidato();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    dateToMySQL = (date) => {
        if(!/\//.test(date)) return date;
        date = date.split('/');
        return date[2] + '-' + date[1] + '-' + date[0];
    }

    handleSubmit = (values, actions) => {
        let sendData = {
            nivelFormacao: values.nivelFormacao,
            formacao: [],
            curso: []
        };

        for(let i=0; i < values.formacao.length; i++){  
            let formacao = values.formacao[i];
            if(formacao.idCurso && formacao.idInstituicao && formacao.dataInicial !== ''){
                sendData.formacao.push({
                    idCandidatoEscolaridade: formacao.idCandidatoEscolaridade || null,
                    atualmente: formacao.dataFinal === '',
                    curso: formacao.idCurso,
                    dataFinal: this.dateToMySQL(formacao.dataFinal),
                    dataInicial: this.dateToMySQL(formacao.dataInicial),
                    instituicao: formacao.idInstituicao
                });
            }
        }

        for(let i=0; i < values.curso.length; i++){  
            let curso = values.curso[i];
            if(curso.idCurso && curso.idInstituicao && curso.data !== '' && curso.cargaHoraria !== ''){
                sendData.curso.push({
                    idCandidatoCurso: curso.idCandidatoCurso || null,
                    cargaHoraria: curso.cargaHoraria,
                    curso: curso.idCurso,
                    data: this.dateToMySQL(curso.data),
                    instituicao: curso.idInstituicao
                });
            }
        }

        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return;
        let decript = jwt_decode(token);
        sendData.idCandidato = decript.sub;

        save(sendData, actions, ()=>{
            if(!this.isEdit()){
                this.props.onNext();
            }
        });
    }

    mysqlToDate = (date) => {
        if(!/-/.test(date)) return date;
        date = date.split(' ')[0].split('-');
        return date[2] + '/' + date[1] + '/' + date[0]
    }

    loadCandidato = () => {
        this.setState({
            loading: true
        })
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return;
        let decript = jwt_decode(token);
        let id = decript.sub;

        fetch(id, (data)=>{
            let obj = data.result;
            obj.formacao = [];
            let cursos = obj.cursos || [];
            
            let qtdCursos = this.state.cursos;
            let qtdFormacoes = this.state.formacoes;
            let model = this.state.model;
            model.nivelFormacao = obj.nivelFormacao;

            if(obj.escolaridades && obj.escolaridades.length){
                qtdFormacoes = obj.escolaridades.length;
                for(let i=0; i<obj.escolaridades.length; i++){
                    model.formacao[i] = {
                        idCandidatoEscolaridade: obj.escolaridades[i].idCandidatoEscolaridade,
                        atualmente: true,
                        idCurso: obj.escolaridades[i].idCurso,
                        nomeCurso: obj.escolaridades[i].nomeCurso,
                        dataInicial: this.mysqlToDate(obj.escolaridades[i].dataInicial.date),
                        dataFinal: this.mysqlToDate(obj.escolaridades[i].dataFinal ? obj.escolaridades[i].dataFinal.date : ''),
                        idInstituicao: obj.escolaridades[i].idInstituicao,
                        nomeInstituicao: obj.escolaridades[i].nomeInstituicao
                    };
                }
            }

            if(cursos && cursos.length){
                qtdCursos = cursos.length;
                for(let i=0; i<cursos.length; i++){
                    model.curso[i] = {
                        idCandidatoCurso: cursos[i].idCandidatoCurso,
                        cargaHoraria: cursos[i].cargaHoraria,
                        idCurso: cursos[i].idCurso,
                        nomeCurso: cursos[i].nomeCurso,
                        data: this.mysqlToDate(cursos[i].data.date),
                        idInstituicao: cursos[i].idInstituicao,
                        nomeInstituicao: cursos[i].nomeInstituicao
                    };
                }
            }

            this.setState({
                model,
                loading: false,
                cursos: qtdCursos,
                formacoes: qtdFormacoes
            });
        })
    }

    isEdit = () => {
        return /minha-conta\/edit/.test(window.location.href);
    }

	render() {
        if(this.state.loading && this.isEdit()){
            return <p className="py-5 text-center">Carregando...</p>
        }
        const boxFormacao = (FormikProps) => {
            let ret = [];
            for(let idx = 0; idx < this.state.formacoes; idx++){
                ret.push(<Row key={idx} className={"mb-3"}>
                    <Col xs="12" lg="6">
                        <FormSelect field={{
                            placeholder: "Digite para localizar...",
                            isSearchable: true,
                            name: `formacao[${idx}].idInstituicao`,
                            loadOptions: loadInstituicoesOptions,
                            getOptionLabel: (option) => `${option['nome']}`,
                            getOptionValue: (option) => {
                                return option.idInstituicao;
                            },
                            loadingMessage: ()=>"Carregando...",
                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : <div>Nenhuma instituição já criada com esse nome, digite o nome da instituição e pressione o botão abaixo. <br/>
                            <PrimaryButton type="button" color="success" onClick={()=>{
                                let nome = document.querySelector('input[name="formacao['+idx+'].idInstituicao"]').parentElement.querySelector('input').value.toUpperCase();
                                if(nome && nome !== ''){
                                    saveInstituicao({
                                        nome,
                                        status: -1,
                                        type: 'candidato'
                                    }, undefined, (res)=>{
                                        let idInstituicao = res.data.id;
                                        let nomeInstituicao = nome;
                                        let formacao = FormikProps.values.formacao;
                                        let obj = formacao[idx];

                                        obj.idInstituicao = idInstituicao;
                                        obj.nomeInstituicao = nomeInstituicao;

                                        formacao[idx] = obj;
                                        FormikProps.setFieldValue("formacao", formacao);

                                        if ("activeElement" in document)
                                            document.activeElement.blur();
                                    });
                                }
                            }}>Criar</PrimaryButton></div>,
                            value: FormikProps.values.formacao[idx].idInstituicao ? {
                                idInstituicao: FormikProps.values.formacao[idx].idInstituicao,
                                nome: FormikProps.values.formacao[idx].nomeInstituicao,
                            } : null,
                            onChange: (value) => {
                                let idInstituicao = value.idInstituicao;
                                let nomeInstituicao = value.nome;
                                let formacao = FormikProps.values.formacao;
                                let obj = formacao[idx];

                                obj.idInstituicao = idInstituicao;
                                obj.nomeInstituicao = nomeInstituicao;

                                formacao[idx] = obj;
                                FormikProps.setFieldValue("formacao", formacao);
                            }
                        }} form={FormikProps} async={true} label="Instituição"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormSelect field={{
                            placeholder: "Digite para localizar...",
                            isSearchable: true,
                            name: `formacao[${idx}].idCurso`,
                            loadOptions: loadCursosOptions,
                            getOptionLabel: (option) => `${option['nome']}`,
                            getOptionValue: (option) => {
                                return option.idCurso;
                            },
                            loadingMessage: ()=>"Carregando...",
                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : <div>Nenhum curso já criado com esse nome, digite o nome do curso e pressione o botão abaixo. <br/>
                            <PrimaryButton type="button" color="success" onClick={()=>{
                                let nome = document.querySelector('input[name="formacao['+idx+'].idCurso"]').parentElement.querySelector('input').value.toUpperCase();
                                if(nome && nome !== ''){
                                    saveCurso({
                                        nome,
                                        status: -1,
                                        type: 'candidato'
                                    }, undefined, (res)=>{
                                        let idCurso = res.data.id;
                                        let nomeCurso = nome;
                                        let formacao = FormikProps.values.formacao;
                                        let obj = formacao[idx];

                                        obj.idCurso = idCurso;
                                        obj.nomeCurso = nomeCurso;

                                        formacao[idx] = obj;

                                        FormikProps.setFieldValue("formacao", formacao);

                                        if ("activeElement" in document)
                                            document.activeElement.blur();
                                    });
                                }
                            }}>Criar</PrimaryButton></div>,
                            value: FormikProps.values.formacao[idx].idCurso ? {
                                idCurso: FormikProps.values.formacao[idx].idCurso,
                                nome: FormikProps.values.formacao[idx].nomeCurso,
                            } : null,
                            onChange: (value) => {
                                let idCurso = value.idCurso;
                                let nomeCurso = value.nome;
                                let formacao = FormikProps.values.formacao;
                                let obj = formacao[idx];

                                obj.idCurso = idCurso;
                                obj.nomeCurso = nomeCurso;

                                formacao[idx] = obj;

                                FormikProps.setFieldValue("formacao", formacao);
                            }
                        }} form={FormikProps} async={true} label="Curso"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormInput field={{
                            name: `formacao[${idx}].dataInicial`,
                            tag: DateInput,
                            onChange: (evt)=>{
                                let formacao = FormikProps.values.formacao;
                                let obj = formacao[idx];

                                obj.dataInicial = evt.target.value;
                                formacao[idx] = obj;

                                FormikProps.setFieldValue("formacao", formacao);
                            }
                        }} form={FormikProps} label="Período - Data Inicial"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormInput field={{
                            name: `formacao[${idx}].dataFinal`,
                            tag: DateInput,
                            placeholder: 'ATUALMENTE',
                            onChange: (evt)=>{
                                let formacao = FormikProps.values.formacao;
                                let obj = formacao[idx];

                                obj.dataFinal = evt.target.value;
                                formacao[idx] = obj;

                                FormikProps.setFieldValue("formacao", formacao);
                            }
                        }} form={FormikProps} label="Período - Data Final"/>
                    </Col>
                </Row>);
            }
            return ret;
        };

        const boxCurso = (FormikProps) => {
            let ret = [];
            for(let idx = 0; idx < this.state.cursos; idx++){
                ret.push(<Row key={idx} className={"mb-3"}>
                    <Col xs="12" lg="6">
                        <FormSelect field={{
                            placeholder: "Digite para localizar...",
                            isSearchable: true,
                            name: `curso[${idx}].idInstituicao`,
                            loadOptions: loadInstituicoesOptions,
                            getOptionLabel: (option) => `${option['nome']}`,
                            getOptionValue: (option) => {
                                return option.idInstituicao;
                            },
                            loadingMessage: ()=>"Carregando...",
                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : <div>Nenhuma instituição já criada com esse nome, digite o nome da instituição e pressione o botão abaixo. <br/>
                            <PrimaryButton type="button" color="success" onClick={()=>{
                                let nome = document.querySelector('input[name="curso['+idx+'].idInstituicao"]').parentElement.querySelector('input').value.toUpperCase();
                                if(nome && nome !== ''){
                                    saveInstituicao({
                                        nome,
                                        status: -1,
                                        type: 'candidato'
                                    }, undefined, (res)=>{
                                        let idInstituicao = res.data.id;
                                        let nomeInstituicao = nome;
                                        let curso = FormikProps.values.curso;
                                        let obj = curso[idx];

                                        obj.idInstituicao = idInstituicao;
                                        obj.nomeInstituicao = nomeInstituicao;

                                        curso[idx] = obj;
                                        FormikProps.setFieldValue("curso", curso);

                                        if ("activeElement" in document)
                                            document.activeElement.blur();
                                    });
                                }
                            }}>Criar</PrimaryButton></div>,
                            value: FormikProps.values.curso[idx].idInstituicao ? {
                                idInstituicao: FormikProps.values.curso[idx].idInstituicao,
                                nome: FormikProps.values.curso[idx].nomeInstituicao,
                            } : null,
                            onChange: (value) => {
                                let idInstituicao = value.idInstituicao;
                                let nomeInstituicao = value.nome;
                                let curso = FormikProps.values.curso;
                                let obj = curso[idx];

                                obj.idInstituicao = idInstituicao;
                                obj.nomeInstituicao = nomeInstituicao;

                                curso[idx] = obj;
                                FormikProps.setFieldValue("curso", curso);
                            }
                        }} form={FormikProps} async={true} label="Instituição"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormSelect field={{
                            placeholder: "Digite para localizar...",
                            isSearchable: true,
                            name: `curso[${idx}].idCurso`,
                            loadOptions: loadCursosOptions,
                            getOptionLabel: (option) => `${option['nome']}`,
                            getOptionValue: (option) => {
                                return option.idCurso;
                            },
                            loadingMessage: ()=>"Carregando...",
                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : <div>Nenhum curso já criado com esse nome, digite o nome do curso e pressione o botão abaixo. <br/>
                            <PrimaryButton type="button" color="success" onClick={()=>{
                                let nome = document.querySelector('input[name="curso['+idx+'].idCurso"]').parentElement.querySelector('input').value.toUpperCase();
                                if(nome && nome !== ''){
                                    saveCurso({
                                        nome,
                                        status: -1,
                                        type: 'candidato'
                                    }, undefined, (res)=>{
                                        let idCurso = res.data.id;
                                        let nomeCurso = nome;
                                        let curso = FormikProps.values.curso;
                                        let obj = curso[idx];

                                        obj.idCurso = idCurso;
                                        obj.nomeCurso = nomeCurso;

                                        curso[idx] = obj;

                                        FormikProps.setFieldValue("curso", curso);

                                        if ("activeElement" in document)
                                            document.activeElement.blur();
                                    });
                                }
                            }}>Criar</PrimaryButton></div>,
                            value: FormikProps.values.curso[idx].idCurso ? {
                                idCurso: FormikProps.values.curso[idx].idCurso,
                                nome: FormikProps.values.curso[idx].nomeCurso,
                            } : null,
                            onChange: (value) => {
                                let idCurso = value.idCurso;
                                let nomeCurso = value.nome;
                                let curso = FormikProps.values.curso;
                                let obj = curso[idx];

                                obj.idCurso = idCurso;
                                obj.nomeCurso = nomeCurso;

                                curso[idx] = obj;

                                FormikProps.setFieldValue("curso", curso);
                            }
                        }} form={FormikProps} async={true} label="Curso"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormInput field={{
                            name: `curso[${idx}].data`,
                            tag: DateInput,
                            onChange: (evt)=>{
                                let curso = FormikProps.values.curso;
                                let obj = curso[idx];

                                obj.data = evt.target.value;
                                curso[idx] = obj;

                                FormikProps.setFieldValue("curso", curso);
                            }
                        }} form={FormikProps} label="Data"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormInput field={{
                            name: `curso[${idx}].cargaHoraria`,
                            type: 'number',
                            onChange: (evt)=>{
                                let curso = FormikProps.values.curso;
                                let obj = curso[idx];

                                obj.cargaHoraria = evt.target.value;
                                curso[idx] = obj;

                                FormikProps.setFieldValue("curso", curso);
                            }
                        }} form={FormikProps} label="Carga Horária (Horas)"/>
                    </Col>
                </Row>);
            }
            return ret;
        };

		return (
            <Container>
                <Row className={`${this.isEdit() ? '' : 'py-5'} d-flex justify-content-center`}>    
                    {this.isEdit() && 
                        <Col xs="12">
                            <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Edição Currículo | Escolaridade</h4>
                        </Col>
                    }
                    <Col xs="12" md={this.isEdit() ? "12" : "10"} lg={this.isEdit() ? "12" : "9"}>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this.state.model}
                            onSubmit={this.handleSubmit}
                            enableReinitialize={true}
                            render={(FormikProps) => (
                                <Form onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12">
                                            <FormGroup>
                                                <InputLabel>Nível de Formação *</InputLabel>
                                                <div>
                                                    <Checkbox onChange={()=>{FormikProps.setFieldValue('nivelFormacao', 'FI')}} checked={FormikProps.values.nivelFormacao === 'FI'} inline type="radio" id="nivelFormacaoFI" name="nivelFormacao" label="Fundamental Incompleto" />
                                                    <Checkbox onChange={()=>{FormikProps.setFieldValue('nivelFormacao', 'FC')}} checked={FormikProps.values.nivelFormacao === 'FC'} inline type="radio" id="nivelFormacaoFC" name="nivelFormacao" label="Fundamental Completo" />
                                                    <Checkbox onChange={()=>{FormikProps.setFieldValue('nivelFormacao', 'MI')}} checked={FormikProps.values.nivelFormacao === 'MI'} inline type="radio" id="nivelFormacaoMI" name="nivelFormacao" label="Ensino Médio Incompleto" />
                                                    <Checkbox onChange={()=>{FormikProps.setFieldValue('nivelFormacao', 'MC')}} checked={FormikProps.values.nivelFormacao === 'MC'} inline type="radio" id="nivelFormacaoMC" name="nivelFormacao" label="Ensino Médio Completo" />
                                                    <Checkbox onChange={()=>{FormikProps.setFieldValue('nivelFormacao', 'SI')}} checked={FormikProps.values.nivelFormacao === 'SI'} inline type="radio" id="nivelFormacaoSI" name="nivelFormacao" label="Superior Incompleto" />
                                                    <Checkbox onChange={()=>{FormikProps.setFieldValue('nivelFormacao', 'SC')}} checked={FormikProps.values.nivelFormacao === 'SC'} inline type="radio" id="nivelFormacaoSC" name="nivelFormacao" label="Superior Completo" />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                            <h6 style={{color: PRIMARY_COLOR}}>FORMAÇÃO - {this.state.maxFormacoes} ÚLTIMAS</h6>
                                        </Col>
                                        <Col xs="12">
                                            {boxFormacao(FormikProps)}

                                            <Row>
                                            {this.state.formacoes < this.state.maxFormacoes &&
                                                <Col xs="12" sm="6" md="auto" className="d-flex align-items-end">
                                                    <PrimaryButton block size="sm" type="button" style={{color:'white', marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light" onClick={()=>{this.setState({formacoes: this.state.formacoes + 1})}}>ADICIONAR +</PrimaryButton>
                                                </Col>
                                            }
                                            {this.state.formacoes > 1 &&
                                                <Col xs="12" sm="6" md="auto" className="d-flex align-items-end">
                                                    <SecondaryButton block size="sm" type="button" style={{marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light" onClick={this.removeFormacao}>REMOVER</SecondaryButton>
                                                </Col>
                                            }
                                            </Row>
                                        </Col>
                                        <Col xs="12" className="mt-3">
                                            <h6 style={{color: PRIMARY_COLOR}}>CURSOS - {this.state.maxCursos} ÚLTIMOS</h6>
                                        </Col>
                                        <Col xs="12">
                                            {boxCurso(FormikProps)}

                                            <Row>
                                            {this.state.cursos < this.state.maxCursos &&
                                                <Col xs="12" sm="6" md="auto" className="d-flex align-items-end">
                                                    <PrimaryButton block size="sm" type="button" style={{color:'white', marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light" onClick={()=>{this.setState({cursos: this.state.cursos + 1})}}>ADICIONAR +</PrimaryButton>
                                                </Col>
                                            }
                                            {this.state.cursos > 1 &&
                                                <Col xs="12" sm="6" md="auto" className="d-flex align-items-end">
                                                    <SecondaryButton block size="sm" type="button" style={{marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light" onClick={this.removeCurso}>REMOVER</SecondaryButton>
                                                </Col>
                                            }
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs="12">
                                            {this.isEdit() && 
                                                <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Salvar <FontAwesomeIcon icon='check' color={PRIMARY_COLOR}/></SecondaryButton>
                                            }
                                            {!this.isEdit() && 
                                                <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Próximo <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default FormEscolaridade;
