    import styled from 'styled-components'

import { SECONDARY_COLOR, PRIMARY_COLOR } from 'helpers/consts';
import { Container, Col, Input } from 'reactstrap';

export const ContainerFluidRight = styled(Container)`
    max-width:none;
    @media (min-width:1200px) {
        width: calc(((100% - 1170px) / 2) + 1170px) !important;
        margin-right: 0;
        max-width:none;
        padding-right:0px
        padding-left:15px
    }
`
export const PrimaryP = styled.p`
    color: ${PRIMARY_COLOR}
`
export const SecondaryP = styled.p`
    color: ${SECONDARY_COLOR}
`
export const BackgroundPrimaryContainer = styled(Container)`
    background-color: ${PRIMARY_COLOR}
`
export const BackgroundSecondaryCol = styled(Col)`
    background-color: ${SECONDARY_COLOR}
`
export const NewsLetterCol = styled(Col)`
    @media (min-width:1200px) {
        max-width: 480px;
    }
`
export const CustomInput = styled(Input)`
    background: none
    border: none
    border-bottom: 1px solid #FFF
    border-radius: 0px
    padding: 0
    color:#FFF
    &::placeholder{
        color:#FFF
    }
    &:focus{
        color:#FFF!important
        background: none
        border: none
        border-bottom: 1px solid #FFF
        box-shadow:none
    }
`