import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Consts
import { PRIMARY_COLOR } from 'helpers/consts';

// Ações / Métodos
import { loginEmpresa } from 'actions/auth';

// Componentes
import FormInput from "components/FormInput";
import { SecondaryButton } from 'components/Buttons';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Validação
const validationSchema = Yup.object().shape({
    senha: Yup.string().required('Por favor digite a senha'),
    email: Yup.string().required('Por favor digite um e-mail').email('Por favor digite um e-mail válido'),
});

const MySwal = withReactContent(Swal);

class LoginEmpresa extends React.Component {
    constructor() {
        super();
         
        this._initialValues = {
            email: '',
            senha: ''
        };     
    }

    handleSubmit = (values, actions) => {
        let email = values.email;
        let password = values.senha;
        
        loginEmpresa({
            email,
            password
        }, (err)=>{
            actions.setSubmitting(false);

            MySwal.fire({
                type: 'error',
                title: err.message,
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
            });
        });
    }

	render() {
		return (
            <Container>
                <Row className="d-flex justify-content-center">    
                    <Col xs="12" lg="10">
                        <p className="h4 pt-4 pb-3 font-weight-light" style={{color:PRIMARY_COLOR}}>FAÇA LOGIN</p>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this._initialValues}
                            onSubmit={this.handleSubmit}
                            render={(FormikProps) => (
                                <Form onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12">
                                            <FormInput field={{
                                                name: 'email',
                                                type: 'email',
                                                autoComplete: 'username'
                                            }} form={FormikProps} label="E-mail"/>
                                        </Col>
                                        <Col xs="12">
                                            <FormInput field={{
                                                name: 'senha',
                                                type: 'password',
                                                autoComplete: 'new-password'
                                            }} form={FormikProps} label="Senha"/>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3 mb-4">
                                        <Col xs="12" className="d-flex justify-content-center">
                                            <SecondaryButton disabled={FormikProps.isSubmitting} type="submit">ACESSAR <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="px-0 py-3 text-center" style={{color: PRIMARY_COLOR, borderTop: '1px solid #cccccc'}}>
                        Não possui uma conta? <Link style={{color: PRIMARY_COLOR, textDecoration: 'underline'}} className="font-weight-bold" to={`${process.env.PUBLIC_URL}/empresa/cadastro`}>Cadastre-se</Link>
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default LoginEmpresa;