import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { BackGround, H1 } from './styles';

import bkg from 'assets/img/bkgCapacitacoes.jpg';

class BannerCapacitacoes extends React.Component {
    render() {
        return (
            <BackGround source={bkg}>
                <Container fluid={true}>
                    <Container>
                        <Row className="py-5 align-items-center">
                            <Col xs="12" md="auto" className="my-4">
                                <H1 className="font-weight-bold m-0">Capacitações</H1>
                            </Col>
                            <Col xs="12" md="auto" className="my-4 text-white font-weight-light">
                                Confira e inscreva-se<br/>nos eventos
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (BannerCapacitacoes);
