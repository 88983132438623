import styled from 'styled-components';
import { Label } from 'reactstrap';

import Editor from 'components/Editor';

export const InputLabel = styled(Label)`
    font-size: 14px;
    margin-bottom: 0;
`

export const CustomEditor = styled(Editor)``