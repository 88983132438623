import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'reactstrap';
import { withRouter, NavLink as Link } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Consts
import { PRIMARY_COLOR, JWT_TOKEN, SECONDARY_COLOR } from 'helpers/consts';

import { loadOptions as loadSetoresOptions } from 'actions/setores';
import { loadOptions as loadCidadesOptions } from 'actions/cidades';
import { loadOptions as loadCargosOptions, save as saveCargo } from 'actions/cargos';
import { loadOptions as loadCategoriasOptions } from 'actions/categorias';
import { loadOptions as loadAreasOptions } from 'actions/areas';
import { loadOptions as loadCursosOptions } from 'actions/cursos';
import { save, fetch, getCandidatos, changeStatus } from 'actions/vagas';

// Componentes
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import { SecondaryButton, PrimaryButton } from 'components/Buttons';
import Telefone from 'components/FormInput/Telefone';
import Checkbox from 'components/Checkbox';
import FormEditor from 'components/FormEditor';
import Curriculo from 'components/SuaContaEmpresa/Content/Curriculo';
import DateInput from 'components/FormInput/Date';

// Validação
import validationSchema from './validation';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MySwal = withReactContent(Swal);

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.insert = function (index, string) {
    var ind = index < 0 ? this.length + index  :  index;
    return  this.substring(0, ind) + string + this.substring(ind, this.length);
};
class FormVaga extends React.Component {
    constructor() {
        super();

        this._isMounted = false;
        this._initialState = {
            necEspecial: 0,
            exigeFoto: 0,
            preenchimento: null,
            resumo: '',
            descricao: '',
            responsavel: '',
            email: '',
            idSetor: null,
            descricaoSetor: '',
            idCategoria: null,
            descricaoCategoria: '',
            idArea: null,
            descricaoArea: '',
            idCargo: null,
            nomeCargo: '',
            idEstado: null,
            nomeEstado: '',
            uf: '',
            idCidade: null,
            nomeCidade: '',
            telefone: '',
            status: 0,
            cursos: [],
            experiencias: [],
            dataExpiracao: null,
        };

        this.state = {
            loading: true,
            activeTab: 'detalhes',
            curriculos: [],
            model: Object.assign({}, this._initialState)
        }

        this.formik = React.createRef();
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    fetchData = (id) => {
        fetch(atob(id), (data)=>{
            if(data.result){
                let model = data.result;
                if(this._isMounted){
                    let token = localStorage.getItem(JWT_TOKEN);
                    if(!token) {
                        this.setState({
                            loading: false
                        });
                        return false;
                    }

                    let decoded = jwt_decode(token);
                    if(parseInt(decoded.sub) !== parseInt(model.idCliente)){
                        this.setState({
                            loading: false
                        });
                    } else {
                        model.resumo = model.resumo.replace(/<br\s{0,1}\/>/g, '')
                        this.setState({
                            model,
                            loading: false
                        });
                    }
                }
            }
        })
    }  

    dateToDisplay = (dt = '') => {
        if(!dt) return '';
        let date = dt.split(' ')[0].split('-');
        return `${date[2]}/${date[1]}/${date[0]}`;
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        if(id){
            this.fetchData(id);
            this.loadCurriculos();
        } else {
            this.setState({
                loading: false,
                model: Object.assign({}, this._initialState)
            })
        }
        this._isMounted = true
    }

    componentDidUpdate(nextProps){
        if(this.props.match.params.id !== nextProps.match.params.id){
            this.setState({
                loading: false,
                model: Object.assign({}, this._initialState)
            });
            if(this.formik.current) {
                this.formik.current.resetForm(this._initialState);
                this.formik.current.setFieldValue('descricao', '');
            }
        }
    }

    loadCurriculos = () => {
        if(this.props.match.params.id){
            var promise = new Promise((res, rej) => {
                getCandidatos(atob(this.props.match.params.id), res, rej);
            });
            
            promise.then((data) => {
                let curriculos = data.result;
                this.setState({
                    curriculos
                });
            });
        }
    }

    handleChangeStatus = () => {
        MySwal.fire({
            title: 'Vaga preenchida',
            type: 'question',
            input: 'select',
            inputOptions: {
                B: 'Rio Negrinho Empregos',
                E: 'E-mail',
                T: 'Telefone',
                P: 'Presencialmente',
                O: 'Outros'
            },
            html: 'De que forma essa vaga foi preenchida?',
            showCancelButton: true,
            confirmButtonText: <PrimaryButton tag="span" size="lg" title="Confirmar">Confirmar</PrimaryButton>,
            cancelButtonText: <SecondaryButton tag="span" size="lg" color="white" title="Cancelar">Cancelar</SecondaryButton>,
            reverseButtons: true
        }).then((result) => {
            console.log(result);
            if(result.value){
                let id = atob(this.props.match.params.id);
                changeStatus(id, 2, ()=>{
                    this.props.history.push(`${process.env.PUBLIC_URL}/empresa/vagas`);
                }, ()=>{}, {preenchimento: result.value});
            }
        });
    }

    handleSubmit = (values, actions) => {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return false;

        let decoded = jwt_decode(token);

        let sendData = Object.assign({}, values);

        sendData.setor = sendData.idSetor;
        sendData.categoria = sendData.idCategoria;
        sendData.cargo = sendData.idCargo;
        sendData.area = sendData.idArea;
        sendData.cidade = sendData.idCidade;
        sendData.cliente = decoded.sub;
        sendData.telefone = sendData.telefone.toString().replace('-', '').insert(-4, '-');
        let tmpArr = [];
        if(sendData.cursos){
            for(let i=0; i < sendData.cursos.length; i++){
                tmpArr.push(sendData.cursos[i].idCurso);
            }
        }
        sendData.cursos = tmpArr;

        let tmpArr2 = [];
        if(sendData.experiencias){
            for(let i=0; i < sendData.experiencias.length; i++){
                tmpArr2.push(sendData.experiencias[i].idCargo);
            }
        }
        sendData.experiencias = tmpArr2;
        save(sendData, actions, ()=>{
            this.props.history.push(`${process.env.PUBLIC_URL}/empresa/vagas`);
        });
    }

	render() {
        if(this.state.loading){
            return (
                <Container>
                    <p className="text-center">Carregando...</p>
                </Container>
            );
        }
        if(this.props.match.params.action && this.props.match.params.action === 'curriculos'){
            return <Curriculo
                id={this.props.match.params.id}
                idx={this.props.match.params.idx}
                vaga={this.state.model}
                curriculos={this.state.curriculos ? this.state.curriculos : null}/>
        }
		return (
            <Container>
                <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">{this.props.match.params.id ? 'Editar Vaga' : 'Nova Vaga'}</h4>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === 'detalhes' ? 'active' : ''}
                            onClick={() => { this.toggle('detalhes'); }}
                        >
                            Detalhes
                        </NavLink>
                    </NavItem>
                    {this.state.curriculos && this.state.curriculos.length > 0 &&
                    <NavItem>
                        <NavLink
                            className={this.state.activeTab === 'curriculos' ? 'active' : ''}
                            onClick={() => { this.toggle('curriculos'); }}
                            >
                            Currículos
                        </NavLink>
                    </NavItem>
                    }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="detalhes">

                    
                        <Row className="pb-5 pt-2 d-flex justify-content-center">    
                            <Col xs="12">
                                <Formik
                                    ref={this.formik}
                                    validationSchema={validationSchema}
                                    initialValues={this.state.model}
                                    onSubmit={this.handleSubmit}
                                    render={(FormikProps) => (
                                        <Form onSubmit={FormikProps.handleSubmit}>
                                            <Row>
                                                <Col xs="12" lg="12">
                                                    <FormSelect field={{
                                                        placeholder: "Digite para localizar...",
                                                        isSearchable: true,
                                                        name: 'idCargo',
                                                        loadOptions: loadCargosOptions,
                                                        getOptionLabel: (option) => `${option['nome']}`,
                                                        getOptionValue: (option) => option.idCargo,
                                                        loadingMessage: ()=>"Carregando...",
                                                        noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : <div>Nenhum cargo já criado com esse nome, digite o nome do cargo e pressione o botão abaixo. <br/>
                                                        <PrimaryButton type="button" color="success" onClick={()=>{
                                                            let nome = document.querySelector('input[name="idCargo"]').parentElement.querySelector('input').value.toUpperCase();
                                                            if(nome && nome !== ''){
                                                                saveCargo({
                                                                    nome,
                                                                    status: -1,
                                                                    type: 'empresa'
                                                                }, undefined, (res)=>{
                                                                    FormikProps.setFieldValue("idCargo", res.data.id);
                                                                    FormikProps.setFieldValue("nomeCargo", nome);
                            
                                                                    if ("activeElement" in document)
                                                                        document.activeElement.blur();
                                                                });
                                                            }
                                                        }}>Criar</PrimaryButton></div>,
                                                        value: FormikProps.values.idCargo ? {
                                                            idCargo: FormikProps.values.idCargo,
                                                            nome: FormikProps.values.nomeCargo,
                                                        } : null,
                                                        onChange: (value) => {
                                                            FormikProps.setFieldValue("idCargo", value.idCargo);
                                                            FormikProps.setFieldValue("nomeCargo", value.nome);
                                                        }
                                                    }} form={FormikProps} async={true} label="Cargo *"/>
                                                </Col>
                                                <Col xs="12">
                                                    <FormInput field={{
                                                        name: 'resumo',
                                                        tag: 'textarea',
                                                        rows: 2
                                                    }} form={FormikProps} label="Resumo"/>
                                                </Col>
                                                <Col xs="12">
                                                    <FormEditor field={{
                                                        name: 'descricao'
                                                    }} form={FormikProps} label="Descrição"/>
                                                </Col>

                                                <Col xs="12" lg="6">
                                                    <FormSelect field={{
                                                        isSearchable: false,
                                                        loadOptions: loadSetoresOptions,
                                                        defaultOptions: true,
                                                        name: 'idSetor',
                                                        getOptionLabel: (option) => option.setor,
                                                        getOptionValue: (option) => option.idSetor,
                                                        loadingMessage: ()=>"Carregando...",
                                                        noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                        value: FormikProps.values.idSetor ? {
                                                            idSetor: FormikProps.values.idSetor,
                                                            setor: FormikProps.values.descricaoSetor
                                                        } : null,
                                                        onChange: (value) => {
                                                            FormikProps.setFieldValue("idSetor", value.idSetor);
                                                            FormikProps.setFieldValue("descricaoSetor", value.setor);
                                                        }
                                                    }} form={FormikProps} async={true} label="Setor"/>
                                                </Col>
                                                <Col xs="12" lg="6">
                                                    <FormSelect field={{
                                                        isSearchable: false,
                                                        loadOptions: loadCategoriasOptions,
                                                        defaultOptions: true,
                                                        name: 'idCategoria',
                                                        getOptionLabel: (option) => option.descricao,
                                                        getOptionValue: (option) => option.idCategoria,
                                                        loadingMessage: ()=>"Carregando...",
                                                        noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                        value: FormikProps.values.idCategoria ? {
                                                            idCategoria: FormikProps.values.idCategoria,
                                                            descricao: FormikProps.values.descricaoCategoria
                                                        } : null,
                                                        onChange: (value) => {
                                                            FormikProps.setFieldValue("idCategoria", value.idCategoria);
                                                            FormikProps.setFieldValue("descricaoCategoria", value.descricao);
                                                        }
                                                    }} form={FormikProps} async={true} label="Categoria *"/>
                                                </Col>
                                                <Col xs="12" lg="6">
                                                    <FormSelect field={{
                                                        isSearchable: false,
                                                        loadOptions: loadAreasOptions,
                                                        defaultOptions: true,
                                                        name: 'idArea',
                                                        getOptionLabel: (option) => option.descricao,
                                                        getOptionValue: (option) => option.idArea,
                                                        loadingMessage: ()=>"Carregando...",
                                                        noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                        value: FormikProps.values.idArea ? {
                                                            idArea: FormikProps.values.idArea,
                                                            descricao: FormikProps.values.descricaoArea
                                                        } : null,
                                                        onChange: (value) => {
                                                            FormikProps.setFieldValue("idArea", value.idArea);
                                                            FormikProps.setFieldValue("descricaoArea", value.descricao);
                                                        }
                                                    }} form={FormikProps} async={true} label="Área"/>
                                                </Col>
                                                <Col xs="12" lg="6">
                                                    <FormInput field={{
                                                        name: 'email',
                                                        type: 'email',
                                                        autoComplete: 'username'
                                                    }} form={FormikProps} label="E-mail"/>
                                                </Col>
                                                <Col xs="12" lg="6">
                                                    <FormInput field={{
                                                        name: 'responsavel'
                                                    }} form={FormikProps} label="Responsável"/>
                                                </Col>
                                                <Col xs="12" lg="6">
                                                    <FormSelect field={{
                                                        placeholder: "Digite para localizar...",
                                                        isSearchable: true,
                                                        name: 'idCidade',
                                                        loadOptions: loadCidadesOptions,
                                                        getOptionLabel: (option) => `${option['nome']}${option['estado'] ? ' - ' + option['estado']['uf'] : ''}`,
                                                        getOptionValue: (option) => {
                                                            return option.idCidade;
                                                        },
                                                        loadingMessage: ()=>"Carregando...",
                                                        noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                        value: FormikProps.values.idCidade ? {
                                                            idCidade: FormikProps.values.idCidade,
                                                            nome: FormikProps.values.nomeCidade,
                                                            estado: {
                                                                idEstado: FormikProps.values.idEstado,
                                                                nome: FormikProps.values.nomeEstado,
                                                                uf: FormikProps.values.uf,
                                                            }
                                                        } : null,
                                                        onChange: (value) => {
                                                            FormikProps.setFieldValue("idCidade", value.idCidade);
                                                            FormikProps.setFieldValue("nomeCidade", value.nome);
                                                            FormikProps.setFieldValue("idEstado", value.estado.idEstado);
                                                            FormikProps.setFieldValue("nomeEstado", value.estado.nome);
                                                            FormikProps.setFieldValue("uf", value.estado.uf);
                                                        }
                                                    }} form={FormikProps} async={true} label="Cidade"/>
                                                </Col>

                                                <Col xs="12" lg="6">
                                                    <FormInput field={{
                                                        name: 'telefone',
                                                        tag: Telefone
                                                    }} form={FormikProps} label="Telefone *"/>
                                                </Col>
                                                <Col xs="12" lg="6">
                                                    <FormInput 
                                                        field={{
                                                            name: 'dataExpiracao',
                                                            tag: DateInput,
                                                            }}
                                                        onChange={(e) => {
                                                        FormikProps.setFieldValue('dataExpiracao', e.current.value);
                                                    }} form={FormikProps} label="Data de Expiração *" />
                                                </Col>
                                                <Col xs="12"></Col>
                                                <Col xs="12" lg="8" className="mb-3 d-flex align-items-center">
                                                    <Checkbox onChange={(evt)=>{
                                                        FormikProps.setFieldValue('necEspecial', evt.target.checked ? 1 : 0)
                                                    }} checked={FormikProps.values.necEspecial === 1} inline type="checkbox" id="necEspecial" name="necEspecial" label={<><FontAwesomeIcon icon='wheelchair' color={PRIMARY_COLOR}/> Vaga para portador(a) de necessidades especiais</>} />
                                                </Col>
                                                <Col xs="12" lg="4" className="mb-3 d-flex align-items-center">
                                                    <Checkbox onChange={(evt)=>{
                                                        FormikProps.setFieldValue('exigeFoto', evt.target.checked ? 1 : 0)
                                                    }} checked={FormikProps.values.exigeFoto === 1} inline type="checkbox" id="exigeFoto" name="exigeFoto" label={<><FontAwesomeIcon icon='portrait' color={PRIMARY_COLOR}/> Exigir foto</>} />
                                                </Col>
                                                <Col xs="12"><hr/></Col>
                                                <Col xs="12" lg="12">
                                                    <FormSelect field={{
                                                        isSearchable: true,
                                                        loadOptions: loadCursosOptions,
                                                        defaultOptions: false,
                                                        isClearable: true,
                                                        name: 'idCurso',
                                                        isMulti: true,
                                                        getOptionLabel: (option) => option.nome,
                                                        getOptionValue: (option) => option.idCurso,
                                                        loadingMessage: ()=>"Carregando...",
                                                        noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                        value: FormikProps.values.cursos,
                                                        onChange: (value) => {
                                                            FormikProps.setFieldValue('cursos', value);
                                                        }
                                                    }} form={FormikProps} async={true} label="Cursos (Requeridos para a vaga)"/>
                                                </Col>
                                                <Col xs="12"><hr/></Col>
                                                <Col xs="12" lg="12">
                                                    <FormSelect field={{
                                                        isSearchable: true,
                                                        loadOptions: loadCargosOptions,
                                                        defaultOptions: false,
                                                        isClearable: true,
                                                        name: 'experiencias',
                                                        isMulti: true,
                                                        getOptionLabel: (option) => option.nome,
                                                        getOptionValue: (option) => option.idCargo,
                                                        loadingMessage: ()=>"Carregando...",
                                                        noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                        value: FormikProps.values.experiencias,
                                                        onChange: (value) => {
                                                            FormikProps.setFieldValue('experiencias', value);
                                                        }
                                                    }} form={FormikProps} async={true} label="Cargos (Experiências anteriores)"/>
                                                </Col>
                                            </Row>

                                            <Row className="mt-3">
                                                <Col xs="12">
                                                    {this.state.model.status === 2 && 
                                                        <>
                                                        <b>Preenchimento: </b> 
                                                        {this.state.model.preenchimento === 'B' && 'Rio Negrinho Empregos'}
                                                        {this.state.model.preenchimento === 'E' && 'E-mail'}
                                                        {this.state.model.preenchimento === 'T' && 'Telefone'}
                                                        {this.state.model.preenchimento === 'P' && 'Presencialmente'}
                                                        {this.state.model.preenchimento === 'O' && 'Outros'}
                                                        </>
                                                    }
                                                    {this.state.model.status !== 2 &&
                                                    <>
                                                        {this.state.model.dataExpiracao &&
                                                        <Row>
                                                            <Col>
                                                                <b>Válida até: </b>{this.state.model.dataExpiracao}
                                                            </Col>
                                                        </Row>
                                                        }
                                                        <div className="d-flex justify-content-center">
                                                            <SecondaryButton disabled={FormikProps.isSubmitting} type="submit">Salvar{this.props.match.params.id && <span>&nbsp;/ Prorrogar</span>} <FontAwesomeIcon icon='check' color={PRIMARY_COLOR}/></SecondaryButton>
                                                        </div>
                                                    </>
                                                    }
                                                    {this.state.model.status === 1 &&
                                                        <PrimaryButton onClick={this.handleChangeStatus} type="button" className="float-right">A vaga foi preenchida <FontAwesomeIcon icon='clipboard-check' color={SECONDARY_COLOR}/></PrimaryButton>
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    )}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="curriculos">
                        <Row>
                            <Col xs="12 mt-2 mx-0 p-2">
                                <h5 style={{color: PRIMARY_COLOR}} className="font-weight-light">{this.state.curriculos ? this.state.curriculos.length : 0} Currículo(s)</h5>
                                <Row className="mx-0">
                                    {this.state.curriculos ? this.state.curriculos.map((val, idx)=>{
                                        return <Link to={`${process.env.PUBLIC_URL}/empresa/vagas/edit/${this.props.match.params.id}/curriculos/${idx+1}`} key={idx} className="col-12 py-2 px-0 font-weight-bold" style={{cursor: 'pointer', color: PRIMARY_COLOR, borderBottom: '1px solid #cccccc'}}>
                                            {val.nome}
                                        </Link>
                                    }) : null}
                                </Row>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </Container>
		);
	}
}

export default withRouter(FormVaga);
