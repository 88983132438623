import styled from 'styled-components';
import Slider from "react-slick";
import { PRIMARY_COLOR } from 'helpers/consts';

export const CustomSlider = styled(Slider)`
    & ul{
        bottom: -40px
    }
    & ul>li, ul>li>button{
        width: 10px
        height: 10px
    }
    & ul>li>button{
        border-radius: 50%
        background: #cbcbca
    }
    & ul>li.slick-active button {
        background: ${PRIMARY_COLOR}
    }
    & ul>li>button::before,& ul>li.slick-active button::before {
        color:transparent
    }
`
