export const JWT_TOKEN = "722b96e235e45f5991fc735e267d1f90";

export const NAME_SITE = "Rio Negrinho Empregos";
export const LINK_FACE = "https://www.facebook.com/acirne.associacaoempresarial";
export const LINK_FACE_NUCLEO_JOVENS = "https://www.facebook.com/NJERioNegrinho/";
export const LINK_FACE_NUCLEO_RH = "https://www.facebook.com/nucleodegestoresemrecursoshumanos/";
export const LINK_INSTA = "https://www.instagram.com/acirne_rionegrinho/";
export const LINK_LINKEDIN = "https://www.linkedin.com/in/acirne-assoc-empresarial-364b94111/";
export const LINK_MAPS = "https://www.google.com/maps/place/Associa%C3%A7%C3%A3o+Empresarial+de+Rio+Negrinho/@-26.254724,-49.5196047,17z/data=!3m1!4b1!4m5!3m4!1s0x94ddd09c3acd57e7:0x98c71c36021c1998!8m2!3d-26.254724!4d-49.517416";
export const EMAIL_CONTATO = 'acirne@acirne.org.br';

export const DESCRIPTION_SITE = "Rio Negrinho Empregos";
export const KEYWORDS_SITE = "Empregos em rio negrinho, trabalho em rio negrinho, vagas de emprego rio negrinho, oportunidade de emprego rio negrinho,Empregos em sao bento do sul, trabalho em sao bento do sul, vagas de emprego sao bento do sul, oportunidade de emprego sao bento do sul,Empregos em mafra, trabalho em mafra, vagas de emprego mafra, oportunidade de emprego mafra";

export const SECONDARY_COLOR = "#f0e626";
export const PRIMARY_COLOR = "#222c57";
export const THIRD_COLOR = "#6dc7da";
export const INFO_COLOR = "#b6e3ec";