import React from "react";
import { withRouter } from 'react-router-dom';
// Constantes
import { JWT_TOKEN } from 'helpers/consts';

// Componentes
import Form from './form';

class FormVaga extends React.Component {

	render() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token)
            return null;
        
        return <Form/>;
	}
}

export default withRouter(FormVaga);
