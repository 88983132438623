import React from "react";

import { Container, Row, Col } from 'reactstrap';
import { BackGround, H1 } from './styles';
import Form from './form';
import bkg from 'assets/img/bkgContato.jpg';
import Patrocinadores from "components/Patrocinadores";
import Cabecalho from "components/Cabecalhos";

//Analytics
import ReactGA from 'react-ga';

class Contato extends React.Component {
	
	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}

	render() {
		return (
			<div>
				<Cabecalho title='Contato' description='description contato' keywords='keys contato'/>
				<BackGround source={bkg}>
					<Container fluid={true}>
						<Container>
							<Row className="pt-5 align-items-center justify-content-center">
								<Col xs="12" md="auto" className="my-4">
									<H1 className="font-weight-bold">CONTATO</H1>
								</Col>
								<Col xs="12" md="auto" className="my-4 text-white font-weight-light">
									Preencha o formulário e envie sua<br/>mensagem com suas dúvidas ou sugestões
								</Col>
							</Row>
						</Container>
					</Container>
					<Form/>
				</BackGround>
				<Patrocinadores/>
			</div>
		);
	}
}

export default Contato;
