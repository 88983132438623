import * as Yup from 'yup';
import { addInYup as addCnpjCpf } from "helpers/validation/CnpjCpf"; 
import { addInYup as addPhone } from "helpers/validation/Phone"; 
import { addInYup as addCep } from "helpers/validation/Cep"; 

// Adiciona funções customizadas no YUP (Não existentes por padrão)
addCnpjCpf();
addPhone();
addCep();

const validationSchema = Yup.object().shape({
    nome: Yup.string().required('Por favor informe o nome da Empresa'),
    tipoPessoa: Yup.string().oneOf(['F', 'J'], 'Por favor selecione o tipo'),
    cnpjCpf: Yup.string().required('Por favor digite o CNPJ/CPF').cnpjCpf('Por favor digite um CNPJ/CPF válido'),
    senha: Yup.string().notRequired().nullable(),
    confirmaSenha: Yup.string().notRequired('Por favor digite a confirmação de senha').oneOf([Yup.ref('senha')], 'As senhas digitadas não correspondem'),
    responsavel: Yup.string().required('Por favor informe um responsável'),
    telefone: Yup.string().required('Por favor digite um telefone válido').phone('Por favor digite um telefone válido'),
    telefone2: Yup.string().notRequired().phone('Por favor digite um telefone válido').nullable(),
    telefone3: Yup.string().notRequired().phone('Por favor digite um telefone válido').nullable(),
    cep: Yup.string().notRequired().cep('Por favor digite um CEP válido').nullable(),
    email: Yup.string().required('Por favor digite um e-mail').email('Por favor digite um e-mail válido'),
});

export default validationSchema;