import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MySwal = withReactContent(Swal);

class VagasBreadCrumb extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            label: 'VAGAS',
            value: null            
        };
    }

    loadData = () =>{
        let model;
        let id;
        if(this.props.match.params.setor){
            model = 'setores';
            id = this.props.match.params.setor;
        }
        if(this.props.match.params.categoria){
            model = 'categorias';
            id = this.props.match.params.categoria;
        }
        axios.get(`${model}/${btoa(id)}`)
        .then(res => {
            let data = res.data.result;
            let label = null;
            if(this.props.match.params.setor){
                label = 'SETORES';
            }else if(this.props.match.params.categoria){
                label = 'CATEGORIA';
            }
            this.setState({
                label,
                value:data.descricao
            });
        })
        .catch(err => {
            if(err.response){
                MySwal.fire({
                    type: 'error',
                    title: "Não foi possível obter os dados. " + err.response.data.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
            }
        });
    }

    componentDidMount() {
        if(this.props.match.params.setor || this.props.match.params.categoria)
            this.loadData();
        else if(this.props.match.params.pesquisa){
            this.setState({
                label: 'PROCURAR',
                value:this.props.match.params.pesquisa
            });
        }    
        else if(this.props.match.params.vaga){
            this.setState({
                label: 'VAGA',
                value: this.props.nomeCargo
            });
        }    
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.setor !== this.props.match.params.setor || prevProps.match.params.categoria !== this.props.match.params.categoria){
            this.setState({
                label: 'VAGAS',
                value: null            
            })
            this.loadData();
        }
        if(prevProps.match.params.pesquisa !== this.props.match.params.pesquisa){
            this.setState({
                label: 'VAGAS',
                value: this.props.match.params.pesquisa            
            })
        }
    }
    
	render() {
        return (
            <>
                <span className="mx-3 d-inline-block">Você está em:</span>
                <p className="h5 m-0 d-inline-block">{this.state.label}</p>
                {this.state.value &&
                    <p className="h5 m-0 d-inline-block text-uppercase">
                        <FontAwesomeIcon icon='arrow-right'className="mx-3"/>
                        {this.state.value }
                        <Link to={`${process.env.PUBLIC_URL}/vagas`} title="Limpar filtros"><FontAwesomeIcon icon='times' className="mx-3"/></Link>
                    </p>
                }              
            </>
        );
    }
};

export default withRouter(VagasBreadCrumb);