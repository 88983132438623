import React from 'react';

import { Container, Row, Col } from 'reactstrap';

//Analytics
import ReactGA from 'react-ga';

class TermosCondicoes extends React.Component {

    componentDidMount() {
        ReactGA.pageview(this.constructor.name);
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <Container fluid={true} className="border-bottom">
                <Container>
                    <Row className="py-5">
                        <Col>
                            <h1 className="text-center">Termos e condições</h1>
                            <h4 className="mt-3">1. INTRODUÇÃO</h4>
                            <p>O BLOG www.rionegrinhoempregos.org.br lhe informa os Termos e Condições que regem sua utilização,
                            sobre os quais considera que você dá o seu consentimento.</p>
                            <p>O conteúdo deste BLOG se destina a disponibilizar oportunidades de empregos, informações e
                            relacionamento do público do BLOG, com as EMPRESAS ANUNCIANTES e aos USUÁRIOS da Internet.</p>

                            <h4 className="mt-3">2. INFORMAÇÃO</h4>
                            <p>Envidaremos nossos melhores esforços no sentido de manter as informações e materiais contidos neste
                            BLOG tão precisos, atualizados e completos quanto possível.</p>
                            <p>As vagas publicadas são de autoria das EMPRESAS cadastradas no BLOG ou recebidas por meio de veículos
                            de assessoria de imprensa. Procuramos assegurar que as informações fornecidas sejam corretas, completas
                            e atuais. As vagas poderão ser atualizadas e modificadas periodicamente; por conseguinte, não devem ser
                            interpretadas como definitivas.</p>
                            <p>As vagas publicadas com indicação de EMPRESA tem seu conteúdo de responsabilidade das respectivas
                            fontes.</p>
                            <p>As atualizações, correções e remoção de informações cadastrais dos USUÁRIOS são de sua responsabilidade,
                            pois são estes que controlam as atividades acima mencionadas. O BLOG não assume a responsabilidade pela
                            utilização, aplicação ou processamento que os USUÁRIOS possam realizar em relação às mencionadas
                            informações cadastrais em desconformidade com a natureza e função das mesmas.</p>

                            <h4 className="mt-3">3. PRIVACIDADE DE NOSSOS VISITANTES</h4>
                            <p>Não publicamos qualquer informação disponibilizada pelos USUÁRIOS no formulário de contato ou do
                            preenchimento da vaga "e-mail" ou o IP de identificação dos USUÁRIOS. Forneceremos, entretanto, essas
                            informações à justiça quando solicitados, conforme determina a lei.</p>
                            <h4 className="mt-3">4. UTILIZAÇÃO DO PORTAL E DE SEUS LINKS</h4>
                            <p>Em nenhuma hipótese seremos responsáveis pelo uso de nosso BLOG ou de acesso a links nele indicados,
                            bem como por atos praticados por USUÁRIO que tenham por base informações obtidas nos links. Não nos
                            responsabilizamos nem pelo conteúdo nem pelas políticas/práticas de privacidade dos portais que apontam
                            para o nosso BLOG e daqueles para os quais apontamos.</p>
                            <h4 className="mt-3">5. DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL</h4>
                            <p>Os textos, fotografias, imagens, logomarcas e som presentes no BLOG se encontram protegidos por
                            direitos autorais ou outros direitos de propriedade intelectual.</p>
                            <p>Ao acessar a página do BLOG o USUÁRIO declara que irá respeitar todos os direitos de propriedade
                            intelectual e industrial, os decorrentes da proteção de marcas registradas da mesma, bem como todos os
                            direitos referentes a terceiros que por ventura estejam, ou estiveram, de alguma forma disponíveis no
                            BLOG.</p>
                            <p>A reprodução dos conteúdos descritos anteriormente está proibida, salvo prévia autorização por escrito
                            do BLOG e com indicação da fonte, ou que se destinem ao uso exclusivamente pessoal e em hipótese alguma
                            o USUÁRIO adquirirá quaisquer direitos sobre os mesmos.</p>
                            <p>É vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que contrarie a
                            realidade para o qual foi concebido conforme definido neste Termo.</p>
                            <p>O USUÁRIO assume toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização
                            indevida das informações, textos, gráficos, marcas, obras, enfim, todo e qualquer direito de propriedade
                            intelectual ou industrial deste BLOG.</p>

                            <h4 className="mt-3">6. USUÁRIO</h4>
                            <p>O USUÁRIO, neste Termo de Uso, concorda em:</p>
                            <p>(a) fornecer informações verdadeiras, exatas, atuais e completas sobre si mesmo quando do registro no
                            formulário específico para envio de vagas ou pelo contato do BLOG;</p>
                            <p>(b) conservar e atualizar imediatamente tais informações de registro para mantê-las verdadeiras,
                            exatas, atuais e completas.</p>
                            <p>USUÁRIOS menores de 18 (dezoito anos) ou juridicamente incapazes precisam ser representados ou
                            assistidos, conforme o caso, pelos seus pais ou responsáveis legais para utilizar o BLOG.</p>
                            <p>O USUÁRIO concorda em notificar imediatamente o BLOG sobre qualquer uso não autorizado de seu nome.</p>
                            <p>Faz parte de nossa política respeitar a privacidade de nossos USUÁRIOS. O BLOG não irá, portanto,
                            monitorar, editar, acessar ou divulgar informações privativas de seus USUÁRIOS, sem autorização prévia,
                            exceto nos casos expressamente previstos nos Termos e Condições de Uso ou a menos que sejamos obrigados
                            a fazê-lo mediante ordem judicial ou por força de lei.</p>
                            <p>O USUÁRIO autoriza expressamente o BLOG a comunicar-se com o mesmo através de todos os canais de
                            comunicação disponíveis, incluindo correio eletrônico (e-mail), ficando ressaltado que a principal
                            via de informação para o USUÁRIO é o BLOG.</p>

                            <h4 className="mt-3">7. É DE INTEIRA RESPONSABILIDADE DO USUÁRIO:</h4>
                            <p>a) providenciar o seu próprio acesso à Internet e pagar todas as taxas de Serviço eventualmente
                            cobradas por terceiros com relação a tal acesso;</p>
                            <p>b) providenciar seu próprio acesso a um endereço para envio de mensagens eletrônicas via Internet e
                            pagar todas as taxas de Serviço eventualmente cobradas por terceiros com relação a tal acesso;</p>
                            <p>c) providenciar todo o equipamento necessário para efetuar sua conexão à Internet, incluindo, mas não
                            se limitando, a um computador e um modem;</p>
                            <p>d) efetuar seu próprio cadastro no BLOG, responsabilizando-se pela correção e veracidade dos dados
                            informados;</p>
                            <p>e) manter o ambiente de seu computador seguro, com uso de ferramentas disponíveis como antivírus e
                            firewall, entre outras, atualizadas, de modo a contribuir na prevenção de riscos eletrônicos do lado do
                            USUÁRIO.</p>

                            <h4 className="mt-3">8. MODIFICAÇÕES DESTES TERMOS E CONDIÇÕES</h4>
                            <p>Reservamo-nos ao direito de modificar a qualquer momento, visando uma melhoria contínua, de forma
                            unilateral, o presente Termo e Condições de Uso. Ao navegar por este BLOG, você aceita guiar-se pelos
                            Termos e Condições que se encontram vigentes na data e, portanto, deve verificar os mesmos previamente
                            cada vez que visitar este BLOG.</p>

                            <h4 className="mt-3">9. EXLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</h4>
                            <p>Em decorrência de questões operacionais, de terceirização de serviços, o BLOG e os serviços estão
                            sujeitos a eventuais problemas de interrupção, falha técnica, ou indisponibilidade de funcionamento
                            temporário. Quando for razoavelmente possível, o BLOG advertirá previamente as interrupções do
                            funcionamento dos serviços aos seus USUÁRIOS.</p>
                            <p>O BLOG www.rionegrinhoempregos.org.br se exime, com toda a extensão permitida pelo ordenamento jurídico,
                            de qualquer responsabilidade pelos danos e prejuízos de toda natureza que possam decorrer da falta de
                            disponibilidade ou de continuidade do funcionamento do BLOG e dos serviços, à defraudação da utilidade
                            que os USUÁRIOS possam ter atribuído ao BLOG e aos serviços, à falibilidade do BLOG e dos serviços e,
                            em particular, ainda que não de modo exclusivo, às falhas de acesso às distintas páginas web que compõem
                            o BLOG ou em que se prestam os serviços.</p>
                            <h4 className="mt-3">10. DISPOSIÇÕES GERAIS</h4>
                            <p>A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente contrato
                            não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade
                            das mesmas a qualquer tempo.</p>
                            <p>O presente instrumento constitui o acordo integral entre as partes, prevalecendo sobre qualquer outro
                            entendimento firmado anteriormente.</p>
                            <h4 className="mt-3">11. LEI APLICÁVEL E JURISDIÇÃO</h4>
                            <p>O presente Termo e Condições de Uso é regido pela legislação da República Federativa do Brasil.
                            Seu texto deverá ser interpretado no idioma português e os USUÁRIOS do blog de empregos se submetem
                            ao Foro da Comarca de Rio Negrinho - SC.</p>
                            <h4 className="mt-3">12. DÚVIDAS</h4>
                            <p>Caso tenha qualquer dúvida em relação ao presente documento, favor entrar em contato através do
                            <a href="mailto:contato@rionegrinhoempregos.com.br" title="E-mail">contato@rionegrinhoempregos.com.br</a>.</p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
};

export default (TermosCondicoes);
