import React from 'react';

import { Container,Row,Col } from 'reactstrap';
import { Link } from "react-router-dom";
import jwt_decode from 'jwt-decode';

import { JWT_TOKEN } from 'helpers/consts';

import { BackGround,H1 } from './styles';

import backgroundPrincipal from 'assets/img/bkgPrincipal.jpg';
import { ThirdButton } from 'components/Buttons/styles';

class BannerPrincipal extends React.Component {
    render() {
        let token = localStorage.getItem(JWT_TOKEN) ? jwt_decode(localStorage.getItem(JWT_TOKEN)) : null;
        return (
			<BackGround source={backgroundPrincipal}>
                <Container fluid={true}>
                    <Container>
                        <Row className="py-5">
                            <Col xs="12" className="text-white mt-5">
                                <p className="h3 m-0 font-weight-light">CONECTANDO ÓTIMAS</p>
                                <H1 className="m-0">OPORTUNIDADES</H1>
                                <p className="h2">A GRANDES TALENTOS</p>
                            </Col>
                            <Col xs="12" className="py-3 mb-5">
                                <ThirdButton tag={ Link } to=
                                    {!token ? (
                                        `${process.env.PUBLIC_URL}/login`
                                    ) : (
                                        token.platform === 'candidato' ? (
                                            `${process.env.PUBLIC_URL}/logout`
                                        ) : (
                                            `${process.env.PUBLIC_URL}/empresa/vagas/new`
                                        )
                                    )}
                                    className="mr-2 my-2">Quero anunciar</ThirdButton>
                                <ThirdButton tag={ Link } to=
                                    {!token ? (
                                        `${process.env.PUBLIC_URL}/login`
                                    ) : (
                                        token.platform === 'empresa' ? (
                                            `${process.env.PUBLIC_URL}/logout`
                                        ) : (
                                            `${process.env.PUBLIC_URL}/vagas`
                                        )
                                    )}
                                className="my-2">Quero me candidatar</ThirdButton>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (BannerPrincipal);
