import styled from 'styled-components';
import { THIRD_COLOR } from 'helpers/consts';

export const BackGround = styled.div`
    background-size: cover
    background-position: top center
    background-image: url(${props => props.source});    
`
export const ValorNumerico = styled.p`
    font-size: 55px
    color:${THIRD_COLOR}
`