import React from 'react';
import { Col, Row } from 'reactstrap';

import { TituloRow, Titulo, Info, Situacao } from './styles';

export default ({vaga}) => {
    return (
        <Col xs="12" className="my-2 mb-4 px-2 px-md-0">
            <TituloRow className="mx-3">
                <Titulo>{vaga.nome}</Titulo>
            </TituloRow>
            <Row className="d-flex align-items-center justify-content-center py-3">
                <Col xs="12" lg="8" className="px-2 px-md-0">
                    {vaga.ocultaInfo !== 1 &&
                        <>
                            <Info><b>Empresa: </b>{vaga.empresa}</Info>
                            <Info><b>Telefone: </b>{vaga.telefone}</Info>
                            <Info><b>E-mail: </b>{vaga.email}</Info>
                            <Info><b>Cidade: </b>{vaga.cidade}</Info>
                        </>
                    }
                    {vaga.ocultaInfo === 1 &&
                        <>
                            <Info><b>Empresa: </b>Confidencial</Info>
                            <Info><b>Cidade: </b>{vaga.cidade}</Info>
                        </>
                    }
                </Col>
                <Situacao className="px-2 px-md-0" status={vaga.status}/>
            </Row>
        </Col>
    );
};