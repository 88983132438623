import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { SecondaryButton } from 'components/Buttons/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'helpers/consts';

const Link = styled(NavLink)`
    color: ${PRIMARY_COLOR};
    border-bottom: 3px solid ${SECONDARY_COLOR};
    border-radius: 0;
    width: 100%;
    text-align: left;
    padding: 0;
    display: flex;
    font-weight: bold;
    margin-bottom: 13px;

    &:hover, &:focus, &:active, &.active{
        text-decoration: none;
        color: ${PRIMARY_COLOR};
        border-color: ${PRIMARY_COLOR};
    }
`

class SuaContaEmpresa extends React.Component {
    render() {
        return (
            <>
                <SecondaryButton tag={NavLink} to={`${process.env.PUBLIC_URL}/empresa/vagas/new`} className="mb-3 w-100">CADASTRAR VAGA</SecondaryButton>
                <Link exact to={`${process.env.PUBLIC_URL}/empresa`}>PRINCIPAL</Link>
                <Link exact to={`${process.env.PUBLIC_URL}/empresa/edit`}>DADOS DA EMPRESA</Link>
                <Link exact to={`${process.env.PUBLIC_URL}/empresa/vagas`}>VAGAS CADASTRADAS</Link>
            </>
        );
    }
};

export default (SuaContaEmpresa);
