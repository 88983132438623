import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col, FormGroup } from 'reactstrap';
import jwt_decode from 'jwt-decode';

// Consts
import { PRIMARY_COLOR, JWT_TOKEN } from 'helpers/consts';

import { loadOptions as loadSetoresOptions } from 'actions/setores';
import { loadOptions as loadCidadesOptions } from 'actions/cidades';
import { update, fetch } from 'actions/clientes';

// Componentes
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import { SecondaryButton, PrimaryButton } from 'components/Buttons';
import CpfCnpj from 'components/FormInput/CpfCnpj';
import Telefone from 'components/FormInput/Telefone';
import Cep from 'components/FormInput/Cep';
import { InputLabel } from 'components/FormInput/styles';
import Checkbox from 'components/Checkbox';
import Thumb from 'components/Thumb';

// Validação
import validationSchema from './validation';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FormCadastroEmpresa extends React.Component {
    constructor() {
        super();
         
        this._initialValues = {
            nome: '',
            tipoPessoa: 'J',
            cnpjCpf: '',
            telefone: '',
            telefone2: '',
            telefone3: '',
            responsavel: '',
            cep: '',
            email: '',
            endereco: '',
            ocultaInfo: 0,
            link: '',
            patrocinador: 0,
            master: 0,
            senha: '',
            confirmaSenha: '',
            logo: '',
            anuncio: '',
            status: 1,
            idEstado: null,
            nomeEstado: '',
            uf: '',
            idCidade: null,
            nomeCidade: '',
            idSetor: null,
            setor: ''
        };     

        this.state = {
            loading: true
        }
    }

    fetchData = (id) => {
        fetch(id, (data)=>{
            if(data.result){
                this._initialValues = data.result;
                this._initialValues.senha = '';
                this._initialValues.confirmaSenha = '';
                this.setState({
                    loading: false
                });
            }
        })
    } 

    componentDidMount() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token){
            this.setState({
                loading: false
            });
            return false;
        }

        let decoded = jwt_decode(token);

        if(decoded.sub){
            this.fetchData(decoded.sub);
        } else {
            this.setState({
                loading: false
            })
        }
    }

    handleSubmit = (values, actions) => {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return false;

        let decoded = jwt_decode(token);

        values.setor = values.idSetor;
        values.cidade = values.idCidade;
        values.estado = values.idEstado;
        values.idCliente = decoded.sub;
        update(values, actions, ()=>{
            this.setState({loading: true}, ()=>{
                this.fetchData(decoded.sub);
            })
        });
    }

	render() {
        if(this.state.loading){
            return (
                <Container>
                    <p className="text-center">Carregando...</p>
                </Container>
            );
        }
		return (
            <Container>
                <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Dados da Empresa</h4>
                <Row className="pb-5 pt-2 d-flex justify-content-center">    
                    <Col xs="12">
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this._initialValues}
                            onSubmit={this.handleSubmit}
                            render={(FormikProps) => (
                                <Form onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'nome'
                                            }} form={FormikProps} label="Nome da empresa *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'cnpjCpf',
                                                tag: CpfCnpj
                                            }} form={FormikProps} label="CPF / CNPJ *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormGroup>
                                                <InputLabel>Tipo de Pessoa *</InputLabel>
                                                <div>
                                                    <Checkbox onChange={()=>{
                                                        FormikProps.setFieldValue('tipoPessoa', 'J')
                                                    }} checked={FormikProps.values.tipoPessoa === 'J'} inline type="radio" id="tipoPessoaJ" name="tipoPessoa" label="Jurídica" />

                                                    <Checkbox onChange={()=>{
                                                        FormikProps.setFieldValue('tipoPessoa', 'F')
                                                    }} checked={FormikProps.values.tipoPessoa === 'F'} inline type="radio" id="tipoPessoaF" name="tipoPessoa" label="Física" />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormSelect field={{
                                                isSearchable: false,
                                                loadOptions: loadSetoresOptions,
                                                defaultOptions: true,
                                                name: 'idSetor',
                                                getOptionLabel: (option) => option.setor,
                                                getOptionValue: (option) => option.idSetor,
                                                loadingMessage: ()=>"Carregando...",
                                                noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                                value: FormikProps.values.idSetor ? {
                                                    idSetor: FormikProps.values.idSetor,
                                                    setor: FormikProps.values.setor
                                                } : null,
                                                onChange: (value) => {
                                                    FormikProps.setFieldValue("idSetor", value.idSetor);
                                                    FormikProps.setFieldValue("setor", value.setor);
                                                }
                                            }} form={FormikProps} async={true} label="Setor"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'email',
                                                type: 'email',
                                                autoComplete: 'username'
                                            }} form={FormikProps} label="E-mail *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'responsavel'
                                            }} form={FormikProps} label="Responsável *"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'senha',
                                                type: 'password',
                                                autoComplete: 'new-password'
                                            }} form={FormikProps} label="Senha (Digite para alterar)"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'confirmaSenha',
                                                type: 'password',
                                                autoComplete: 'new-password'
                                            }} form={FormikProps} label="Confirmação de Senha"/>
                                        </Col>
                                        <Col xs="12" lg="4">
                                            <FormInput field={{
                                                name: 'telefone',
                                                tag: Telefone
                                            }} form={FormikProps} label="Telefone *"/>
                                        </Col>
                                        <Col xs="12" lg="4">
                                            <FormInput field={{
                                                name: 'telefone2',
                                                tag: Telefone
                                            }} form={FormikProps} label="Telefone 2"/>
                                        </Col>
                                    
                                        <Col xs="12" lg="4">
                                            <FormInput field={{
                                                name: 'telefone3',
                                                tag: Telefone
                                            }} form={FormikProps} label="Telefone 3"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'link'
                                            }} form={FormikProps} label="Link"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'cep',
                                                tag: Cep
                                            }} form={FormikProps} label="CEP"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                name: 'endereco'
                                            }} form={FormikProps} label="Endereço"/>
                                        </Col>
                                        <Col xs="12" lg="6">
                                        <FormSelect field={{
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            name: 'idCidade',
                                            loadOptions: loadCidadesOptions,
                                            getOptionLabel: (option) => `${option['nome']}${option['estado'] ? ' - ' + option['estado']['uf'] : ''}`,
                                            getOptionValue: (option) => {
                                                return option.idCidade;
                                            },
                                            loadingMessage: ()=>"Carregando...",
                                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                            value: FormikProps.values.idCidade ? {
                                                idCidade: FormikProps.values.idCidade,
                                                nome: FormikProps.values.nomeCidade,
                                                estado: {
                                                    idEstado: FormikProps.values.idEstado,
                                                    nome: FormikProps.values.nomeEstado,
                                                    uf: FormikProps.values.uf,
                                                }
                                            } : null,
                                            onChange: (value) => {
                                                FormikProps.setFieldValue("idCidade", value.idCidade);
                                                FormikProps.setFieldValue("nomeCidade", value.nome);
                                                FormikProps.setFieldValue("idEstado", value.estado.idEstado);
                                                FormikProps.setFieldValue("nomeEstado", value.estado.nome);
                                                FormikProps.setFieldValue("uf", value.estado.uf);
                                            }
                                        }} form={FormikProps} async={true} label="Cidade"/>
                                        </Col>

                                        <Col xs="12" lg="6">
                                            <InputLabel>Logo</InputLabel>
                                            {this._initialValues.logo && <img alt={this._initialValues.nome} src={this._initialValues.logo} className="img-fluid my-2"/>}
                                            <input type="file" name="logo" id="logo-empresa" style={{display:'none'}} onChange={(event) => {
                                                FormikProps.setFieldValue("logo", event.target.files[0]);
                                            }}/>
                                            <label htmlFor="logo-empresa" style={{display: 'flex', alignItems: 'center'}}>
                                                <PrimaryButton onClick={()=>{document.getElementById('logo-empresa').click()}} block size="sm" type="button" style={{color:'white', marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light">ESCOLHER ARQUIVO</PrimaryButton>
                                            </label>
                                            <div style={{flexGrow: 1, marginLeft: '15px', width: '100%'}}>{(FormikProps.values.logo && FormikProps.values.logo.name) ? FormikProps.values.logo.name : ''}</div>
                                            <Thumb file={FormikProps.values.logo} />
                                        </Col>

                                        <Col xs="12" lg="6">
                                            <InputLabel>Banner (Anúncio)</InputLabel>
                                            {this._initialValues.anuncio && <img alt={this._initialValues.nome} src={this._initialValues.anuncio} className="img-fluid my-2"/>}
                                            <input type="file" name="anuncio" id="anuncio-empresa" style={{display:'none'}} onChange={(event) => {
                                                FormikProps.setFieldValue("anuncio", event.target.files[0]);
                                            }}/>
                                            <label htmlFor="anuncio-empresa" style={{display: 'flex', alignItems: 'center'}}>
                                                <PrimaryButton onClick={()=>{document.getElementById('anuncio-empresa').click()}} block size="sm" type="button" style={{color:'white', marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light">ESCOLHER ARQUIVO</PrimaryButton>
                                            </label>
                                            <div style={{flexGrow: 1, marginLeft: '15px', width: '100%'}}>{(FormikProps.values.anuncio && FormikProps.values.anuncio.name) ? FormikProps.values.anuncio.name : ''}</div>
                                            <Thumb file={FormikProps.values.anuncio} />
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs="12">
                                            <SecondaryButton disabled={FormikProps.isSubmitting} type="submit">Salvar <FontAwesomeIcon icon='check' color={PRIMARY_COLOR}/></SecondaryButton>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default FormCadastroEmpresa;
