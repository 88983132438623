import React from "react";
import { withRouter } from 'react-router-dom';

// Componentes
import Banner from 'components/Banners/SuaContaEmpresa';
import Main from 'components/SuaContaEmpresa';

//Analytics
import ReactGA from 'react-ga';

class Empresa extends React.Component {
	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}
	
	render() {
		return (
			<div>
				<Banner/>
                <Main/>
			</div>
		);
	}
}

export default withRouter(Empresa);
