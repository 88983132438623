import React from "react";

import BannerQuemSomos from 'components/Banners/QuemSomos'
import MarcosHistoricos from "components/MarcosHistoricos";
import Idealizadores from "components/Idealizadores";
import Estatisticas from "components/Estatisticas";
import Patrocinadores from "components/Patrocinadores";
import Cabecalho from "components/Cabecalhos";

//Analytics
import ReactGA from 'react-ga';

class QuemSomos extends React.Component {

	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}
	
	render() {
		return (
			<div>
				<Cabecalho title='quem somos' description='description quem somos' keywords='keys quem somos'/>
				<BannerQuemSomos/>
				<MarcosHistoricos/>
				<Idealizadores/>
				<Estatisticas/>
				<Patrocinadores/>
			</div>
		);
	}
}

export default QuemSomos;
