import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'

export default class Cpf extends Component {
    constructor() {
        super();
        
        let anyNum = /[0-9]/;
        this._maskCpf = [anyNum, anyNum, anyNum, '.', anyNum, anyNum, anyNum, '.', anyNum, anyNum, anyNum, '-', anyNum, anyNum];
    }

    render() {
        return (
            <MaskedInput guide={true} mask={this._maskCpf} {...this.props}/>
        )
    }
}