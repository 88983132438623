import * as Yup from 'yup';
import { addInYup as addCep } from "helpers/validation/Cep"; 

// Adiciona funções customizadas no YUP (Não existentes por padrão)
addCep();

const validationSchema = Yup.object().shape({
    endereco: Yup.string().required('Por favor informe o nome da Empresa'),
    bairro: Yup.string().required('Por favor informe o nome da Empresa'),
    cep: Yup.string().required('Por favor digite o CEP').cep('Por favor informe um CEP válido'),
    idCidade: Yup.mixed().required('Por favor selecione a cidade')
});

export default validationSchema;