import styled from 'styled-components';
import { Label } from 'reactstrap';

import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

import { INFO_COLOR } from 'helpers/consts';

export const InputLabel = styled(Label)`
    font-size: 14px;
    margin-bottom: 0;
`

export const defaultStyles = {
    control: (provided, state)=>({
        ...provided,
        width: '100%',
        height: 'calc(1.5em + 0.75rem + 2px)',
        paddingRight: '0!important',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#495057',
        backgroundClip: 'padding-box',
        border: state.selectProps['data-success'] ? '1px solid #28a745' : state.selectProps['data-error'] ? '1px solid #dc3545' : '1px solid #ced4da',
        borderRadius: '0.25rem',
        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
        backgroundColor: state.isFocused ? 'white' : INFO_COLOR,
        borderWidth: '1px',
        boxShadow: state.isFocused ? (state.selectProps['data-success'] ? '0 0 0 0.2rem rgba(40, 167, 69, 0.25)' : state.selectProps['data-error'] ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)' : '0 0 0 0.2rem rgba(0, 123, 255, 0.25)') : 'none',
    })
}

export const CustomSelect = styled(Select).attrs(props => ({
    theme: theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: INFO_COLOR,
            primary: '#80bdff'
        }
    })
}))``;
export const CustomAsyncSelect = styled(AsyncSelect).attrs(props => ({
    theme: theme => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: INFO_COLOR,
            primary: '#80bdff'
        }
    })
}))``;