import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { BackGround,ValorNumerico } from './styles';

import bkg from 'assets/img/bkgEstatisticas.jpg';
import iconVagas from 'assets/img/iconVagas.png';
import iconCurriculos from 'assets/img/iconCurriculos.png';
import iconEmpresas from 'assets/img/iconEmpresas.png';
import iconContratacoes from 'assets/img/iconContratacoes.png';

class Estatisticas extends React.Component {
    render() {
        return (
			<BackGround source={bkg}>
                <Container fluid={true}>
                    <Container>
                        <Row className="py-5 align-items-center justify-content-between text-center">
                            <Col xs="12" md="6" lg="auto" className="my-5 px-3">
                                <img src={iconVagas} title="Vagas" alt="Vagas" className="img-fluid center-block"/>
                                <ValorNumerico className="h1 font-weight-bold">660</ValorNumerico>
                                <p className="text-white h4">VAGAS</p>
                            </Col>
                            <Col xs="12" md="6" lg="auto" className="my-5 px-3">
                                <img src={iconCurriculos} title="Currículos" alt="Currículos" className="img-fluid center-block"/>
                                <ValorNumerico className="h1 font-weight-bold">2646</ValorNumerico>
                                <p className="text-white h4">CURRÍCULOS</p>
                            </Col>
                            <Col xs="12" md="6" lg="auto" className="my-5 px-3">
                                <img src={iconEmpresas} title="Empresas" alt="Empresas" className="img-fluid center-block"/>
                                <ValorNumerico className="h1 font-weight-bold">186</ValorNumerico>
                                <p className="text-white h4">EMPRESAS</p>
                            </Col>
                            <Col xs="12" md="6" lg="auto" className="my-5 px-3">
                                <img src={iconContratacoes} title="Contratações" alt="Contratações" className="img-fluid center-block"/>
                                <ValorNumerico className="h1 font-weight-bold">514</ValorNumerico>
                                <p className="text-white h4">CONTRATAÇÕES</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (Estatisticas);
