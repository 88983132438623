import React from "react";

import BannerVagas from 'components/Banners/Vagas'
import CompVagasView from "components/VagasView";
import Patrocinadores from "components/Patrocinadores";

//Analytics
import ReactGA from 'react-ga';

class VagasView extends React.Component {
	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}

	render() {
		return (
			<div>
				<BannerVagas/>
                <CompVagasView/>
				<Patrocinadores/>
			</div>
		);
	}
}

export default VagasView;
