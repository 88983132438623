import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import Menu from './Menu';
import Content from './Content';
import jwt_decode from 'jwt-decode';

// Constantes
import { JWT_TOKEN } from 'helpers/consts';

class SuaContaCandidato extends React.Component {
    componentDidMount() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token || (token && jwt_decode(token).platform !== 'candidato')){
            this.props.history.push(`${process.env.PUBLIC_URL}`)
        }
    }
    render() {
        return (
            <Container>
                <Row className="py-5">
                    <Col xs="12" md="4" lg="3">
                        <Menu/>
                    </Col>
                    <Col xs="12" md="8" lg="9">
                        <Content/>
                    </Col>
                </Row>
            </Container>
        );
    }
};

export default withRouter(SuaContaCandidato);
