import React from "react";
import styled from 'styled-components';
import { CustomInput } from 'reactstrap';

import { PRIMARY_COLOR } from 'helpers/consts';

export const CustomCheck = styled(CustomInput)`
    ${(props)=>{
        if(props.checked)
            return `
                && label::before{
                    border-color: ${PRIMARY_COLOR}!important;
                    background-color: ${PRIMARY_COLOR}!important;
                }
            `;
    }}
`

function Checkbox(props) {    
    return (
        <CustomCheck {...props}/>
    );
}

export default Checkbox;
