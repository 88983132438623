import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Ações / Métodos
import { logoutUser } from 'actions/auth';
import imageColorOverlay from 'helpers/imageColorOverlay';

// Estilos
import { BackGround,H1 } from './styles';
import { SECONDARY_COLOR } from 'helpers/consts';

// Imagens
import bkg from 'assets/img/bkgPrincipal.jpg';
import icon from 'assets/img/iconCandidato.png';

class BannerSuaContaCandidato extends React.Component {
    render() {
        return (
			<BackGround source={bkg}>
                <Container fluid={true}>
                    <Container>
                        <Row className="py-4 d-flex justify-content-center align-items-center">
                            <Col xs="12" className="text-white my-3">
                                <Row className="d-flex align-items-center">
                                    <Col xs="auto pr-0"><img style={{filter:imageColorOverlay(SECONDARY_COLOR)}} src={icon} alt="Candidato" className="img-fluid"/></Col>
                                    <Col>
                                        <H1 className="pl-0 m-0 font-weight-normal" xs="auto">SUA CONTA</H1>
                                    </Col>
                                    <Col xs="12" sm="auto" className="font-weight-bold" style={{color: SECONDARY_COLOR, cursor: 'pointer'}} onClick={logoutUser}>
                                        &times; SAIR
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (BannerSuaContaCandidato);
