import React from "react";
import { withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import InfiniteScroll from 'react-infinite-scroller';

// Métodos / Ações
import { fetchVagasRecentes as fetchList } from 'actions/candidatos';

// Constantes
import { JWT_TOKEN, PRIMARY_COLOR } from 'helpers/consts';

// Componentes
import BoxVaga from 'components/SuaContaCandidato/BoxVaga';

class Vagas extends React.Component {
    constructor(){
        super();

        this.state = {
            pgSize: 5,
            hasMore: true,
            vagas: []
        }
    }

    loadData = (pg = 1) => {
        let reqData = {
            pg,
            pgSize: this.state.pgSize

        }
        var promise = new Promise((res, rej) => {
            fetchList(reqData, res, rej);
        });
        
        promise.then((data) => {
            let hasMore = true;
            if(data.paginacao.paginas <= pg){
                hasMore = false;
            }
            let vagas = this.state.vagas;
            for(let i=0; i < data.results.length; i++){
                vagas.push({
                    idVaga: data.results[i].idVaga,
                    nome: data.results[i].cg_nome,
                    status: data.results[i].status,
                    empresa: data.results[i].em_nome,
                    telefone: data.results[i].telefone,
                    email: data.results[i].email,
                    ocultaInfo: data.results[i].cl_ocultaInfo,
                    cidade: data.results[i].cd_nome + ' - ' + data.results[i].es_uf
                })
            }

            this.setState({
                hasMore,
                vagas
            });
        });
    }

    componentDidMount(){
        this.loadData();
    }

	render() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token)
            return null;
        
        token = jwt_decode(token);
    
        const vagas = this.state.vagas.map((value, idx)=> <BoxVaga vaga={value} key={idx}/>)
		return (
			<div>
                <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Vagas que me candidatei</h4>
                <div className="text-muted mt-3 mb-4">
                    {this.state.vagas.length > 0 &&
                        <>
                            <InfiniteScroll
                                pageStart={1}
                                loadMore={this.loadData}
                                hasMore={this.state.hasMore}
                                initialLoad={false}
                                loader={<div className="loader" key={0}>Carregando ...</div>}
                            >
                                {vagas}
                            </InfiniteScroll>
                        </>
                    }
                    {this.state.vagas.length === 0 &&
                        <>Você ainda não se cadastrou em nenhuma vaga.</>
                    }
                </div>
			</div>
		);
	}
}

export default withRouter(Vagas);
