import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';

class DateInput extends Component {

    constructor(props) {
        super(props);

        let anyNum = /[0-9]/;
        this._mask = [anyNum, anyNum, '/', anyNum, anyNum, '/', anyNum, anyNum, anyNum, anyNum];
    }
    render() {
        return <MaskedInput guide={true} mask={this._mask} {...this.props}/>
    }
}

export default DateInput;