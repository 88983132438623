import React from 'react';

import { Container,Row,Col } from 'reactstrap';
import { CustomSlider } from "./style";

import { defaultFetchList } from 'actions/default';

class Patrocindores extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
			patrocinadores: <div className="text-center">Carregando...</div>,
		};
    }

    componentDidMount(){
        let reqData = {
            s: '',
            pg: 1,
            pgSize: 15
        };
        var promise = new Promise((res, rej) => {
            defaultFetchList('clientes/patrocinadores/logos', reqData, res, rej);
        });
        
        promise.then((data) => {
            let result = null;
            if(data.results.length){
                const patrocinadoresDisplay = data.results.map((patrocinador,idx) => {
					return (
						<div className="mx-3" key={idx}>
							{patrocinador.link ? (
								<a href={'http://'+patrocinador.link} rel="noopener noreferrer" title={patrocinador.nome} target="_blank">
									<img src={patrocinador.logo} alt={patrocinador.nome} className="img-fluid" style={{maxHeight:'100px'}}/>
								</a>
							) : (
								<img src={patrocinador.logo} alt={patrocinador.nome} className="img-fluid" style={{maxHeight:'100px'}}/>
							)}
						</div>
					);
                }); 

                var settings = {
                    dots: true,
                    infinite: false,
                    speed: 300,
                    autoplay: true,
                    autoplaySpeed: 7000,
                    slidesToShow: 7,
                    slidesToScroll: 7,
                    lazyLoad: 'ondemand',
                    responsive: [
                      {
                        breakpoint: 1200,
                        settings: {
                          slidesToShow: 5,
                          slidesToScroll: 5,
                          infinite: true,
                          dots: true
                        }
                      },
                      {
                        breakpoint: 1024,
                        settings: {
                          slidesToShow: 4,
                          slidesToScroll: 4,
                          infinite: true,
                          dots: true
                        }
                      },
                      {
                        breakpoint: 600,
                        settings: {
                          slidesToShow: 3,
                          slidesToScroll: 3
                        }
                      },
                      {
                        breakpoint: 480,
                        settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2
                        }
                      }
                    ]
                };

                result = <CustomSlider {...settings}>
                            {patrocinadoresDisplay}
                        </CustomSlider>
            }

            this.setState({
                patrocinadores: result
            });
        });
    }

    render() {
        return (
            <Container fluid={true} className="border-bottom">
                <Container>
                    <Row className="py-5">
                        <Col xs="12" lg="4" className="pt-4 font-weight-light text-center text-xl-left">
                            <p className="h2 font-weight-light text-center text-md-left my-2">PATROCINADORES</p>
                        </Col>
                        <Col xs="12" lg="8">{this.state.patrocinadores}</Col>
                    </Row>
                </Container>
            </Container>
        );
    }
};

export default (Patrocindores);
