import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

import { PRIMARY_COLOR, SECONDARY_COLOR } from 'helpers/consts';

const DANGER_COLOR = "#cf5125";
const SUCCESS_COLOR = "#2ba959";

export const TituloRow = styled(Row)`
    border-bottom: 2px solid ${PRIMARY_COLOR}
`

export const Titulo = styled(Col).attrs(()=>({
    className: 'px-0'
}))`
    color:${PRIMARY_COLOR};
    font-size: 18px;
    font-weight: 600!important;
`

export const EditBtn = styled(NavLink).attrs((props)=>({
    children: <><FontAwesomeIcon className='mr-2' icon='pencil-alt' color={PRIMARY_COLOR}/>EDITAR</>,
    to: `${process.env.PUBLIC_URL}/empresa/vagas/edit/${btoa(props.id)}`
}))`
    color:${PRIMARY_COLOR};
    &:hover, &:active, &:focus {
        color: ${PRIMARY_COLOR};
        text-decoration: none;
        outline: none;
    }
`

export const DeleteBtn = styled.button.attrs(()=>({
    className: 'ml-3',
    children: <><FontAwesomeIcon className='mr-2' icon='times' color={DANGER_COLOR}/>EXCLUIR</>
}))`
    ${props=>props.curriculos <= 0 ? '' : 'display: none;'}
    color:${DANGER_COLOR};
    background-color: transparent;
    border: none;
    &:hover, &:active, &:focus {
        color: ${DANGER_COLOR};
        text-decoration: none;
        outline: none;
    }
`

export const Situacao = styled(Col).attrs((props)=>({
    children: props.status === -1 ? 'AG. AVALIAÇÃO' : props.status === 0 ? 'BLOQUEADA' : props.status === 1 ? 'EM ABERTO' : 'PREENCHIDA',
    xs: '12',
    sm: '6',
    md: '4',
    lg: '3',
    className: 'rounded'
}))`
    margin-top: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    color: white;
    text-align:center;
    background-color: ${(props)=>props.status === -1 ? PRIMARY_COLOR : props.status === 0 ? SECONDARY_COLOR : props.status === 1 ? SUCCESS_COLOR : DANGER_COLOR}
`

export const QuantidadeCurriculos = styled(Col)`
    color: ${SUCCESS_COLOR};
    margin-top: 15px;
    margin-left: 15px;
    display: flex;
    align-items:center;
    font-weight: 500;
`