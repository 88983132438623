import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from './Main';
import Vagas from './Vagas';
import FormPessoal from 'views/CadastroCandidato/Pessoal/form';
import FormEndereco from 'views/CadastroCandidato/Endereco/form';
import FormEscolaridade from 'views/CadastroCandidato/Escolaridade/form';
import FormProfissional from 'views/CadastroCandidato/Profissional/form';

class Content extends React.Component {
    render() {
        return (
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/minha-conta/edit/pessoal`} component={FormPessoal}/>
                <Route path={`${process.env.PUBLIC_URL}/minha-conta/edit/endereco`} component={FormEndereco}/>
                <Route path={`${process.env.PUBLIC_URL}/minha-conta/edit/escolaridade`} component={FormEscolaridade}/>
                <Route path={`${process.env.PUBLIC_URL}/minha-conta/edit/profissional`} component={FormProfissional}/>
                <Route path={`${process.env.PUBLIC_URL}/minha-conta/vagas`} component={Vagas}/>
                <Route path={`${process.env.PUBLIC_URL}/minha-conta`} component={Main}/>
            </Switch>
        );
    }
};

export default (Content);
