import axios from "axios";
import objToQueryString from "helpers/objToQueryString";
import { defaultFetch, defaultSave } from "./default";

const model = "candidatos";
const idField = "idCandidato";

export const fetch = (id, callback) => defaultFetch(model, id, callback);
export const save = (data, actions, afterSave, afterError) =>
  defaultSave(model, idField, data, true, actions, afterSave, afterError);
export const saveEndereco = (data, actions, afterSave, afterError) =>
  defaultSave(
    model,
    idField,
    data,
    false,
    actions,
    afterSave,
    afterError,
    "/endereco"
  );
export const saveEscolaridade = (data, actions, afterSave, afterError) =>
  defaultSave(
    model,
    idField,
    data,
    false,
    actions,
    afterSave,
    afterError,
    "/escolaridade"
  );
export const saveProfissional = (data, actions, afterSave, afterError) =>
  defaultSave(
    model,
    idField,
    data,
    false,
    actions,
    afterSave,
    afterError,
    "/profissional"
  );

export const vagasInteresse = (callback) => {
  axios
    .get("candidatos/novas-vagas-interesse/")
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      console.error(err);
    });
};

export function fetchVagasRecentes(reqData, resolve, reject) {
  let ignoreNulls = true;

  axios
    .get(`${model}/vagas/${objToQueryString(reqData, ignoreNulls)}`)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      let erro = { message: "Não foi possível obter os dados" };
      if (err.response) {
        erro = err.response.data;
      }
      reject(erro.message);
    });
}
