import React from "react";
import { withRouter } from 'react-router-dom';
import Header from './Header';
import Main from './Main';

class Curriculo extends React.Component {
	render() {
        return <>
            <Header {...this.props}/>
            <Main {...this.props}/>
        </>;
	}
}

export default withRouter(Curriculo);
