import React from "react";

import BannerCapacitacoes from 'components/Banners/Capacitacoes'
import CompCapacitacoes from "components/Capacitacoes";
import Patrocinadores from "components/Patrocinadores";
import Cabecalho from "components/Cabecalhos";

//Analytics
import ReactGA from 'react-ga';

class Capacitacoes extends React.Component {
	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}

	render() {
		return (
			<div>
				<Cabecalho title='Capacitações' description='description capacitações' keywords='keys capacitações'/>
				<BannerCapacitacoes/>
                <CompCapacitacoes/>
				<Patrocinadores/>
			</div>
		);
	}
}

export default Capacitacoes;
