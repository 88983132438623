import styled from 'styled-components';
import { Nav, NavLink } from 'reactstrap';

import { PRIMARY_COLOR } from 'helpers/consts';
import { SECONDARY_COLOR } from 'helpers/consts';

export const CustomNav = styled(Nav)`
    border-bottom:5px solid ${PRIMARY_COLOR}
`
export const CustomNavLink = styled(NavLink)`
    color:#b8b8b8!important
    font-weight:bold
    &:hover,&.active{
        color:${PRIMARY_COLOR}!important
        border: none
        border-color: ${SECONDARY_COLOR}!important
        border-bottom: 7px solid ${SECONDARY_COLOR}
        margin-bottom: -5px
    }
`