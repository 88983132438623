import React from "react";
import { Formik } from 'formik';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';


// Consts
import { PRIMARY_COLOR } from 'helpers/consts';
import { CustomForm } from './styles';

import { loadOptions as loadCidadesOptions } from 'actions/cidades';

// Componentes
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import { SecondaryButton } from 'components/Buttons';
import Telefone from 'components/FormInput/Telefone';

// Validação
import validationSchema from './validation';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MySwal = withReactContent(Swal);

class FormContato extends React.Component {
    constructor() {
        super();
         
        this._initialValues = {
            assunto: '',
            nome: '',
            email: '',
            telefone: '',
            idEstado: null,
            idCidade: null,
            nomeCidade: '',
            mensagem: '',
        };     
    }

    handleSubmit = (values, actions) => {
        let assunto = values.assunto;
        let email = values.email;
        let telefone = values.telefone.toString().replace('-', '').insert(-4, '-');
        let mensagem = values.mensagem;
        let nome = values.nome;
        axios.post('contato', {assunto, email, telefone, mensagem, nome}, {headers: {'Accept': 'application/json'}})
        .then(() => {
            actions.setSubmitting(false);
            actions.resetForm(this._initialValues);
            MySwal.fire({
                type: 'success',
                title: 'Mensagem enviada! Obrigado!',
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
            });
        }).catch(err => {
            actions.setSubmitting(false);
            console.log(err.response);
            if(err.response.status===400){
                MySwal.fire({
                    type: 'info',
                    title: err.response.data.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }else{
                MySwal.fire({
                    type: 'danger',
                    title: 'Houve algum problema. Tente mais tarde',
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                });
            }
        });
    }

	render() {
		return (
            <Container>
                <Row className="py-5 d-flex justify-content-center">    
                    <Col xs="12" md="10" lg="8">
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this._initialValues}
                            onSubmit={this.handleSubmit}
                            render={(FormikProps) => (
                                <CustomForm onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12">
                                            <FormInput field={{
                                                value: FormikProps.values.assunto,
                                                name: 'assunto'
                                            }} form={FormikProps} label="Assunto *"
                                            />
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                value: FormikProps.values.nome,
                                                name: 'nome',
                                            }} form={FormikProps} label="Nome *"
                                            />
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                value: FormikProps.values.email,
                                                name: 'email',
                                                // tag: email
                                            }} form={FormikProps} label="E-mail *"
                                            />
                                        </Col>
                                        <Col xs="12" lg="6">
                                            <FormInput field={{
                                                value: FormikProps.values.telefone,
                                                name: 'telefone',
                                                tag: Telefone
                                            }} form={FormikProps} label="Telefone *"
                                            />
                                        </Col>
                                        <Col xs="12" lg="6">
                                        <FormSelect field={{
                                            placeholder: "Digite para localizar...",
                                            isSearchable: true,
                                            name: 'idCidade',
                                            loadOptions: loadCidadesOptions,
                                            getOptionLabel: (option) => `${option['nome']}${option['estado'] ? ' - ' + option['estado']['uf'] : ''}`,
                                            getOptionValue: (option) => {
                                                return option.idCidade;
                                            },
                                            loadingMessage: ()=>"Carregando...",
                                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                                            value: FormikProps.values.idCidade ? {
                                                idCidade: FormikProps.values.idCidade,
                                                nome: FormikProps.values.nomeCidade,
                                                estado: {
                                                    idEstado: FormikProps.values.idEstado,
                                                    nome: FormikProps.values.nomeEstado,
                                                    uf: FormikProps.values.uf,
                                                }
                                            } : null,
                                            onChange: (value) => {
                                                FormikProps.setFieldValue("idCidade", value.idCidade);
                                                FormikProps.setFieldValue("nomeCidade", value.nome);
                                                FormikProps.setFieldValue("idEstado", value.estado.idEstado);
                                                FormikProps.setFieldValue("nomeEstado", value.estado.nome);
                                                FormikProps.setFieldValue("uf", value.estado.uf);
                                            }
                                        }} form={FormikProps} async={true} label="Cidade" styles={{backgroundColor:'black'}}/>
                                        </Col>
                                        <Col xs="12">
                                            <FormInput field={{
                                                value: FormikProps.values.mensagem,
                                                name: 'mensagem',
                                                tag: 'textarea',
                                                rows: 5
                                            }} form={FormikProps} label="Mensagem *"
                                            />
                                        </Col>                                       
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs="12" className="text-center">
                                            <SecondaryButton disabled={FormikProps.isSubmitting} type="submit">Enviar <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                                        </Col>
                                    </Row>
                                </CustomForm>
                            )}
                        />
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default FormContato;
