import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withRouter } from 'react-router-dom';

import { Container, Row, Col } from 'reactstrap';
import { BoxVaga } from 'components/BoxVagas';
import { BoxLogos } from 'components/BoxLogos';
import VagasBreadCrumb from 'components/BreadCrumbs';

import { defaultFetchList } from 'actions/default';
import CarrosselClientes from 'components/CarrosselClientes';

class Vagas extends React.Component {

    constructor(props) {
        super(props);

        this.ponteiroPatrocinador = -1;
        this.patrocinadores = [];

        this.state = {
            hasMore: false,
            vagas: [],
            vazio: false,
        };
        window.scrollTo(0, 0)
    }

    loadData = (pg = 1) =>{
        let pesquisa = this.props.match.params.pesquisa;

        let reqData = {
            s: pesquisa ? pesquisa : '',
            pgSize: 10,
            pg: pg
        };
        var promise = new Promise((res, rej) => {
            if(this.props.match.params.setor)
                defaultFetchList('vagas/setores/'+this.props.match.params.setor, reqData, res, rej);
            else if(this.props.match.params.categoria)
                defaultFetchList('vagas/categorias/'+this.props.match.params.categoria, reqData, res, rej);
            else
                defaultFetchList('vagas', reqData, res, rej);
        });

        promise.then((data) => {
            if(data.total){
                let hasMore = true;
                if(data.paginacao.paginas <= pg){
                    hasMore = false;
                }
                let vagas = this.state.vagas;
                vagas = vagas.concat(data.results);
                this.setState({
                    vazio : false,
                    hasMore,
                    vagas: vagas,
                });
            }else{
                this.setState({
                    vazio : true,
                    hasMore: false,     
                });
            }
        });
    }

    componentDidMount() {

        var promise = new Promise((res, rej) => {
            defaultFetchList('clientes/patrocinadores/logos', {}, res, rej);
        });

		promise.then((data) => {
            this.patrocinadores = data.results            
            if(this.patrocinadores.length)
                this.shuffle(this.patrocinadores);
            this.loadData();
		});


    }

    shuffle(array) {
        array.sort(() => Math.random() - 0.5);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.match.params.setor !== this.props.match.params.setor || prevProps.match.params.categoria !== this.props.match.params.categoria || prevProps.match.params.pesquisa !== this.props.match.params.pesquisa){
            this.setState({
                vagas:[]
            })
            this.loadData();
        }
    }

    render() {
        let vagas = '';
        if(!this.state.vazio){
            var patrocinadores = [];
            vagas = this.state.vagas.map((vaga, idx) => {
                patrocinadores = [];
                if(this.patrocinadores.length && idx > 0 && idx % 5 === 0){
                    for(var i = 0; i<3 ;i++){
                        if(!this.patrocinadores[this.ponteiroPatrocinador+1])                        
                            this.ponteiroPatrocinador = -1;
                        patrocinadores.push(this.patrocinadores[++this.ponteiroPatrocinador]);
                    }                 
                }
                return (
                    <div key={idx}>
                        {patrocinadores.length ? (<BoxLogos patrocinadores={patrocinadores}/>) : ('')}
                        <BoxVaga vaga={vaga}/>
                    </div>
                );
            });
        }else{
            vagas = <Row><Col className="text-center h2 py-5">Não encontramos vagas com esta busca...</Col></Row>;
        }

        return (
            <div>
                <Container fluid={true} className="border-bottom">
                    <Container>
                        <Row className="my-4">
                            <Col xs="12">
                                <VagasBreadCrumb/>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <CarrosselClientes/>
                <Container fluid={true}>
                    <Container>
                        <Row>
                            <Col sm="12" className="p-0">
                                <InfiniteScroll
                                    pageStart={1}
                                    loadMore={this.loadData}
                                    hasMore={this.state.hasMore}
                                    useWindow={true}
                                    initialLoad={false}
                                    loader={<Row className="loader py-4" key={0}><Col className="text-center my-4">Carregando mais vagas...</Col></Row>}
                                >
                                    {vagas}
                                </InfiniteScroll>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        );
    };
}

export default withRouter(Vagas);
