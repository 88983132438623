import styled from 'styled-components';

import { PRIMARY_COLOR } from 'helpers/consts';
import { SECONDARY_COLOR } from 'helpers/consts';

import { Link } from 'react-router-dom';

export const Titulo = styled.p`
    color:${PRIMARY_COLOR}
    border-bottom: 3px solid ${PRIMARY_COLOR}
`
export const Setor = styled.div`
    background-color:${SECONDARY_COLOR}
    float:left
    margin-right: 15px
    padding:5px 15px
`
export const BoxSetorData = styled.div`
    position: absolute
    left:0px;
`
export const LinkVaga = styled(Link)`
    color: inherit
    &:hover{
        text-decoration:none;
    }
`