import React from "react";
import { withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

// Constantes
import { JWT_TOKEN } from 'helpers/consts';

// Componentes
import Banner from 'components/Banners/CadastroEmpresa';
import Form from './form';

//Analytics
import ReactGA from 'react-ga';

class CadastroEmpresa extends React.Component {
    componentDidMount() {
        ReactGA.pageview(this.constructor.name);

        let token = localStorage.getItem(JWT_TOKEN);
        if(token && jwt_decode(token).platform === 'empresa'){
            this.props.history.push(`${process.env.PUBLIC_URL}/empresa`)
        } else if(token && jwt_decode(token).platform === 'candidato') {
            this.props.history.push(`${process.env.PUBLIC_URL}/minha-conta`)
        }
    }

	render() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(token)
            return null;
		return (
			<div>
				<Banner/>
				<Form/>
			</div>
		);
	}
}

export default withRouter(CadastroEmpresa);
