import axios from 'axios'

export const loadOptions = inputValue => {
    return new Promise((resolve, reject) => {
        axios.get('paises?value='+inputValue)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            let erro = "Não foi possível obter os países"
            if(err.response && err.response.data){
                erro = err.response.data
            }
            reject(erro)
        })
    })
}
