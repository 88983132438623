import styled from 'styled-components';
import { CarouselIndicators } from 'reactstrap';
import { PRIMARY_COLOR } from 'helpers/consts';

export const CircleCarouselIndicators = styled(CarouselIndicators)`
    bottom: -50px
    & li {
        width: 19px
        height: 0px
        border-radius: 50%
        background: #cbcbca
    }
    & li.active {
        background: ${PRIMARY_COLOR}
    }
`
