import axios from 'axios';
import { defaultFetchList, defaultDelete, defaultSave, defaultFetch, defaultChangeStatus } from './default';

const model = "vagas";
const idField = "idVaga";

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model, reqData, resolve, reject, dispatch)
export const deleteReg = (id, afterDelete, afterError) => defaultDelete(model, id, afterDelete, afterError)
export const save = (data, actions, afterSave, afterError) => defaultSave(model, idField, data, false, actions, afterSave, afterError)
export const fetch = (id, callback) => defaultFetch(model, id, callback)
export const changeStatus = (id, status, afterChange, afterError, extraData) => defaultChangeStatus(model, id, status, afterChange, afterError, extraData)

export const getCandidatos = (id, resolve, reject) => {
    axios.get(`${model}/candidatos/${btoa(id)}`)
    .then(res => {
        resolve(res.data)
    })
    .catch(err => {
        let erro = {message: "Não foi possível obter os dados"}
        if(err.response){
            erro = err.response.data
        }
        reject(erro.message)
    })
}