import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Main from './Main';
import Vagas from './Vagas';
import FormEmpresa from './FormEmpresa';
import FormVaga from './FormVaga';

class Content extends React.Component {
    render() {
        return (
            <Switch>
                <Route path={`${process.env.PUBLIC_URL}/empresa/vagas/new`} component={FormVaga}/>
                <Route path={`${process.env.PUBLIC_URL}/empresa/vagas/edit/:id?/:action?/:idx?`} component={FormVaga}/>
                <Route path={`${process.env.PUBLIC_URL}/empresa/vagas`} component={Vagas}/>
                <Route path={`${process.env.PUBLIC_URL}/empresa/edit`} component={FormEmpresa}/>
                <Route path={`${process.env.PUBLIC_URL}/empresa`} component={Main}/>
            </Switch>
        );
    }
};

export default (Content);
