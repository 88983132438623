import React from "react";
import { withRouter } from 'react-router-dom';

// Componentes
import Banner from 'components/Banners/SuaContaCandidato';
import Main from 'components/SuaContaCandidato';

//Analytics
import ReactGA from 'react-ga';

class Candidato extends React.Component {
	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}

	render() {
		return (
			<div>
				<Banner/>
                <Main/>
			</div>
		);
	}
}

export default withRouter(Candidato);
