import axios from 'axios';

// Types 
import types from "types/auth";

import setAuthToken from 'helpers/setAuthToken';
import { JWT_TOKEN } from "helpers/consts";

export const loginEmpresa = (user, afterError) => {
    axios.post('auth/empresa', user, {headers: {'Accept': 'application/json'}})
    .then(res => {
        const { data } = res.data;
        localStorage.setItem(JWT_TOKEN, data);
        setAuthToken(data);
        window.location.href = `${process.env.PUBLIC_URL}/empresa`;
        //const decoded = jwt_decode(data);
        //refreshToken();
        //dispatch(setCurrentUser(decoded));
    }).catch(err => {
        if(afterError){
            afterError(err.response.data)
        }
    });
}

export const loginUser = (user, afterDone=()=>{
    window.location.href = `${process.env.PUBLIC_URL}/minha-conta`;
}, afterError) => {
    axios.post('auth/candidato', user, {headers: {'Accept': 'application/json'}})
    .then(res => {
        const { data } = res.data;
        localStorage.setItem(JWT_TOKEN, data);
        setAuthToken(data);
        afterDone();
    })
    .catch(err => {
        if(afterError){
            afterError(err.response.data)
        }
    });
}

export const loginUserFacebook = (fbkId, afterError) => {
    axios.post(`auth/candidato/facebook/${btoa(fbkId)}`, {headers: {'Accept': 'application/json'}})
    .then(res => {
        const { data } = res.data;
        localStorage.setItem(JWT_TOKEN, data);
        setAuthToken(data);
        window.location.href = `${process.env.PUBLIC_URL}/minha-conta`;
    })
    .catch(err => {
        if(afterError){
            afterError(err.response.data)
        }
    });
}

export const setCurrentUser = decoded => {
    return {
        type: types.SET_CURRENT_USER,
        payload: decoded
    }
}

export const logoutUser = () => {
    localStorage.removeItem(JWT_TOKEN);
    setAuthToken(false);

    window.location.href = `${process.env.PUBLIC_URL}/login`;
}