import React from "react";

import { Container, Col } from 'reactstrap';
import { GridRow } from './styles';

import { defaultFetchList } from 'actions/default';

import BannerPatrocinadores from 'components/Banners/Patrocinadores'
import Cabecalho from "components/Cabecalhos";

import Image from "react-graceful-image";

//Analytics
import ReactGA from 'react-ga';

class Patrocinadores extends React.Component {

    constructor(props) {
		super(props);

		this.state = {
            patrocinadores: [<div key="0" className="text-center">Carregando...</div>],
		};
    }

    componentDidMount(){
        ReactGA.pageview(this.constructor.name);

        let reqData = {
            s: '',
            pg: 0,            
        };
        var promise = new Promise((res, rej) => {
            defaultFetchList('clientes/patrocinadores/logos', reqData, res, rej);
        });
        
        promise.then((data) => {
            if(data.results.length){
                this.setState({
                    patrocinadores: data.results
                });
            }
        });
    }



	render() {
        const patrocinadoresDisplay = this.state.patrocinadores.map((patrocinador,idx) => {
            return (
                <Col xs="6" md="3" className="py-3 text-center border-bottom border-right" key={idx}>
                    {/* nao sei pq não funcionou em uma variavel */}
                    {patrocinador.link ? (
                        <a href={'http://'+patrocinador.link} rel="noopener noreferrer" title={patrocinador.nome} target="_blank">
                            <Image src={patrocinador.logo} alt={patrocinador.nome} className="img-fluid"/>
                        </a>
                    ) : (
                        <Image src={patrocinador.logo} alt={patrocinador.nome} className="img-fluid"/>
                    )}
                </Col>
            );
        });        
		return (
			<div>
   				<Cabecalho title='patrocinadores' description='description patrocinadores' keywords='keys patrocinadores'/>
				<BannerPatrocinadores/>
                <Container>
                    <GridRow className="py-5 position-relative">
                        {patrocinadoresDisplay}
                    </GridRow>
                </Container>
			</div>
		);
	}
}

export default Patrocinadores;
