import React from 'react';

import { withRouter, Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import VagasBreadCrumb from 'components/BreadCrumbs';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Setor, Titulo } from 'components/BoxVagas/styles';
import { PrimaryButton, SecondaryButton, FourthButton } from 'components/Buttons/styles';
import { InfoBox } from './styles';
import { SECONDARY_COLOR } from 'helpers/consts';

import iconEmpresaBlue from 'assets/img/iconEmpresaBlue.png';
import iconCandidatoBlue from 'assets/img/iconCandidatoBlue.png';
import iconCurriculoBlue from 'assets/img/iconCurriculoBlue.png';
import iconFace from 'assets/img/iconFace.png';
import iconWhats from 'assets/img/iconWhats.png';
import iconLinkedin from 'assets/img/iconLinkedin.png';
import iconEmail from 'assets/img/iconEmail.png';
import jwt_decode from 'jwt-decode';
import { JWT_TOKEN, PRIMARY_COLOR } from 'helpers/consts';

//share
import { FacebookShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import Cabecalho from 'components/Cabecalhos';

//Analytics
import ReactGA from 'react-ga';

const MySwal = withReactContent(Swal);

class VagasView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            vaga: {},
        };
    }
    loadData = (vg) => {
        axios.get(`vagas/${btoa(vg)}`)
            .then(res => {
                this.setState({
                    vaga: res.data.result
                });
            })
            .catch(err => {
                if (err.response) {
                    MySwal.fire({
                        type: 'error',
                        title: "Não foi possível obter os dados. " + err.response.data.message,
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 3000,
                    })
                }
            });
    }

    componentDidMount() {
        if (this.props.match.params.vaga)
            this.loadData(this.props.match.params.vaga);
        else
            this.props.history.back()

        if(localStorage.getItem('candidatar'))
            this.candidatarSe(localStorage.getItem('candidatar'));
    }

    changeVaga(vg) {
        this.setState({
            vaga: {}
        });
        this.loadData(vg);
    }

    candidatarSe(vg) {
        let token = localStorage.getItem(JWT_TOKEN);
        if (token)
            token = jwt_decode(token);
        if (token && token.platform === 'candidato') {
            MySwal.fire({
                title: '',
                type: 'info',
                html: 'Deseja candidatar-se a esta vaga?',
                showCancelButton: true,
                confirmButtonText: <PrimaryButton tag="span" size="lg" color="danger" title="Sim">Sim</PrimaryButton>,
                cancelButtonText: <SecondaryButton tag="span" size="lg" color="white" title="Cancelar">Cancelar</SecondaryButton>,
                reverseButtons: true
            }).then((result) => {
                localStorage.removeItem('candidatar');
                if (result.value) {
                    axios.post(`candidatos/me-inscrever/${btoa(vg)}`)
                    .then(res => {
                        ReactGA.event({
                            category: 'Candidato',
                            action: 'Currículo enviado, vaga: '+vg
                        });
                        MySwal.fire({
                            type: 'success',
                            title: "Currículo enviado!",
                            toast: true,
                            position: 'center',
                            showConfirmButton: false,
                            timer: 3000,
                        })
                    })
                    .catch(err => {
                        if (err.response) {
                            MySwal.fire({
                                type: 'error',
                                title: err.response.data.message,
                                toast: true,
                                position: 'center',
                                showConfirmButton: false,
                            })
                        }
                    })
                }
            });
        } else {
            localStorage.setItem('candidatar',vg);
            this.props.history.push(`${process.env.PUBLIC_URL}/login`);
            return;
        }
    }

    render() {
        const vaga = this.state.vaga

        return (
            <div>
   				<Cabecalho title={vaga.nomeCargo} description={vaga.resumo} keywords='keys capacita'/>
                <Container fluid={true} className="border-bottom">
                    <Container>
                        <Row className="my-4">
                            <Col xs="12">
                                {vaga.nomeCargo ? (
                                    <VagasBreadCrumb nomeCargo={vaga.nomeCargo} />
                                ) : (
                                        <p>Carregando...</p>
                                    )}
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid={true} className="border-bottom">
                    <Container>
                        {vaga.idVaga ? (
                            <>
                                <Row className="my-4">
                                    <Col xs="12">
                                        <Setor>{vaga.descricaoSetor}</Setor>
                                        <p className="float-left mt-1">{vaga.data}</p>
                                    </Col>
                                    <Col xs="12">
                                        <Titulo className="h4 my-4 font-weight-bold text-uppercase">{vaga.necEspecial === 1 && <FontAwesomeIcon icon="wheelchair" className="mr-2 mb-1 fa-lg" style={{color:'#007FFF'}}/>}{vaga.nomeCargo}</Titulo>
                                    </Col>
                                    <Col xs="12" md="6">
                                        {vaga.ocultaInfo !== 1 &&
                                            <>
                                                <p className="text-muted m-0"><b>Empresa: </b>{vaga.nomeEmpresa}</p>
                                                <p className="text-muted m-0"><b>Telefone: </b>{vaga.telefone}</p>
                                                <p className="text-muted m-0"><b>E-mail: </b>{vaga.email}</p>
                                                <p className="text-muted m-0"><b>Cidade: </b>{vaga.nomeCidade + ' / ' + vaga.nomeEstado}</p>
                                            </>
                                        }
                                    </Col>
                                    <Col xs="12" md="6" className="text-center text-md-right">
                                        {vaga.status !== 2 ? (
                                            <SecondaryButton onClick={() => { this.candidatarSe(vaga.idVaga) }}>Candidatar-se <FontAwesomeIcon icon="arrow-right" /></SecondaryButton>
                                        ) : (
                                            <SecondaryButton disabled={true}>Vaga Preenchida <FontAwesomeIcon icon="clipboard-check" /></SecondaryButton>
                                        )}
                                    </Col>
                                </Row>
                                <Row className="my-4 justify-content-between">
                                    <Col xs="12" md="auto" className="py-3">
                                        <InfoBox>
                                            <img src={iconEmpresaBlue} title="Setor" alt="Setor" className="d-inline-block mx-2 img-fluid" />
                                            <p className="text-white font-weight-bold d-inline-block mx-2 my-3">Setor</p>
                                            <p className="font-weight-bold d-inline-block mx-2 text-uppercase mb-0" style={{ color: SECONDARY_COLOR }}>{vaga.descricaoSetor}</p>
                                        </InfoBox>
                                    </Col>
                                    <Col xs="12" md="auto" className="py-3">
                                        <InfoBox>
                                            <img src={iconCandidatoBlue} title="Area" alt="Area" className="d-inline-block mx-2 img-fluid" />
                                            <p className="text-white font-weight-bold d-inline-block mx-2 my-3">Área</p>
                                            <p className="font-weight-bold d-inline-block mx-2 text-uppercase mb-0" style={{ color: SECONDARY_COLOR }}>{vaga.descricaoArea}</p>
                                        </InfoBox>
                                    </Col>
                                    <Col xs="12" md="auto" className="py-3">
                                        <InfoBox>
                                            <img src={iconCurriculoBlue} title="Categoria" alt="Categoria" className="d-inline-block mx-2 img-fluid" />
                                            <p className="text-white font-weight-bold d-inline-block mx-2 my-3">Categoria</p>
                                            <p className="font-weight-bold d-inline-block mx-2 text-uppercase mb-0" style={{ color: SECONDARY_COLOR }}>{vaga.descricaoCategoria}</p>
                                        </InfoBox>
                                    </Col>
                                </Row>
                                <Row className="my-4">
                                    <Col xs="12">
                                        <p dangerouslySetInnerHTML={{ __html: vaga.descricao }}></p>
                                    </Col>
                                </Row>
                                <Row className="my-4 py-4 border-top border-bottom">
                                    <Col xs="12" className="text-center">
                                        <p className="d-inline-block font-weight-bold mr-3">COMPARTILHE</p>
                                        <FacebookShareButton url={`${window.location.origin}${this.props.location.pathname}`} className="d-inline-block mx-1">
                                            <img src={iconFace} title="Compartilhar via Facebook" alt="Compartilhar via Facebook" />
                                        </FacebookShareButton>
                                        <WhatsappShareButton url={`${window.location.origin}${this.props.location.pathname}`} className="d-inline-block mx-1">
                                            <img src={iconWhats} title="Compartilhar via Whatsapp" alt="Compartilhar via Whatsapp" />
                                        </WhatsappShareButton>
                                        <LinkedinShareButton url={`${window.location.origin}${this.props.location.pathname}`} className="d-inline-block mx-1">
                                            <img src={iconLinkedin} title="Compartilhar via Linkedin" alt="Compartilhar via Linkedin" />
                                        </LinkedinShareButton>
                                        <EmailShareButton url={`${window.location.origin}${this.props.location.pathname}`} className="d-inline-block mx-1">
                                            <img src={iconEmail} title="Compartilhar via E-mail" alt="Compartilhar via E-mail" />
                                        </EmailShareButton>
                                    </Col>
                                </Row>
                                <Row className="my-4 py-4">
                                    <Col xs="12" md="6">
                                        <p style={{ color: PRIMARY_COLOR }}><FontAwesomeIcon icon="list"></FontAwesomeIcon> IR PARA A LISTAGEM</p>
                                    </Col>
                                    <Col xs="12" md="6" className="text-center text-md-right">
                                        {vaga.prev &&
                                            <FourthButton className="mx-2" tag={Link} to={`${process.env.PUBLIC_URL}/vaga/${vaga.prev}`} onClick={() => { window.scrollTo(0, 0);this.changeVaga(vaga.prev) }}><FontAwesomeIcon icon="arrow-left"></FontAwesomeIcon> ANTERIOR</FourthButton>
                                        }
                                        {vaga.next &&
                                            <FourthButton tag={Link} to={`${process.env.PUBLIC_URL}/vaga/${vaga.next}`} onClick={() => { window.scrollTo(0, 0);this.changeVaga(vaga.next) }}><FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon> PRÓXIMA</FourthButton>
                                        }
                                    </Col>
                                </Row>
                            </>
                        ) : (
                                <Row className="my-4 py-4">
                                    <Col xs="12" className="my-5 text-center">
                                        <p style={{ color: PRIMARY_COLOR }}>Carregando a vaga...</p>
                                    </Col>
                                </Row>
                            )}
                    </Container>
                </Container>
            </div>
        );
    };
}

export default withRouter(VagasView);
