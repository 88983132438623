import React from 'react';
import { Col, Row } from 'reactstrap';

import { deleteReg } from 'actions/vagas';
import { TituloRow, Titulo, EditBtn, DeleteBtn, Situacao, QuantidadeCurriculos } from './styles';

export default ({vaga, afterDelete}) => {
    return (
        <Col xs="12" className="my-2 mb-4">
            <TituloRow>
                <Titulo>{vaga.nome}</Titulo>
                <Col xs="12" md="auto pr-0">
                    <EditBtn id={vaga.idVaga}/>
                    <DeleteBtn id={vaga.idVaga} curriculos={vaga.curriculos} onClick={()=>{
                        deleteReg(vaga.idVaga, afterDelete);
                    }}/>
                </Col>
            </TituloRow>
            <Row>
                <Situacao status={vaga.status}/>
                <QuantidadeCurriculos>{vaga.curriculos} CURRÍCULOS</QuantidadeCurriculos>
            </Row>
        </Col>
    );
};