import React from 'react';

import { Container,Row,Col } from 'reactstrap';
import { BoxVagaResumida } from 'components/BoxVagas';
import { BackGround } from 'components/Banners/styles';
import backgroundVagasRecentes from 'assets/img/bkgVagasRecentes.jpg';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'helpers/consts';
import { SecondaryButton } from 'components/Buttons';

import { NavLink } from 'react-router-dom';

import { defaultFetchList } from 'actions/default';

//font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class VagasRecentes extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            vagas: [<Col key="1" className="text-center">Carregando...</Col>],
        };
    }

    componentDidMount(){
        let reqData = {
            s: '',
            pg: 1,
            pgSize: 4
        };
        var promise = new Promise((res, rej) => {
            defaultFetchList('vagas', reqData, res, rej);
        });
        
        promise.then((data) => {
            let result = null;
            if(data.results.length){
                result = data.results.map((vaga,idx) => {
                    return (
                        <BoxVagaResumida key={idx} vaga={vaga}/>
                    );
                });
            }
            
            this.setState({
                vagas: result
            });
        });
    }

    render() {
        return (
            <BackGround source={backgroundVagasRecentes} className="pb-5">
                <Container fluid={true}>
                    <Container>
                        <Row className="py-5">
                            <Col xs="12">
                                <p className="h1 m-0" style={{color: SECONDARY_COLOR}}>VAGAS RECENTES</p>
                                <p className="h4 text-white font-weight-light">Confira as últimas vagas cadastradas e candidate-se!</p>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.vagas}
                            <Col xs="12" className="text-center py-3">
                                <SecondaryButton tag={NavLink} to={`${process.env.PUBLIC_URL}/vagas`}>VER TODAS <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (VagasRecentes);
