import React from 'react';
import styled from 'styled-components';
import { Container,Row,Col } from 'reactstrap';

// Componentes
import LoginEmpresa from './Empresa';
import LoginCandidato from './Candidato';

// Ações / Métodos
import imageColorOverlay from 'helpers/imageColorOverlay';

// Constantes
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'helpers/consts';

// Imagens
import iconCandidato from 'assets/img/iconCandidato.png';
import iconEmpresa from 'assets/img/iconEmpresaBlack.png';
import bkg from 'assets/img/bkgVagasRecentes.jpg';

// Estilos
import { BackGround } from 'components/Banners/styles';
const H1 = styled.p`
    font-size: 60px  
`

class Login extends React.Component {
    constructor() {
        super();

        this.state = { 
            type: 'candidato'
        };
    }

    render() {
        return (
            <BackGround source={bkg} className="pb-5">
                <Container fluid>
                    <Container>
                        <Row className="py-5">
                            <Col xs="12" lg="6" className="text-center text-lg-left my-5 py-5">
                                <H1 className="h1 m-0 font-weight-bold" style={{color: SECONDARY_COLOR}}>Faça<br/>login ou</H1>
                                <H1 className="h1 text-white font-weight-light">cadastre-se</H1>
                                <p className="text-white font-weight-light pt-4">Faça login ou cadastre-se para registrar seu currículo e se inscrever para as vagas ou para divulgar as vagas de sua empresa.</p>
                            </Col>
                            <Col xs="12" lg="6" className="text-center text-lg-left my-5 py-5">
                                <Row>
                                    <Col xs="12" md="auto pr-0">
                                        <div onClick={()=>{
                                            this.setState({type: 'candidato'});
                                        }} className="d-flex align-items-center justify-content-center flex-column py-2 px-4 mt-4 font-weight-normal" style={{
                                            color: this.state.type === 'candidato' ? PRIMARY_COLOR : 'white',
                                            lineHeight: 1,
                                            borderTopLeftRadius: '0.25rem',
                                            height: '110px',
                                            backgroundColor: this.state.type === 'candidato' ? 'white' : 'rgba(255,255,255,0.4)',
                                            transition: 'all 0.5s ease'
                                        }}>
                                            <img src={iconCandidato} alt="Para Você" className="pb-1" style={{
                                                height: '40px',
                                                filter: this.state.type === 'candidato' ? imageColorOverlay(PRIMARY_COLOR) : imageColorOverlay()
                                            }}/>
                                            PARA<br/>VOCÊ
                                        </div>

                                        <div onClick={()=>{
                                            this.setState({type: 'empresa'});
                                        }} className="d-flex align-items-center justify-content-center flex-column py-2 px-4 mb-5 font-weight-normal" style={{
                                            color: this.state.type === 'empresa' ? PRIMARY_COLOR : 'white',
                                            lineHeight: 1,
                                            borderBottomLeftRadius: '0.25rem',
                                            height: '110px',
                                            backgroundColor: this.state.type === 'empresa' ? 'white' : 'rgba(255,255,255,0.4)',
                                            transition: 'all 0.5s ease'
                                        }}>
                                            <img src={iconEmpresa} alt="Para Sua Empresa" className="pb-1" style={{
                                                height: '35px',
                                                filter: this.state.type === 'empresa' ? imageColorOverlay(PRIMARY_COLOR) : imageColorOverlay()
                                            }}/>
                                            PARA SUA<br/>EMPRESA
                                        </div>
                                    </Col>
                                    <Col className="rounded px-0" style={{backgroundColor: 'white'}}>
                                        {this.state.type === 'candidato' &&
                                            <LoginCandidato/>
                                        }
                                        {this.state.type === 'empresa' &&
                                            <LoginEmpresa/>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (Login);
