import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'reactstrap';
import jwt_decode from 'jwt-decode';

// Consts
import { PRIMARY_COLOR, JWT_TOKEN } from 'helpers/consts';

import { loadOptions as loadEmpresasOptions, save as saveEmpresa } from 'actions/empresas';
import { loadOptions as loadCargosOptions } from 'actions/cargos';
import { saveProfissional as save, fetch } from 'actions/candidatos';

// Componentes
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import { SecondaryButton, PrimaryButton } from 'components/Buttons';
import DateInput from 'components/FormInput/Date';
import Checkbox from 'components/Checkbox';

// Validação
import validationSchema from './validation';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FormProfissional extends React.Component {
    constructor() {
        super();

        this._initialValues = {
            primeiroEmprego: 0,
            comentarios: '',
            conhecimentos: '',
            experiencia: []
        };     
        this._isMounted = false;
        this._maxExperiencia = 3;

        for(let i = 0; i<this._maxExperiencia;i++){
            this._initialValues.experiencia.push({
                idCargo: null,
                nomeCargo: '',
                dataFinal: '',
                dataInicial: '',
                idEmpresa: null,
                nomeEmpresa: ''
            });
        }

        this.state = {
            maxExperiencia: this._maxExperiencia,
            experiencia: 1,
            loading: false,
            model: this._initialValues
        }
    }

    removeExperiencia = () => {
        let model = this.state.model;
        let experiencia = model.experiencia;
        let lenExp = this.state.experiencia;
        experiencia[lenExp-1] = {
            idCandidatoExperiencia: null,
            idCargo: null,
            nomeCargo: '',
            dataFinal: '',
            dataInicial: '',
            idEmpresa: null,
            nomeEmpresa: ''
        };
        model.experiencia = experiencia;
        this.setState({model, experiencia: (lenExp-1)});
    }


    componentDidMount(){
        this._isMounted = true;
        this.loadCandidato();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    dateToMySQL = (date) => {
        if(!/\//.test(date)) return date;
        date = date.split('/');
        return date[2] + '-' + date[1] + '-' + date[0];
    }

    mysqlToDate = (date) => {
        if(!/-/.test(date)) return date;
        date = date.split(' ')[0].split('-');
        return date[2] + '/' + date[1] + '/' + date[0]
    }

    loadCandidato = () => {
        this.setState({
            loading: true
        })
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return;
        let decript = jwt_decode(token);
        let id = decript.sub;

        fetch(id, (data)=>{
            let obj = data.result;
            obj.experiencia = [];

            let qtdExperiencias = this.state.experiencia;
            let model = this.state.model;
            model.primeiroEmprego = obj.primeiroEmprego;
            model.comentarios = obj.comentarios;
            model.conhecimentos = obj.conhecimentos;

            if(obj.experiencias && obj.experiencias.length){
                qtdExperiencias = obj.experiencias.length;
                for(let i=0; i<obj.experiencias.length; i++){
                    model.experiencia[i] = {
                        idCandidatoExperiencia: obj.experiencias[i].idCandidatoExperiencia,
                        idCargo: obj.experiencias[i].idCargo,
                        nomeCargo: obj.experiencias[i].nomeCargo,
                        dataInicial: this.mysqlToDate(obj.experiencias[i].dataInicial.date),
                        dataFinal: this.mysqlToDate(obj.experiencias[i].dataFinal ? obj.experiencias[i].dataFinal.date : ''),
                        idEmpresa: obj.experiencias[i].idEmpresa,
                        nomeEmpresa: obj.experiencias[i].nomeEmpresa
                    };
                }
            }

            this.setState({
                model,
                loading: false,
                experiencia: qtdExperiencias
            });
        })
    }

    isEdit = () => {
        return /minha-conta\/edit/.test(window.location.href);
    }

    handleSubmit = (values, actions) => {
        let sendData = {
            primeiroEmprego: values.primeiroEmprego,
            comentarios: values.comentarios,
            conhecimentos: values.conhecimentos,
            experiencia: []
        };

        for(let i=0; i < values.experiencia.length; i++){  
            let experiencia = values.experiencia[i];
            if(experiencia.idCargo && experiencia.idEmpresa && experiencia.dataInicial !== ''){
                sendData.experiencia.push({
                    atualmente: experiencia.dataFinal === '',
                    cargo: experiencia.idCargo,
                    dataFinal: this.dateToMySQL(experiencia.dataFinal),
                    dataInicial: this.dateToMySQL(experiencia.dataInicial),
                    empresa: experiencia.idEmpresa
                });
            }
        }

        let token = localStorage.getItem(JWT_TOKEN);
        if(!token) return;
        let decript = jwt_decode(token);
        sendData.idCandidato = decript.sub;

        save(sendData, actions, ()=>{
            if(!this.isEdit()){
                if(localStorage.getItem('candidatar'))
                    window.location.href = `${process.env.PUBLIC_URL}/vaga/`+localStorage.getItem('candidatar');
                else
                    window.location.href = `${process.env.PUBLIC_URL}/minha-conta`;
            }
        });
    }

	render() {
        if(this.state.loading && this.isEdit()){
            return <p className="py-5 text-center">Carregando...</p>
        }

        const boxExperiencia = (FormikProps) => {
            let ret = [];
            for(let idx = 0; idx < this.state.experiencia; idx++){
                ret.push(<Row key={idx} className={"mb-3"}>
                    <Col xs="12" lg="6">
                        <FormSelect field={{
                            placeholder: "Digite para localizar...",
                            isSearchable: true,
                            name: `experiencia[${idx}].idEmpresa`,
                            loadOptions: loadEmpresasOptions,
                            getOptionLabel: (option) => `${option['nome']}`,
                            getOptionValue: (option) => {
                                return option.idEmpresa;
                            },
                            loadingMessage: ()=>"Carregando...",
                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : <div>Nenhuma empresa já criado com esse nome, digite o nome da empresa e pressione o botão abaixo. <br/>
                            <PrimaryButton type="button" color="success" onClick={()=>{
                                let nome = document.querySelector('input[name="experiencia['+idx+'].idEmpresa"]').parentElement.querySelector('input').value.toUpperCase();
                                if(nome && nome !== ''){
                                    saveEmpresa({
                                        nome,
                                        status: -1,
                                        type: 'candidato'
                                    }, undefined, (res)=>{
                                        let idEmpresa = res.data.id;
                                        let nomeEmpresa = nome;
                                        let experiencia = FormikProps.values.experiencia;
                                        let obj = experiencia[idx];
        
                                        obj.idEmpresa = idEmpresa;
                                        obj.nomeEmpresa = nomeEmpresa;
        
                                        experiencia[idx] = obj;
                                        FormikProps.setFieldValue("experiencia", experiencia);

                                        if ("activeElement" in document)
                                            document.activeElement.blur();
                                    });
                                }
                            }}>Criar</PrimaryButton></div>,
                            value: FormikProps.values.experiencia[idx].idEmpresa ? {
                                idEmpresa: FormikProps.values.experiencia[idx].idEmpresa,
                                nome: FormikProps.values.experiencia[idx].nomeEmpresa,
                            } : null,
                            onChange: (value) => {
                                let idEmpresa = value.idEmpresa;
                                let nomeEmpresa = value.nome;
                                let experiencia = FormikProps.values.experiencia;
                                let obj = experiencia[idx];

                                obj.idEmpresa = idEmpresa;
                                obj.nomeEmpresa = nomeEmpresa;

                                experiencia[idx] = obj;
                                FormikProps.setFieldValue("experiencia", experiencia);
                            }
                        }} form={FormikProps} async={true} label="Empresa"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormSelect field={{
                            placeholder: "Digite para localizar...",
                            isSearchable: true,
                            name: `experiencia[${idx}].idCargo`,
                            loadOptions: loadCargosOptions,
                            getOptionLabel: (option) => `${option['nome']}`,
                            getOptionValue: (option) => {
                                return option.idCargo;
                            },
                            loadingMessage: ()=>"Carregando...",
                            noOptionsMessage: (input)=>input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado",
                            value: FormikProps.values.experiencia[idx].idCargo ? {
                                idCargo: FormikProps.values.experiencia[idx].idCargo,
                                nome: FormikProps.values.experiencia[idx].nomeCargo,
                            } : null,
                            onChange: (value) => {
                                let idCargo = value.idCargo;
                                let nomeCargo = value.nome;
                                let experiencia = FormikProps.values.experiencia;
                                let obj = experiencia[idx];

                                obj.idCargo = idCargo;
                                obj.nomeCargo = nomeCargo;

                                experiencia[idx] = obj;

                                FormikProps.setFieldValue("experiencia", experiencia);
                            }
                        }} form={FormikProps} async={true} label="Cargo"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormInput field={{
                            name: `experiencia[${idx}].dataInicial`,
                            tag: DateInput,
                            onChange: (evt)=>{
                                let experiencia = FormikProps.values.experiencia;
                                let obj = experiencia[idx];

                                obj.dataInicial = evt.target.value;
                                experiencia[idx] = obj;

                                FormikProps.setFieldValue("experiencia", experiencia);
                            }
                        }} form={FormikProps} label="Período - Data Inicial"/>
                    </Col>
                    <Col xs="12" lg="6">
                        <FormInput field={{
                            name: `experiencia[${idx}].dataFinal`,
                            tag: DateInput,
                            placeholder: 'ATUALMENTE',
                            onChange: (evt)=>{
                                let experiencia = FormikProps.values.experiencia;
                                let obj = experiencia[idx];

                                obj.dataFinal = evt.target.value;
                                experiencia[idx] = obj;

                                FormikProps.setFieldValue("experiencia", experiencia);
                            }
                        }} form={FormikProps} label="Período - Data Final"/>
                    </Col>
                </Row>);
            }
            return ret;
        };

		return (
            <Container>
                <Row className={`${this.isEdit() ? '' : 'py-5'} d-flex justify-content-center`}>    
                    {this.isEdit() && 
                        <Col xs="12">
                            <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Edição Currículo | Profissional</h4>
                        </Col>
                    }
                    <Col xs="12" md={this.isEdit() ? "12" : "10"} lg={this.isEdit() ? "12" : "9"}>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this.state.model}
                            onSubmit={this.handleSubmit}
                            enableReinitialize={true}
                            render={(FormikProps) => (
                                <Form onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12" className="mb-3 mt-2 py-3 px-0 mx-2">
                                            <Checkbox onChange={(evt)=>{
                                                FormikProps.setFieldValue('primeiroEmprego', evt.target.checked ? 1 : 0)
                                            }} checked={FormikProps.values.primeiroEmprego === 1} inline type="checkbox" id="primeiroEmprego" name="primeiroEmprego" label="Primeiro emprego" />
                                        </Col>
                                        <Col xs="12">
                                            <h6 style={{color: PRIMARY_COLOR}}>EXPERIÊNCIA - {this.state.maxExperiencia} ÚLTIMOS EMPREGOS</h6>
                                        </Col>
                                        <Col xs="12">
                                            {boxExperiencia(FormikProps)}

                                            <Row>
                                            {this.state.experiencia < this.state.maxExperiencia &&
                                                <Col xs="12" sm="6" md="auto" className="d-flex align-items-end">
                                                    <PrimaryButton block size="sm" type="button" style={{color:'white', marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light" onClick={()=>{this.setState({experiencia: this.state.experiencia + 1})}}>ADICIONAR +</PrimaryButton>
                                                </Col>
                                            }
                                            {this.state.experiencia > 1 &&
                                                <Col xs="12" sm="6" md="auto" className="d-flex align-items-end">
                                                    <SecondaryButton block size="sm" type="button" style={{marginBottom: '1rem', height: 'calc(1.5em + 0.75rem + 4px)'}} className="py-0 font-weight-light" onClick={this.removeExperiencia}>REMOVER</SecondaryButton>
                                                </Col>
                                            }
                                            </Row>
                                        </Col>
                                        <Col xs="12" lg="12">
                                            <FormInput field={{
                                                name: 'conhecimentos',
                                                tag: 'textarea'
                                            }} form={FormikProps} label={<span>Conhecimentos <span className="small">Alguma observação sobre você?</span></span>}/>
                                        </Col>
                                        <Col xs="12" lg="12">
                                            <FormInput field={{
                                                name: 'comentarios',
                                                tag: 'textarea'
                                            }} form={FormikProps} label={<span>Comentários <span className="small">Deseja dizer mais algo?</span></span>}/>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs="12">
                                            {this.isEdit() && 
                                                <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Salvar <FontAwesomeIcon icon='check' color={PRIMARY_COLOR}/></SecondaryButton>
                                            }
                                            {!this.isEdit() && 
                                                <SecondaryButton disabled={FormikProps.isSubmitting} className="float-right" type="submit">Finalizar <FontAwesomeIcon icon='check' color={PRIMARY_COLOR}/></SecondaryButton>
                                            }
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default FormProfissional;
