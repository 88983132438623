import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { BackGround,H1 } from './styles';

import { SECONDARY_COLOR } from 'helpers/consts';
import imageColorOverlay from 'helpers/imageColorOverlay';

import bkg from 'assets/img/bkgCadastro.jpg';
import icon from 'assets/img/iconCandidato.png';

class BannerCadastroEmpresa extends React.Component {
    render() {
        return (
			<BackGround source={bkg}>
                <Container fluid={true}>
                    <Container>
                        <Row className="py-5 d-flex justify-content-center align-items-end">
                            <Col xs="12" md="10" lg="6" className="text-white mt-5">
                                <Row className="d-flex align-items-center">
                                    <Col xs="auto"><img src={icon} style={{filter:imageColorOverlay(SECONDARY_COLOR)}} alt="Candidato" className="img-fluid"/></Col>
                                    <Col className="h3 pl-0 m-0 font-weight-light" xs="auto">PARA<br/>VOCÊ:</Col>
                                    <Col><H1 className="m-0">cadastre-se</H1></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </BackGround>
        );
    }
};

export default (BannerCadastroEmpresa);
