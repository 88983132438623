import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'helpers/consts';

const Link = styled(NavLink)`
    color: ${PRIMARY_COLOR};
    border-bottom: 3px solid ${SECONDARY_COLOR};
    border-radius: 0;
    width: 100%;
    text-align: left;
    padding: 0;
    display: flex;
    font-weight: bold;
    margin-bottom: 13px;

    &:hover, &:focus, &:active, &.active{
        text-decoration: none;
        color: ${PRIMARY_COLOR};
        border-color: ${PRIMARY_COLOR};
    }
`

const CustomDiv = styled.div`
    color: ${PRIMARY_COLOR};
    border-bottom: 3px solid ${SECONDARY_COLOR};
    border-radius: 0;
    width: 100%;
    text-align: left;
    padding: 0;
    display: flex;
    font-weight: bold;
    margin-bottom: 13px;
`

const SubLink = styled(NavLink)`
    color: ${PRIMARY_COLOR};
    border-bottom: 1px solid #cccccc;
    border-radius: 0;
    width: 100%;
    text-align: left;
    padding: 0;
    display: flex;
    font-weight: light;
    margin-bottom: 11px;

    &:hover, &:focus, &:active, &.active{
        text-decoration: none;
        color: ${PRIMARY_COLOR};
        border-color: ${PRIMARY_COLOR};
    }
    &:last-child{
        border: none!important;
    }
`

class SuaContaCandidatoMenu extends React.Component {
    render() {
        return (
            <>
                <Link exact to={`${process.env.PUBLIC_URL}/minha-conta`}>PRINCIPAL</Link>
                <CustomDiv>CURRÍCULO</CustomDiv>
                <div className="d-flex flex-column">
                    <SubLink exact to={`${process.env.PUBLIC_URL}/minha-conta/edit/pessoal`}>PESSOAL</SubLink>
                    <SubLink exact to={`${process.env.PUBLIC_URL}/minha-conta/edit/endereco`}>ENDEREÇO</SubLink>
                    <SubLink exact to={`${process.env.PUBLIC_URL}/minha-conta/edit/escolaridade`}>ESCOLARIDADE</SubLink>
                    <SubLink exact to={`${process.env.PUBLIC_URL}/minha-conta/edit/profissional`}>PROFISSIONAL</SubLink>
                </div>
                <Link exact to={`${process.env.PUBLIC_URL}/minha-conta/vagas`}>VAGAS QUE ME CANDIDATEI</Link>
            </>
        );
    }
};

export default (SuaContaCandidatoMenu);
