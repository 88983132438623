import React from "react";

import BannerPrincipal from 'components/Banners'
import CarrosselClientes from 'components/CarrosselClientes'
import Setores from "components/Setores";
import VagasRecentes from "components/VagasRecentes";
import Patrocinadores from "components/Patrocinadores";
import VagasPorCategoria from "components/VagasPorCategoria";

//Analytics
import ReactGA from 'react-ga';

class Home extends React.Component {

	componentDidMount() {
		ReactGA.pageview(this.constructor.name);
	}

	render() {
		return (
			<div>
				<BannerPrincipal/>
				<CarrosselClientes/>
				<Setores/>
				<VagasRecentes/>
				<Patrocinadores/>
				<VagasPorCategoria/>
			</div>
		);
	}
}

export default Home;
