// Geral
import React from "react";

// Componentes
import { Container } from 'reactstrap';
import { NavBar,SubNavBar } from "components/Navbars";

export default () => {
    return (
        <div>
            <Container fluid={true} className="border-bottom">
                <Container>
                    <NavBar/>
                </Container>
            </Container>
            <Container fluid={true}>
                <Container>
                    <SubNavBar/>
                </Container>
            </Container>
        </div>
    );
};
