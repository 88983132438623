import React from "react";
import { Formik, Form } from 'formik';
import { Container, Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { addInYup as addCpfYup } from "helpers/validation/Cpf"; 
import { addInYup as addDateYup } from "helpers/validation/Date"; 
import { FacebookProvider, LoginButton } from 'react-facebook';

// Consts
import { PRIMARY_COLOR } from 'helpers/consts';

// Ações / Métodos
import { loginUser, loginUserFacebook } from 'actions/auth';

// Componentes
import FormInput from "components/FormInput";
import { SecondaryButton } from 'components/Buttons';
import DateInput from 'components/FormInput/Date';
import Cpf from 'components/FormInput/Cpf';

// Ícones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Adiciona a função de validação de CPF no YUP
addCpfYup();
addDateYup();

// Validação
const validationSchema = Yup.object().shape({
    cpf: Yup.string().required('Por favor digite o CPF').cpf('Por favor digite um CPF válido'),
    dataNascimento: Yup.string().required('Por favor digite a sua data de nascimento').date('Por favor digite uma data válida'),
});

const MySwal = withReactContent(Swal);

class LoginCandidato extends React.Component {
    constructor() {
        super();
         
        this._initialValues = {
            cpf: '',
            dataNascimento: ''
        };     
    }

    handleSubmit = (values, actions) => {
        let cpf = values.cpf;
        let dataNascimento = values.dataNascimento;
        dataNascimento = dataNascimento.split('/');
        dataNascimento = dataNascimento[2] + '-' + dataNascimento[1] + '-' + dataNascimento[0];
        
        loginUser({
            cpf,
            dataNascimento
        }, ()=>{
            if(localStorage.getItem('candidatar'))
                window.location.href = `${process.env.PUBLIC_URL}/vaga/`+localStorage.getItem('candidatar');
            else
                window.location.href = `${process.env.PUBLIC_URL}/minha-conta`;
        }, (err)=>{
            actions.setSubmitting(false);

            MySwal.fire({
                type: 'error',
                title: err.message,
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 3000,
            });
        });
    }

    handleFacebookResponse = (data) => {
        if(data && data.profile && data.profile.id){
            loginUserFacebook(data.profile.id, ()=>{
                // Mandar para o cadastro com os dados
                this.props.history.push(`${process.env.PUBLIC_URL}/candidato/cadastro`, {
                    facebook: data.profile
                });
            });
        }
        /*
        Exemplo de retorno:
        "{"profile":{  
            "id":"2449206865169441",
            "first_name":"Andrei Luiz",
            "last_name":"Nenevê",
            "name":"Andrei Luiz Nenevê",
            "name_format":"{first} {last}",
            "picture":{  
                "data":{  
                    "height":50,
                    "is_silhouette":false,
                    "url":"https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=2449206865169441&height=50&width=50&ext=1565528388&hash=AeQJZkT8zX7wH5kP",
                    "width":50
                }
            },
            "short_name":"Andrei Luiz",
            "email":"andreiluizn@gmail.com"
            },
            "tokenDetail":{  
            "accessToken":"EAAavZBFVV15QBAH0RXV4hs4OdfeLOLYvPiUfwzikvZA11huUZAqS614AqRL1bUEGtWy3kR7Xdv6r9jOFVqRXZBv95OFURzfpQcP9VG0et3qGQlAaYqs8ylKT9TQi7ep38jp6jYU1bMiqXyreLaf930vZA4mZBRZAW6TeQ6gPBc9ZCHIQFy6PpZAkqxZCX4pXYRK6vsW8VfVdzDdwZDZD",
            "userID":"2449206865169441",
            "expiresIn":3613,
            "signedRequest":"lWR67mWndmRpjjaq44v8RE0667agUwMoqFoM3WaKvbY.eyJ1c2VyX2lkIjoiMjQ0OTIwNjg2NTE2OTQ0MSIsImNvZGUiOiJBUUJYRHV6MUYxRU5TMjNkQWk1N2hhaGtJcnlVMW9xaV9TN3NYaURGWV93cGptcC0xMnp2REF5MDJHb0FQb2VYc045X0lKajhNRW9BZ2Y1Wm41b05neDFkTmVwTDRjVVV2V0RsWk1XMkZHZjQ2ZmFJYjlwN2JOaVdSaFU2Q2sxVWN5WndKWXZuX2ZmWDRZUVZNM3BpcEFsQzFZV1VsQVdKdG0zOVFkeTQtaDE0NGR6UUVZUnJJUUpBQnR5cG1pc3p3bGpqTXpsSGpkX2NRMG01ZUFVRWczOTFTZURtX0tuVXlVckJ2VDFXekEycTJ3WVNLWTBiRXJqSHh3b3p0d1FPOXp5dUI2bS1nbEFJc1RmeGh5cmJLSHY3dFBITkhzUlBrUVVZX3JlMFZEdVVLd0c5eVRaYXZDeVhlRHNjYTlIX01IT2hkYlRXMWRHVUducjRYZElJc1RVeSIsImFsZ29yaXRobSI6IkhNQUMtU0hBMjU2IiwiaXNzdWVkX2F0IjoxNTYyOTM2Mzg3fQ",
            "reauthorize_required_in":7776000,
            "data_access_expiration_time":1570712387
            }
        }"
        */
    }
     
    handleFacebookError = (error) => {
        MySwal.fire({
            type: 'error',
            title: 'Não foi possível fazer o login via Facebook!',
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 3000,
        });
    }

	render() {
		return (
            <Container>
                <Row className="d-flex justify-content-center">    
                    <Col xs="12" lg="10">
                        <p className="h4 pt-4 pb-3 font-weight-light" style={{color:PRIMARY_COLOR}}>FAÇA LOGIN</p>
                        <Formik
                            validationSchema={validationSchema}
                            initialValues={this._initialValues}
                            onSubmit={this.handleSubmit}
                            render={(FormikProps) => (
                                <Form onSubmit={FormikProps.handleSubmit}>
                                    <Row>
                                        <Col xs="12">
                                            <FormInput field={{
                                                name: 'cpf',
                                                tag: Cpf
                                            }} form={FormikProps} label="CPF"/>
                                        </Col>
                                        <Col xs="12">
                                            <FormInput field={{
                                                name: 'dataNascimento',
                                                tag: DateInput
                                            }} form={FormikProps} label="Nascimento"/>
                                        </Col>
                                    </Row>

                                    <Row className="mt-3 mb-1">
                                        <Col xs="12" className="d-flex justify-content-center">
                                            <SecondaryButton disabled={FormikProps.isSubmitting} type="submit">ACESSAR <FontAwesomeIcon icon='arrow-right' color={PRIMARY_COLOR}/></SecondaryButton>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2 mb-3">
                                        <Col xs="12" className="d-flex justify-content-center">
                                            <FacebookProvider appId="1882330978703252">
                                                <LoginButton
                                                    scope="email"
                                                    onCompleted={this.handleFacebookResponse}
                                                    onError={this.handleFacebookError}
                                                    className="btn p-0 btn-sm"
                                                >
                                                    <span style={{color: PRIMARY_COLOR}}><FontAwesomeIcon icon={['fab',"facebook-f"]} color={'#365d90'}/> &nbsp;Logar com Facebook</span>
                                                </LoginButton>
                                            </FacebookProvider>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="px-0 py-3 text-center" style={{color: PRIMARY_COLOR, borderTop: '1px solid #cccccc'}}>
                        Não possui uma conta? <Link style={{color: PRIMARY_COLOR, textDecoration: 'underline'}} className="font-weight-bold" to={`${process.env.PUBLIC_URL}/candidato/cadastro`}>Cadastre-se</Link>
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default withRouter(LoginCandidato);