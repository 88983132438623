import axios from "axios";
import { JWT_TOKEN } from "./consts";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal)

const setAxiosDefaults = (url = '/api') => {
    axios.defaults.baseURL = url;

    axios.interceptors.response.use((response)=> response, function (error) {
        if (error.response && error.response.status === 500 && error.response.data.message !== "") {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 2000,
                type: 'error',
                html: error.response.data.message,
                title: 'Oops, ocorreu um problema'
            })
        } else if(error.response && error.response.status === 401){
            localStorage.removeItem(JWT_TOKEN)
            //window.location.href = `${process.env.PUBLIC_URL}/login`
        }
        return Promise.reject(error)
    })
};

export default setAxiosDefaults;
