import React from "react";
import { withRouter, NavLink } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

// Métodos / Ações
import { vagasInteresse } from 'actions/candidatos';
import { fetchVagasRecentes as fetchList } from 'actions/candidatos';

// Constantes
import { JWT_TOKEN, PRIMARY_COLOR } from 'helpers/consts';
import { PrimaryButton } from "components/Buttons";

// Componentes
import BoxVaga from 'components/SuaContaCandidato/BoxVaga';

class Main extends React.Component {
    constructor(){
        super();

        this.state = {
            pgSize: 3,
            interesses: [],
            vagas: []
        }
    }

    loadData = (pg = 1) => {
        let reqData = {
            pg,
            pgSize: this.state.pgSize

        }
        var promise = new Promise((res, rej) => {
            fetchList(reqData, res, rej);
        });
        
        promise.then((data) => {
            let vagas = [];
            for(let i=0; i < data.results.length; i++){
                vagas.push({
                    idVaga: data.results[i].idVaga,
                    nome: data.results[i].cg_nome,
                    status: data.results[i].status,
                    empresa: data.results[i].em_nome,
                    telefone: data.results[i].telefone,
                    email: data.results[i].email,
                    ocultaInfo: data.results[i].cl_ocultaInfo,
                    cidade: data.results[i].cd_nome + ' - ' + data.results[i].es_uf
                })
            }

            this.setState({
                vagas
            });
        });
    }

    componentDidMount(){
        this.loadData();
        this.loadInteresses();
    }

    loadInteresses = () =>{
        vagasInteresse((data)=>{
            this.setState({
                interesses: data.results
            });
        })
    }
    

	render() {
        let token = localStorage.getItem(JWT_TOKEN);
        if(!token)
            return null;
        
        token = jwt_decode(token);
    
        const vagas = this.state.vagas.map((value, idx)=>{
            return <BoxVaga vaga={value} key={idx}/>
        })
		return (
			<div>
                <h4 style={{color: PRIMARY_COLOR}} className="font-weight-light">Olá {token.nome}</h4>
                <div className="text-muted mt-3 mb-4">
                    {this.state.interesses.length > 0 &&
                        <>
                            <div className="mb-3">Há {this.state.interesses.map((val, idx)=>
                                <div className="d-inline-flex" key={idx}>{idx > 0 ? ',  ' : ''}<NavLink style={{color:'inherit'}} to={`${process.env.PUBLIC_URL}/vagas/setores/${val.descricao.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()}`}>
                                    {val.quantidade} nova(s) vaga(s) em {val.descricao} 
                                </NavLink></div>
                            )} que podem lhe interessar.</div>
                        </>
                    }
                    {this.state.interesses.length === 0 &&
                        <>Infelizmente não temos nenhuma novidade por aqui.</>
                    }
                </div>
                <h5 style={{color: PRIMARY_COLOR}} className="font-weight-light mt-4">Últimas vagas que me candidatei</h5>
                <div className="mt-3 mb-4">
                    {this.state.interesses.length > 0 &&
                        vagas
                    }
                    {this.state.vagas.length === 0 &&
                        <>Nenhuma candidatura ainda, comece procurando vagas.</>
                    }
                </div>
                <PrimaryButton tag={NavLink} to={`${process.env.PUBLIC_URL}/minha-conta/vagas`} className="font-weight-light mt-4" style={{color:'white'}}>VER TODAS</PrimaryButton>
			</div>
		);
	}
}

export default withRouter(Main);
